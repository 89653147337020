import path from "../../router";

export const defMetaData = {
	[path.home.root]: {
		en: {
			title: `Beyond88 - The Best Online Sportsbook, Casino and Online Gambling in Asia`,
			description: `Beyond88 is Asia's best and most trusted online betting and gambling site. Join and play sportsbook, live casino, casino slot, lottery, keno, and fishing.`,
			keyword: `Beyond88, Beyond88 link, online casino, online gambling, online gambling malaysia, sports betting, lottery, live casino malaysia`
		},
		"zh-CN": {
			title: `Beyond88 - 亚洲最佳体育博彩，真人娱乐与在线博彩。`,
			description: `Beyond88 - 亚洲知名品牌与最受信任的在线博彩网站。立即注册并享受顶尖的体育博彩，真人娱乐，电子游戏老虎机，彩票，快乐彩，和捕鱼游戏。`,
			keyword: `Beyond88 - 在线博彩，在线赌博，马来西亚在线博彩，体育博彩，彩票，马来西亚真人娱乐场`
		}
	},
	[path.casino.root]: {
		en: {
			title: `Beyond88 - Online Slots Gambling Game Malaysia`,
			description: `Beyond88 is the hottest and latest online casino slots games in Malaysia. Spin and Win real money slots, jackpot slots, 918kiss, mega888, pragmatic play slots.`,
			keyword: `Beyond88 - slots, online slots, online casino slots, casino slots, progressive slots, real money slots`
		},
		"zh-CN": {
			title: `Beyond88 - 马来西亚在线电子老虎机`,
			description: `Beyond88 - 马来西亚最热门和最新的在线赌场老虎机游戏。 旋转并赢得奖金，积宝大奖老虎机。`,
			keyword: `Beyond88 - 老虎机, 线上老虎机, 在线老虎机, 在线电子游戏, 积宝奖金老虎机, 现金老虎机`
		}
	},
	[path.promotion.root]: {
		en: {
			title: `Beyond88 - Online Casino Promotions`,
			description: `Beyond88 promotion offered to boost your winning chances, from welcome bonus, cash rebates, reload bonus, and special bonuses tailored for you. Claim now!`,
			keyword: `Beyond88, Beyond88 promotion, welcome bonus, deposit bonus, online casino bonus, reload bonus, redeposit bonus, casino slot bonus, freebet`
		},
		"zh-CN": {
			title: `Beyond88 - 在线博彩优惠活动`,
			description: `Beyond88 - 优惠活动提高您获胜的机率。从首存红利，现金返水，再存红利与各种为您量身定制的优惠促销，马上领取！`,
			keyword: `Beyond88，优惠活动，首存红利，存款优惠，在线博彩优惠，再存红利，现金反水，电子游戏老虎机优惠，免费金额`
		}
	}
};

import path from "../../router";

export const defPrevPage = {
	[path.withdrawal.root]: {
		contentMsg: "lbl_GoWithdrawalPage",
		path: path.withdrawal.root,
		label: "lbl_WithdrawalNow"
	},
	[path.promotion.root]: {
		contentMsg: "lbl_GoPromotionPage",
		path: `${path.promotion.root}`,
		label: "lbl_GoNow"
	},
	[path.deposit.root]: {
		contentMsg: "lbl_GoDepositPage",
		path: path.deposit.root,
		label: "lbl_DepositNow"
	}
};

export const initStatus = {
	realName: {
		checked: null,
		tip: ""
	},
	birth: {
		checked: null,
		tip: ""
	},
	phoneNo: {
		checked: null,
		tip: ""
	},
	email: {
		checked: null,
		tip: ""
	}
};

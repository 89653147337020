/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, Suspense, lazy } from "react";
import { popBoxContext } from "./context";
import Cancel from "./popBox.cancel";
import Success from "./popBox.success";
import ErrorMsg from "./popBox.errorMsg";
import Login from "./popBox.login";
import AddBankAccount from "./popBox.addBankAccount";
import AddCryptoWallet from "./popBox.addCryptoWallet";
import AddBankSuccess from "./popBox.addBankSuccess";
import ConfirmDelete from "./popBox.confirmDelete";
import ProfileConfirmCheck from "./popBox.profileConfirmCheck";
import ProfileCompelete from "./popBox.profileCompelete";
import ChangePsw from "./popBox.changePsw";
import LogoutHint from "./popBox.logoutHint";
import DepositHint from "./popBox.depositHint";
import WithdrawalSuccess from "./popBox.withdrawalSuccess";
import UpdateProfile from "./popBox.updateProfile";
import TurnoverAccumulated from "./popBox.turnoverAccumulated";
import RegisterSuccess from "./popBox.registerSuccess";
import PromotionDepositConfirm from "./popBox.promotionDepositConfirm";
import PromotionAmountConfirm from "./popBox.promotionAmountConfirm";
import defPopBoxType from "./defPopBoxType";
import Modal from "./popBox.modal";

export default () => {
	const { popBox } = useContext(popBoxContext);

	let { content } = popBox;
	switch (popBox.popBoxType) {
		case defPopBoxType.Modal:
			content = (
				<Modal
					closePopBox={popBox.closePopBox}
					content={popBox.content}
				/>
			);
			break;

		case defPopBoxType.Cancel:
			content = <Cancel btnBar={popBox.btnBar} />;
			break;

		case defPopBoxType.Success:
			content = (
				<Success
					icon={popBox.icon}
					description={popBox.description}
					btnBar={popBox.btnBar}
				/>
			);

			break;

		case defPopBoxType.ErrorMsg:
			content = (
				<ErrorMsg
					btnBar={popBox.btnBar}
					message={popBox.message}
					errorCode={popBox.errorCode}
					icon={popBox.icon}
					apiUrl={popBox.apiUrl}
				/>
			);
			break;

		case defPopBoxType.Login:
			content = <Login />;
			break;

		case defPopBoxType.AddBankAccount:
			content = (
				<AddBankAccount
					bankInfoSysList={popBox.bankInfoSysList}
					custBankInfo={popBox.custBankInfo}
					GetCustBankInfo={popBox.GetCustBankInfo}
				/>
			);
			break;

		case defPopBoxType.AddCryptoWallet:
			content = (
				<AddCryptoWallet GetCryptoWallet={popBox.GetCryptoWallet} />
			);
			break;

		case defPopBoxType.AddBankSuccess:
			content = <AddBankSuccess btnBar={popBox.btnBar} />;
			break;

		case defPopBoxType.ConfirmDelete:
			content = (
				<ConfirmDelete
					btnBar={popBox.btnBar}
					message={popBox.message}
					message2={popBox.message2}
				/>
			);
			break;

		case defPopBoxType.ProfileConfirmCheck:
			content = (
				<ProfileConfirmCheck
					btnBar={popBox.btnBar}
					realName={popBox.realName}
				/>
			);
			break;

		case defPopBoxType.ProfileCompelete:
			content = <ProfileCompelete {...popBox} />;
			break;

		case defPopBoxType.ChangePsw:
			content = (
				<ChangePsw
					btnBar={popBox.btnBar}
					title={popBox.title}
					message={popBox.message}
					ClickClose={popBox.ClickClose}
				/>
			);
			break;

		case defPopBoxType.LogoutHint:
			content = (
				<LogoutHint btnBar={popBox.btnBar} content={popBox.content} />
			);
			break;

		case defPopBoxType.DepositHint:
			content = <DepositHint />;
			break;

		case defPopBoxType.WithdrawalSuccess:
			content = <WithdrawalSuccess btnBar={popBox.btnBar} />;
			break;

		case defPopBoxType.UpdateProfile:
			content = (
				<UpdateProfile
					title={popBox.title}
					content={popBox.content}
					btnBar={popBox.btnBar}
				/>
			);
			break;

		case defPopBoxType.TurnoverAccumulated:
			content = <TurnoverAccumulated {...popBox} />;
			break;

		case defPopBoxType.RegisterSuccess:
			content = (
				<RegisterSuccess
					btnBar={popBox.btnBar}
					closeBtn={popBox.closeBtn}
				/>
			);
			break;
		case defPopBoxType.PromotionDepositConfirm:
			content = (
				<PromotionDepositConfirm
					hint={popBox.hint}
					btnBar={popBox.btnBar}
				/>
			);
			break;

		case defPopBoxType.PromotionAmountConfirm:
			content = (
				<PromotionAmountConfirm
					currencyId={popBox.currencyId}
					userLang={popBox.userLang}
					promotionInfo={popBox.promotionInfo}
					clickClose={popBox.clickClose}
					setClaimPromotion={popBox.setClaimPromotion}
				/>
			);
			break;

		case defPopBoxType.PaymentQR: {
			const PaymentQR = lazy(() => import("./popBox.paymentQR"));
			content = (
				<Suspense fallback={<>Loading...</>}>
					<PaymentQR {...popBox} />
				</Suspense>
			);
			break;
		}

		default:
			break;
	}

	const customLayout = popBox.popBoxType === defPopBoxType.Modal;

	if (!popBox.isOpen) return null;

	return customLayout || popBox.customLayout ? (
		<div id="popup" className="active">
			{content}
		</div>
	) : (
		<div id="popup" className="active">
			<div className="pop">{content}</div>
		</div>
	);
};

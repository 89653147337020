import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import {
	userInfoContext,
	popBoxContext,
	isRootContext
} from "../common/context";
import Menu from "./header.menu";
import config from "../../config";
import Functions from "./header.functions";
import LanguageDropDown from "./header.langDropdown";
import { LOGOUT } from "../../service/auth";
import path from "../../router";
import defPopBoxType from "../common/defPopBoxType";
import {
	api_entrance_GetGameList,
	defGameListType
} from "../../service/api/api.entrance";

import logo from "../../styles/img/logo.png";

export default () => {
	const { t } = useTranslation();
	const { userInfo, setUserInfo } = useContext(userInfoContext);
	const { setPopBox } = useContext(popBoxContext);
	const { isRoot } = useContext(isRootContext);
	const [fishingList, setFishingList] = useState();
	const [p2pList, setp2pList] = useState();
	const [slotList, setSlotList] = useState();

	const handleLogout = () => {
		LOGOUT(() => {
			const clickClose = () => {
				if (
					window.location.pathname.includes(
						`/${userInfo.userLang}/${path.resetPassword.root}`
					)
				) {
					navigate(`/${userInfo.userLang}`);
				}

				setPopBox({ isOpen: false });
				setUserInfo(prevState => ({
					isLogin: false,
					userLang: prevState.userLang,
					kickout: true,
					willLogout: true,
					defaultLang: prevState.defaultLang
				}));

				if (isRoot) {
					window.location.reload();
				}
			};

			setPopBox({
				isOpen: true,
				popBoxType: defPopBoxType.LogoutHint,
				content: t("msg_loggedout"),
				btnBar: (
					<div className="btnGroup">
						<button className="btn-check" onClick={clickClose}>
							OK
						</button>
					</div>
				)
			});
		});
	};

	const reload = () => {
		if (isRoot) {
			window.location.reload();
		}
	};

	const LogoutButton = () => {
		if (!userInfo.isLogin) return null;

		return <button className="icon icon-exit" onClick={handleLogout} />;
	};

	useEffect(() => {
		const getFishingList = async () => {
			try {
				const res = await api_entrance_GetGameList({
					userLang: userInfo.userLang,
					gameListType: defGameListType.fishing
				});

				const { data, errorCode } = res.data;
				if (errorCode === 0) {
					const allowList = [];
					data?.providerList.forEach(p => {
						allowList.push({
							tpId: p.value,
							name: p.name,
							iconPath: p.iconPath
						});
					});

					setFishingList(allowList);
				}
			} catch (error) {}
		};

		const getp2pList = async () => {
			const res = await api_entrance_GetGameList({
				userLang: userInfo.userLang,
				gameListType: defGameListType.p2p
			});

			const { data, errorCode } = res.data;
			if (errorCode === 0) {
				const allowList = [];
				data?.providerList.forEach(p => {
					allowList.push({
						tpId: p.value,
						name: p.name,
						iconPath: p.iconPath
					});
				});

				setp2pList(allowList);
			}
		};

		const getSlotList = async () => {
			const res = await api_entrance_GetGameList({
				userLang: userInfo.userLang,
				gameListType: defGameListType.casino
			});

			const { data, errorCode } = res.data;
			if (errorCode === 0) {
				const allowList = [];
				data?.providerList.forEach(p => {
					allowList.push({
						tpId: p.value,
						name: p.name,
						iconPath: p.iconPath
					});
				});

				setSlotList(allowList);
			}
		};

		getSlotList();
		getFishingList();
		getp2pList();
	}, [userInfo.userLang]);

	return (
		<header id="header">
			<div className="header">
				<div className="logo">
					<Link
						className="logo"
						to={`/${userInfo.userLang}`}
						onClick={reload}>
						<img src={logo} alt={config.siteName} />
					</Link>
				</div>
				<div className="function">
					<Functions />
					<LanguageDropDown />
					{userInfo.isLogin ? null : (
						<Link
							to={`/${userInfo.userLang}/${path.forgetPassword.root}`}
							className="forgot">
							{t("lbl_ForgetPassword")}
						</Link>
					)}
					<LogoutButton />
				</div>
				<Menu
					fishingList={fishingList}
					p2pList={p2pList}
					slotList={slotList}
				/>
			</div>
		</header>
	);
};

import React from "react";

export default ({ title, content, btnBar }) => (
	<div className="Cancel active">
		<div className="icon icon-icon32px_Profile" />
		<div className="title">{title}</div>
		<div>{content}</div>
		{btnBar}
	</div>
);

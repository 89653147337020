import productList from "../../datas/productList";
import path from "../../router";
import {
	defEventIcon,
	defEventPeriod,
	showEventIcon
} from "../common/eventIcon";
import { defCurrencyList } from "../common/common";
import { defGameListType } from "../../service/api/api.entrance";

export const defMenuType = {
	entPage: "entPage",
	Link: "Link",
	LinkNoImg: "LinkNoImg",
	DirectUrl: "DirectUrl",
	useGoToGame: "useGoToGame"
};

export const defSubListClassName = {
	appDownload: "appList",
	sports: "sportsList",
	slot: "casinoList",
	casino: "livecasinoList",
	games: "tableList",
	fishing: "fishList",
	promotion: "promoList"
};

export const defMenuLinks = {
	home: {
		className: "icon icon-home",
		path: "/"
	},
	appDownload: {
		className: "icon icon-mobile"
	},
	sports: {
		...productList.polySports,
		displayKey: "lbl_Sports",
		menuType: defMenuType.useGoToGame,
		allowCurrencies: [defCurrencyList.THB],
		eventIcons: [
			showEventIcon({
				icon: defEventIcon.new,
				period: defEventPeriod.sports_poly
			})
		]
	},
	casino: {
		displayKey: "lbl_Casino",
		className: "livecasinobtn",
		path: path.liveCasino.root,
		subMenu: [
			{
				...productList.liveCasino_EVO,
				keyName: "casino_EVO",
				displayText: "Evolution Gaming",
				listNpcImgUrl: "casino-ev.png",
				listBgImgUrl: ["evbg.png", "livecasinListHover.png"],
				menuType: defMenuType.entPage,
				lobbyPage: { npcImg: "casino_ev" }
			},
			{
				...productList.liveCasino_Allbet,
				keyName: "casino_ALBET",
				className: "liveALL",
				icon: "icon icon-AB",
				displayText: "Allbet",
				listNpcImgUrl: "casino-ALL.png",
				listBgImgUrl: ["allBG.png", "livecasinListHover.png"],
				menuType: defMenuType.entPage,
				lobbyPage: { npcImg: "casino_ab" }
			},
			{
				...productList.liveCasino_AG,
				keyName: "casino_AG",
				className: "liveAG",
				icon: "icon icon-AG",
				displayText: "Asia Gaming",
				listNpcImgUrl: "casino-AG.png",
				listBgImgUrl: ["agBG.png", "livecasinListHover.png"],
				menuType: defMenuType.entPage,
				lobbyPage: { npcImg: "casino_ag" }
			},
			{
				...productList.liveCasino_WM,
				keyName: "casino_WM",
				className: "liveWM",
				icon: "icon icon-WM",
				displayText: "WM Casino",
				listNpcImgUrl: "casino-WM.png",
				listBgImgUrl: ["wmBG.png", "livecasinListHover.png"],
				menuType: defMenuType.entPage,
				lobbyPage: { npcImg: "casino_wm" }
			}
		]
	},
	slot: {
		displayKey: "lbl_Slots",
		gameListType: defGameListType.casino,
		className: "slotbtn",
		path: path.casino.root
	},
	games: {
		displayKey: "lbl_p2p",
		gameListType: defGameListType.p2p,
		path: path.p2p.root
	},
	fishing: {
		displayKey: "lbl_Fishing",
		className: "fishbtn",
		path: path.fishing.root,
		gameListType: defGameListType.fishing
	},
	promotion: {
		displayKey: "lbl_Promos",
		path: path.promotion.root,
		eventIcons: [
			showEventIcon({
				icon: defEventIcon.hot,
				period: defEventPeriod.promo
			})
		]
	}
};

import React from "react";
import PropTypes from "prop-types";
import { number_format, isConvert } from "../common/common";

const CashbackInfo = ({ promotionCode, cashBackDetails, t, currencyId }) => {
	if (!cashBackDetails) return null;

	const { totalBonus, maxBonus, cashBackDetail } = cashBackDetails;

	const rows = cashBackDetail?.map(c => (
		<tr key={`CashbackInfo-${promotionCode}-${c.tpName}`}>
			<td>{c.tpName}</td>
			<td>
				{number_format(Math.abs(c.lossAmount), isConvert(currencyId))}
			</td>
			<td>{c.percentageName}</td>
			<td>{number_format(c.bonusAmount, isConvert(currencyId))}</td>
		</tr>
	));

	return (
		<div className="cashBack">
			<table>
				<tbody>
					<tr>
						<td />
						<td>{t("lbl_LossAmount")}</td>
						<td>{t("lbl_Percentage")}</td>
						<td>{t("lbl_BonusAmount")}</td>
					</tr>
					{rows}
					<tr>
						<td colSpan="4">
							<span>{t("lbl_TotalAmount")}：</span>
							<span>
								{number_format(
									totalBonus,
									isConvert(currencyId)
								)}
							</span>
						</td>
					</tr>
					<tr>
						<td colSpan="4">
							<span>{t("lbl_MaxBonusAmount")}：</span>
							<span>
								{number_format(maxBonus, isConvert(currencyId))}
							</span>
						</td>
					</tr>
					<tr>
						<td colSpan="4">
							<span>{t("lbl_ClaimAmount")}：</span>
							<span>
								{totalBonus > maxBonus
									? number_format(
											maxBonus,
											isConvert(currencyId)
									  )
									: number_format(
											totalBonus,
											isConvert(currencyId)
									  )}
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

CashbackInfo.defaultProps = {
	cashBackDetails: null,
	promotionCode: null,
	currencyId: null
};

CashbackInfo.propTypes = {
	promotionCode: PropTypes.string,
	cashBackDetails: PropTypes.objectOf(PropTypes.any),
	t: PropTypes.func.isRequired,
	currencyId: PropTypes.number
};

export default CashbackInfo;

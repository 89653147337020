import React from "react";
import ProcessBar from "./promotion.processBar";
import { isNew, isClaimed, isArrayNotEmpty } from "./promotion.common";
import PromotionJoinButton from "./promotion.joinButton";
import { getExpiryTime } from "./promotion.promoRule";
import { useHandlePromotionInfo } from "./promotionInfo.popbox";

const defPromoDisplayStyle = {
	default: "new",
	new: "new active",
	expired: "expired",
	claimed: "claimed"
};

export default ({
	promoList,
	t,
	userInfo,
	filter,
	refreshProcessBar,
	afterJoinCallBack
}) => {
	const { getPromotionDetail } = useHandlePromotionInfo({});

	const clickMoreInfo = promotionCode => {
		getPromotionDetail(promotionCode);
	};

	const { isLogin, currencyId } = userInfo;

	const rows = promoList?.map(p => {
		const _isClaimed = isClaimed(p);
		const _isNew = _isClaimed ? false : isNew(p);

		let promoDisplayStyle = defPromoDisplayStyle.default;

		if (_isClaimed) {
			promoDisplayStyle = defPromoDisplayStyle.claimed;
		} else if (_isNew) {
			promoDisplayStyle = defPromoDisplayStyle.new;
		}

		return (
			<div
				className={_isClaimed ? "active" : ""}
				key={`promotion.list-${p.promotionCode}-${p.tpId}`}>
				<div className={promoDisplayStyle} />
				<div className="promoImg">
					<img src={p.banner} alt={p.title} />
				</div>

				<div className="promoInfo">
					<div className="txt-darkBlue">{p.title}</div>
					<div className="txt-gray">{p.description}</div>

					<ProcessBar
						{...p}
						t={t}
						currencyId={currencyId}
						refreshProcessBar={() => refreshProcessBar(p)}
					/>

					<div className="btnGroup">
						<PromotionJoinButton
							promotionInfo={p}
							userInfo={userInfo}
							filter={filter}
							callBack={afterJoinCallBack}
						/>

						<button
							className="btn-check"
							onClick={clickMoreInfo.bind(this, p.promotionCode)}>
							{t("promotion.lbl_promo_more_info")}
						</button>
					</div>
				</div>
				{p.showComing ? (
					<div className="remain">
						<div className="icon icon-icon32px_time">
							{t("lbl_StartDate")}
							<div>{`${p.periodFrom} 00:00:00 ${p.timeZone}`}</div>
						</div>
					</div>
				) : (
					<div className="remain">
						<div className="icon icon-icon32px_time">
							{t("lbl_RemainingTime")}
							<div>{getExpiryTime(t, p)}</div>
						</div>
					</div>
				)}
			</div>
		);
	});

	if (isLogin === null) return null;

	if (isArrayNotEmpty(promoList))
		return <div className="proListNew">{rows}</div>;

	return (
		<div className="nodata icon icon-nodata">
			<div>{t("lbl_NoData")}</div>
		</div>
	);
};

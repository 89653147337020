import React from "react";

export const defFilterType = {
	all: "all",
	firstDeposit: "firstDeposit",
	sportsbook: "sportsbook",
	liveCasino: "liveCasino",
	slots: "slots"
};

const defPromoNavs = [
	{
		displayKey: "lbl_All",
		value: defFilterType.all,
		className: "blueBorder icon icon-icon32px_ALL",
		selectedClassName: "blue icon icon-icon32px_ALL"
	},
	{
		displayKey: "lbl_NewMember",
		value: defFilterType.firstDeposit,
		className: "blueBorder",
		selectedClassName: "blue"
	},
	{
		displayKey: "lbl_Sportsbook",
		value: defFilterType.sportsbook,
		className: "blueBorder",
		selectedClassName: "blue"
	},
	{
		displayKey: "lbl_LiveCasino",
		value: defFilterType.liveCasino,
		className: "blueBorder",
		selectedClassName: "blue"
	},
	{
		displayKey: "lbl_Promotion_Slot",
		value: defFilterType.slots,
		className: "blueBorder",
		selectedClassName: "blue"
	}
];

export default ({ t, selected, onClick }) => (
	<div className="promosSelect">
		<div className="btnGroup promoType">
			{defPromoNavs.map(item => (
				<button
					key={`promo-nav-${item.displayKey}`}
					className={
						item.value === selected
							? item.selectedClassName
							: item.className
					}
					onClick={() => onClick(item.value)}>
					{t(item.displayKey).toUpperCase()}
				</button>
			))}
		</div>
	</div>
);

import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { navigate } from "gatsby";
import {
	defMenuLinks,
	defSubListClassName,
	defMenuType
} from "./header.menu.def";
import { popBoxContext, userInfoContext } from "../common/context";
import defPopBoxType from "../common/defPopBoxType";
import SubMenuMultiple from "./header.menu.sub.Multiple";
import SubMenuSingle from "./header.menu.sub.single";
import { useGotoGame } from "../hook/useGotoGame";
import { getCurrencyCode } from "../common/common";
import { defGameListType } from "../../service/api/api.entrance";

export default ({ currentMenuKey, fishingList, p2pList, slotList }) => {
	const { t } = useTranslation();
	const { setPopBox } = useContext(popBoxContext);
	const { userInfo } = useContext(userInfoContext);

	const isMountedRef = useRef(null);

	const { handleGoToGame } = useGotoGame({
		isLogin: userInfo.isLogin,
		userLang: userInfo.userLang,
		autoGetData: false
	});

	const { isLogin, userLang } = userInfo;

	let currencyId = null;

	if (userInfo.isLogin) {
		currencyId = userInfo.currencyId;
	} else if (userInfo.isLogin === false) {
		currencyId = getCurrencyCode(userInfo.userLang);
	}

	const showLoginPopBox = () => {
		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.Login
		});
	};

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	const clickItem = async ({ tpId, gameId, path, needLogin, tpGameType }) => {
		if (!tpId && !gameId && path) {
			return needLogin && !userInfo.isLogin
				? showLoginPopBox()
				: navigate(`/${userInfo.userLang}/${path}`);
		}

		handleGoToGame({
			isLogin,
			userLang,
			tpId,
			gameId,
			tpGameType
		});
	};

	return Object.keys(defMenuLinks).map(menuKey => {
		const subClassKey = defSubListClassName[menuKey];

		const subClassName = [
			subClassKey,
			menuKey === currentMenuKey && "active"
		]
			.filter(Boolean)
			.join(" ");

		if (
			defMenuLinks[menuKey].gameListType === defGameListType.p2p &&
			p2pList
		) {
			return (
				<div className={subClassName} key={`main-key-${menuKey}`}>
					{p2pList.map(item => (
						<SubMenuMultiple
							key={`${menuKey}-${item.tpId}-${item.name}`}
							subMenuItem={{
								displayText: item.name,
								listNpcImgUrl: `p2p${item.iconPath}.png`,
								listBgImgUrl: "tablelisthover.png",
								menuType: defMenuType.Link,
								parameter: {
									tpId: item.tpId
								},
								tpId: item.tpId
							}}
							clickItem={clickItem}
							userInfo={userInfo}
							t={t}
							menuKey={menuKey}
							showLoginPopBox={showLoginPopBox}
						/>
					))}
				</div>
			);
		}

		if (
			defMenuLinks[menuKey].gameListType === defGameListType.fishing &&
			fishingList
		) {
			return (
				<div className={subClassName} key={`main-key-${menuKey}`}>
					{fishingList.map(item => (
						<SubMenuMultiple
							key={`${menuKey}-${item.tpId}-${item.name}`}
							subMenuItem={{
								displayText: item.name,
								listNpcImgUrl: `fish${item.iconPath}.png`,
								listBgImgUrl: "fishListHover.png",
								menuType: defMenuType.Link,
								parameter: {
									tpId: item.tpId
								},
								tpId: item.tpId
							}}
							clickItem={clickItem}
							userInfo={userInfo}
							t={t}
							menuKey={menuKey}
							showLoginPopBox={showLoginPopBox}
						/>
					))}
				</div>
			);
		}

		if (
			defMenuLinks[menuKey].gameListType === defGameListType.casino &&
			slotList
		) {
			return (
				<div className={subClassName} key={`main-key-${menuKey}`}>
					{slotList.map(item => (
						<SubMenuMultiple
							key={`${menuKey}-${item.tpId}-${item.name}`}
							subMenuItem={{
								displayText: item.name,
								listNpcImgUrl: `slot${item.iconPath}.png`,
								listBgImgUrl: "slotListHover.png",
								menuType: defMenuType.Link,
								parameter: {
									tpId: item.tpId
								},
								tpId: item.tpId
							}}
							clickItem={clickItem}
							userInfo={userInfo}
							t={t}
							menuKey={menuKey}
							showLoginPopBox={showLoginPopBox}
						/>
					))}
				</div>
			);
		}

		if (defMenuLinks[menuKey]?.subMenu) {
			const { subMenu } = defMenuLinks[menuKey];

			return (
				<div className={subClassName} key={`main-key-${menuKey}`}>
					{subMenu
						?.filter(s => {
							return (
								!isLogin ||
								!s.allowCurrencies ||
								s.allowCurrencies.includes(currencyId)
							);
						})
						.map(subMenuItem => (
							<SubMenuMultiple
								key={`${menuKey}-${subMenuItem.tpId}-${subMenuItem.keyName}`}
								subMenuItem={subMenuItem}
								clickItem={clickItem}
								userInfo={userInfo}
								t={t}
								menuKey={menuKey}
								className={subMenuItem.className}
								displayText={subMenuItem.displayText}
								displayKey={subMenuItem.displayKey}
								parameter={subMenuItem.parameter}
								showLoginPopBox={showLoginPopBox}
							/>
						))}
				</div>
			);
		}

		return (
			<SubMenuSingle
				key={`main-key-${menuKey}`}
				subClassName={subClassName}
				menuKey={menuKey}
				userInfo={userInfo}
				clickItem={() => clickItem(defMenuLinks[menuKey])}
				t={t}
			/>
		);
	});
};

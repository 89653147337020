export const defEventIcon = {
	new: "icon icon-new",
	live: "live",
	new_header: "icon icon-star-full",
	hot: "icon icon-mediafire"
};

export const defEventPeriod = {
	promo: { start: "2021/11/11 00:00:00" },
	sports_poly: { start: "2022/07/06 11:00:00" },
	btFishing: { start: "2022/07/06 11:00:00" }
};

export const showEventIcon = ({ icon, period }) => {
	if (!period) return "";
	const startDate = period.start
		? new Date(`${period.start} GMT+0800`)
		: null;
	const endDate = period.end ? new Date(`${period.end} GMT+0800`) : null;
	const now = new Date();

	if (
		icon &&
		((startDate && !endDate && now > startDate) ||
			(!startDate && endDate && now < endDate) ||
			(startDate && endDate && now > startDate && now < endDate))
	) {
		return icon;
	}
	return "";
};

import { req } from "./base";

export const api_accountStrict_SetProfile = ({
	email,
	areaCode,
	phoneNo,
	countryCode,
	birth_d,
	birth_m,
	birth_y,
	realName
}) =>
	req.post("/AccountStrict/SetProfile", {
		email,
		areaCode,
		phoneNo,
		countryCode,
		birth_d,
		birth_m,
		birth_y,
		realName
	});

export const api_accountStrict_DelCustBankInfo = ({ bankInfoId, accountNo }) =>
	req.post("/AccountStrict/DelCustBankInfo", {
		bankInfoId,
		accountNo
	});

export const api_accountStrict_SetCustBankInfo = ({
	accountName,
	accountNo,
	bankInfoId
}) =>
	req.post("/AccountStrict/SetCustBankInfo", {
		accountName,
		accountNo,
		bankInfoId
	});

export const api_accountStrict_SetCustCryptoWallet = ({
	cryptoId,
	groupId,
	nickName,
	walletAddress
}) =>
	req.post("/AccountStrict/SetCustCryptoWallet", {
		cryptoId,
		groupId,
		nickName,
		walletAddress
	});

export const api_accountStrict_DelCustCryptoWallet = ({
	cryptoId,
	groupId,
	nickName,
	walletAddress
}) =>
	req.post("/AccountStrict/DelCustCryptoWallet", {
		cryptoId,
		groupId,
		nickName,
		walletAddress
	});

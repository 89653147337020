import { useState } from "react";
import usePopBox from "../../hook/usePopBox";
import {
	api_payment_GetMobilePayGroupList,
	api_payment_GetMobilePayInfoList
} from "../../../service/api/api.payment";

export const useMobilePay = ({ isMountedRef }) => {
	const [mobilePayGroupList, setMobilePayGroupList] = useState([]);
	const [mobilePayInfoList, setMobilePayInfoList] = useState([]);

	const { errorPopBox } = usePopBox();

	const getMobilePayGroupList = async () => {
		await api_payment_GetMobilePayGroupList().then(
			res => {
				if (!isMountedRef.current) return;
				const { data, message, errorCode } = res.data;
				const success = errorCode === 0;
				if (success && data.length > 0) {
					setMobilePayGroupList(data);
				} else if (!success) {
					errorPopBox(message || `error`, errorCode);
				}
			},
			() => {
				if (!isMountedRef.current) return;
				errorPopBox();
			}
		);
	};

	const getMobilePayInfoList = async gId => {
		const datas = await api_payment_GetMobilePayInfoList(Number(gId)).then(
			res => {
				if (!isMountedRef.current) return;
				const { data, message, errorCode } = res.data;
				const success = errorCode === 0;
				if (success) {
					setMobilePayInfoList(data);
					return data;
				}
				errorPopBox(message || `error`, errorCode);
			}
		);
		return datas || [];
	};

	return {
		getMobilePayGroupList,
		getMobilePayInfoList,
		mobilePayGroupList,
		mobilePayInfoList
	};
};

import { readCookie } from "../components/common/common";
import { defLangList, defTmpSupportLangList } from "../datas/languageData";

export const siteDefLangId = "en";
const siteLangList = defLangList;

const SetReplaceChar = (initPath, idx, userLang) => {
	const _path = initPath
		.split("/")
		?.filter(x => x !== "" && !isTmpSupportLang(x))
		.map(x => (siteLangList.includes(x) ? userLang : x));

	let result = "";
	if (_path.length > 0) {
		const pathIsLang = siteLangList.includes(_path[0]);
		result = pathIsLang
			? `/${_path.join("/")}`
			: `/${userLang}/${_path.join("/")}`;
	} else {
		result = `/${userLang}${initPath}`;
	}
	return result;
};

export const getNewLanguagePath = userLang => {
	const url =
		window.location.pathname +
		window.location.search +
		window.location.hash;

	return SetReplaceChar(url, 1, userLang);
};

export const isVaildLang = lang => {
	const _lang = lang?.replace("/");
	return [...siteLangList, ...defTmpSupportLangList].includes(_lang);
};

/**
 * 檢查當前語系是否為尚未完全支援的語系，若是則回傳此site預設的語系，若否則繼續使用目前已選的語系
 */
export const isTmpSupportLang = lang => {
	return defTmpSupportLangList.includes(lang);
};

export const GET_LANG_ID = userLangId => {
	let userLang = userLangId || readCookie("LangId");
	const browserDefLang =
		typeof window !== `undefined` ? navigator.language.toLowerCase() : null;

	if (!userLang && browserDefLang) {
		if (
			browserDefLang.startsWith("ms") ||
			browserDefLang.startsWith("zh")
		) {
			userLang = "zh-CN";
			// } else if (browserDefLang.startsWith("th")) {
			// 	userLang = "th";
			// } else if (browserDefLang.startsWith("vi")) {
			// 	userLang = "vn";
			// } else if (browserDefLang.startsWith("id")) {
			// 	userLang = "id";
			// } else if (browserDefLang.startsWith("jp")) {
			// 	userLang = "jp";
			// } else if (browserDefLang.startsWith("ko")) {
			// 	userLang = "ko";
			// } else if (
			// 	browserDefLang.startsWith("pa") ||
			// 	browserDefLang.startsWith("ta")
			// ) {
			// 	userLang = "hi";
		} else if (browserDefLang.startsWith("en")) {
			userLang = "en";
		} else {
			userLang = siteDefLangId;
		}
	}

	if (!userLang || !siteLangList.includes(userLang)) {
		userLang = siteDefLangId;
	}

	return userLang;
};

import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { number_format } from "../common/common";

const statusClassName = {
	1: "pending",
	2: "pending",
	3: "pending",
	4: "pending",
	5: "cancel",
	6: "approved",
	7: "reject",
	8: "pending"
};

export const HistoryResult = props => {
	const { t } = useTranslation();
	let btnGroup = null;
	if (props.orderStatus === 1 || props.orderStatus === 8) {
		btnGroup = (
			<button className="gray" onClick={props.ClickCancel}>
				{props.i18n("lbl_Cancel")}
			</button>
		);
	}

	return (
		<div>
			<div>{props.idx}</div>
			<div>
				{moment(props.createdAt).format("MM/DD/YYYY")}
				<span className="txt-blue">
					{moment(props.createdAt).format("hh:mm:ss A")}
				</span>
			</div>
			<div>{props.orderId}</div>
			<div>{t(`lbl_WithdrawalAccountType.${props.accountType}`)}</div>
			<div>{number_format(props.amount, props._isConvert)}</div>
			<div className={statusClassName[props.orderStatus]}>
				{props.i18n(`lbl_WithdrawalStatus${props.orderStatus}`)}
			</div>
			<div className="btnGroup">{btnGroup}</div>
		</div>
	);
};

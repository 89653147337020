import React from "react";
import PropTypes from "prop-types";

const FeaturePanel = ({ userLang, t }) => {
	if (userLang === "en") return <EN t={t} />;
	if (userLang === "zh-CN") return <CS t={t} />;
	return <EN />;
};

FeaturePanel.propTypes = {
	userLang: PropTypes.string.isRequired
};

export default FeaturePanel;

const EN = ({ t }) => (
	<div className="features">
		<div className="title">
			<span className="icon icon-star-full" />
			FEATURES
		</div>
		<div className="title">MEMBER BENEFITS</div>
		<ul>
			<li>{t("lbl_MemberBenefits_1")}</li>
			<li>{t("lbl_MemberBenefits_2")}</li>
			<li>{t("lbl_MemberBenefits_3")}</li>
			<li>{t("lbl_MemberBenefits_4")}</li>
		</ul>
		<div className="title">
			ENJOY BEYOND88 VIP EXCLUSIVE BONUS & REBATES
		</div>
		<ul>
			<li>
				Multiple product platforms with better odds and full gameplay
				experience.
			</li>
			<li>Speedy deposit and withdrawal with multiple deposit option.</li>
		</ul>
	</div>
);

const CS = ({ t }) => (
	<div className="features">
		<div className="title">
			<span className="icon icon-star-full" />
			特征
		</div>
		<div className="title">会员福利</div>
		<ul>
			<li>{t("lbl_MemberBenefits_1")}</li>
			<li>{t("lbl_MemberBenefits_2")}</li>
			<li>{t("lbl_MemberBenefits_3")}</li>
			<li>{t("lbl_MemberBenefits_4")}</li>
		</ul>
		<div className="title">豪享BEYOND88尊贵优惠红利与返水</div>
		<ul>
			<li>多样化产品平台配上超划赔率以及完整游戏体验。</li>
			<li>多项支付方式且火速存/提款交易。</li>
		</ul>
	</div>
);

export const initValue = {
	username: "",
	password: "",
	areaCodeIdx: null,
	phone: "",
	email: "",
	currencyIdx: null,
	termsCheck: false,
	ageCheck: false
};

export const initTip = {
	username: { checked: null, msg: "" },
	password: { checked: null, msg: "" },
	phone: { checked: null, msg: "" },
	email: { checked: null, msg: "" },
	currency: { checked: null, msg: "" },
	checkbox: { checked: null, msg: "" }
};

import React from "react";
import PropTypes from "prop-types";

const AccountNamePanel = ({ t, realName, clickTip, showTip }) => (
	<>
		{t("lbl_BankAccountName")}
		<span>{realName}</span>
		<button className="icon icon-error" onClick={clickTip} />
		{showTip ? (
			<div className="tip active">{t("lbl_ContactCSD")}</div>
		) : null}
	</>
);

AccountNamePanel.propTypes = {
	t: PropTypes.func.isRequired,
	realName: PropTypes.string.isRequired,
	clickTip: PropTypes.func.isRequired,
	showTip: PropTypes.bool.isRequired
};

export default AccountNamePanel;

import React, { useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link } from "gatsby";
import { popBoxContext, userInfoContext } from "../common/context";
import { HistoryResult } from "./withdrawal.historyResult";
import { api_payment_SetWithdrawalCancel } from "../../service/api/api.payment";
import defPopBoxType from "../common/defPopBoxType";
import ReloadSpin from "../common/reloadSpin";
import path from "../../router";
import { isConvert } from "../common/common";
import { defActionType } from "./withdrawal.reducer";
import { defHistoryIdList } from "../history/def";

export default ({
	dispatch,
	recentHistory,
	refreshData,
	GetWithdrawalInfoRecent
}) => {
	const { t } = useTranslation();
	const { setPopBox } = useContext(popBoxContext);
	const { userInfo } = useContext(userInfoContext);
	const _isConvert = isConvert(userInfo.currencyId);
	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	useEffect(() => {
		GetWithdrawalInfoRecent();
	}, []);

	const ClosePopBox = () => {
		setPopBox({ isOpen: false });
	};

	const ClickCancel = orderId => {
		const ClickConfirm = () => {
			setPopBox({ isOpen: false });
			api_payment_SetWithdrawalCancel(orderId).then(
				res => {
					if (isMountedRef.current) {
						if (res.data.errorCode === 0) {
							const newData = [...recentHistory];
							const idx = newData.findIndex(
								_i => _i.orderId === orderId
							);
							newData[idx].orderStatus = 5;
							dispatch({
								type: defActionType.GET_RECENT_HISTORY,
								recentHistory: newData
							});
							refreshData();
						} else {
							setPopBox({
								isOpen: true,
								popBoxType: defPopBoxType.ErrorMsg,
								message: `[${res.data.errorCode}] ${res.data.message}`,
								btnBar: (
									<div className="btnGroup">
										<button
											className="btn-check"
											onClick={ClosePopBox}>
											{t("lbl_Close")}
										</button>
									</div>
								)
							});
						}
					}
				},
				err => {
					if (isMountedRef.current) {
						setPopBox({
							isOpen: true,
							popBoxType: defPopBoxType.ErrorMsg,
							message: t("msg_ApiServerError"),
							btnBar: (
								<div className="btnGroup">
									<button
										className="btn-check"
										onClick={ClosePopBox}>
										{t("lbl_Close")}
									</button>
								</div>
							)
						});
					}
				}
			);
		};

		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.Cancel,
			btnBar: (
				<div className="btnGroup">
					<button className="gray" onClick={ClickConfirm}>
						{t("lbl_Yes")}
					</button>
					<button className="btn-check" onClick={ClosePopBox}>
						{t("lbl_No")}
					</button>
				</div>
			)
		});
	};

	const arr = recentHistory?.map((item, idx) => (
		<HistoryResult
			{...item}
			key={`withdrawal-recent-${item.orderId}`}
			idx={idx + 1}
			i18n={t}
			_isConvert={_isConvert}
			ClickCancel={ClickCancel.bind(this, item.orderId)}
		/>
	));

	return recentHistory ? (
		<>
			<div className="list">
				<div className="listHeader">
					<div>{t("lbl_NO")}</div>
					<div>
						{t("lbl_Date")} ({moment().format("Z")})
					</div>
					<div>{t("lbl_ID")}</div>
					<div>{t("lbl_WithdrawalAccountType.accountType")}</div>
					<div>{t("lbl_Amount")}</div>
					<div>{t("lbl_Status")}</div>
					<div>
						{t("lbl_Action")}
						<ReloadSpin
							func={GetWithdrawalInfoRecent}
							btnActive={false}
						/>
					</div>
				</div>
				{recentHistory?.length > 0 ? (
					<div className="listBody">{arr}</div>
				) : (
					<div className="nodata icon icon-nodata">
						<div>{t("lbl_NoData")}</div>
					</div>
				)}
			</div>
			<Link
				to={`/${userInfo.userLang}/${path.history.root}?type=${defHistoryIdList.withdrawalId}`}
				className="icon icon-books"
			/>
		</>
	) : null;
};

import React, { useState, useRef, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
	api_account_GetMsgCount,
	api_account_GetMsgContent
} from "../../service/api/api.account";
import { popBoxContext, msgCountContext } from "../common/context";
import defPopBoxType from "../common/defPopBoxType";

export default props => {
	const { t } = useTranslation();
	const [showContent, setShowContent] = useState(false);
	const [msgContentData, setMsgContentData] = useState("");

	const isMountedRef = useRef(null);
	const { setPopBox } = useContext(popBoxContext);
	const { setMsgCount } = useContext(msgCountContext);
	const compareDay = moment().diff(
		moment(props.createTime, "YYYY-MM-DD"),
		"day"
	);

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	const clickClose = () => {
		setPopBox({ isOpen: false });
	};

	const dateTimeFormat = () => {
		if (showContent) {
			return moment(props.createdAt).format("YYYY-MM-DD HH:mm:ss");
		}
		return compareDay < 1
			? moment(props.createdAt).format("HH:mm:ss")
			: moment(props.createdAt).format("YYYY-MM-DD");
	};

	const updateMsgList = () => {
		props.getMsgList();
	};

	const updateMsgCount = () => {
		api_account_GetMsgCount().then(res => {
			if (isMountedRef.current) {
				if (res.data.errorCode === 0) {
					setMsgCount(res.data.data);
				}
			}
		});
	};

	const getMsgContent = getMsg => {
		if (getMsg) {
			api_account_GetMsgContent(props.msgId).then(
				res => {
					if (isMountedRef.current) {
						const success = res.data.errorCode === 0;
						if (success) {
							setShowContent(true);
							setMsgContentData(res.data?.data?.content);

							updateMsgList();
							updateMsgCount();
						} else {
							setPopBox({
								isOpen: true,
								popBoxType: defPopBoxType.ErrorMsg,
								message: `[${res.data.errorCode}] ${res.data.message}`,
								btnBar: (
									<div className="btnGroup">
										<button
											className="btn-check"
											onClick={clickClose}>
											{t("lbl_Close")}
										</button>
									</div>
								)
							});
						}
					}
				},
				() => {
					if (isMountedRef.current) {
						setPopBox({
							isOpen: true,
							popBoxType: defPopBoxType.ErrorMsg,
							message: t("msg_ApiServerError"),
							btnBar: (
								<div className="btnGroup">
									<button
										className="btn-check"
										onClick={clickClose}>
										{t("lbl_Close")}
									</button>
								</div>
							)
						});
					}
				}
			);
		} else {
			setShowContent(false);
		}
	};
	return (
		<div className={props.flag ? "haveRead" : ""}>
			<div className="listHeader">
				<div className="checkbox">
					<input
						type="checkbox"
						id={`msgList-checkBox-${props.msgId}`}
						onChange={props.onChangeCheck}
						checked={props.selectItems.includes(props.msgId)}
					/>
					<label
						aria-label="msgList-checkBox"
						htmlFor={`msgList-checkBox-${props.msgId}`}
						className="icon icon-checkbox-checked"
					/>
				</div>
				<div onClick={() => getMsgContent(!showContent)}>
					{props.title}
				</div>
				<div
					className="txt-blue"
					onClick={() => getMsgContent(!showContent)}>
					{dateTimeFormat()}

					<button className="icon icon-keyboard_arrow_down" />
				</div>
			</div>
			<div
				className={`listBody backstage ${showContent ? "active" : ""}`}
				dangerouslySetInnerHTML={{ __html: msgContentData }}
			/>
		</div>
	);
};

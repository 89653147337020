import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { navigate } from "gatsby";
import path from "../../router";
import productList from "../../datas/productList";
import { popBoxContext } from "../common/context";
import defPopBoxType from "../common/defPopBoxType";
import { defCurrencyList } from "../common/common";
import { useGotoGame } from "../hook/useGotoGame";

import saba from "../../styles/img/logo/saba.png";
import ab from "../../styles/img/logo/ab.png";
import dg from "../../styles/img/logo/dg.png";
import ag from "../../styles/img/logo/ag.png";
import ae from "../../styles/img/logo/ae.png";
import sa from "../../styles/img/logo/sa.png";
import pp from "../../styles/img/logo/pp.png";
import mg from "../../styles/img/logo/mg.png";
import pt from "../../styles/img/logo/pt.png";
import jk from "../../styles/img/logo/jk.png";
import sg from "../../styles/img/logo/sg.png";
import ji from "../../styles/img/logo/ji.png";
import tc from "../../styles/img/logo/tc.png";
import mp from "../../styles/img/logo/mp.png";
import rt from "../../styles/img/logo/rt.png";
import nt from "../../styles/img/logo/nt.png";
import ap from "../../styles/img/logo/ap.png";
import ev from "../../styles/img/logo/ev.png";
import ho from "../../styles/img/logo/ho.png";
import yg from "../../styles/img/logo/yg.png";

const defPlatfrom = [
	{
		...productList.polySports,
		imgName: saba,
		allowCurrencies: [defCurrencyList.THB]
	},
	{
		...productList.liveCasino_Allbet,
		imgName: ab
	},
	{
		imgName: dg
	},
	{
		...productList.liveCasino_AG,
		imgName: ag
	},
	{
		imgName: ae
	},
	{
		imgName: sa
	},
	{
		path: path.casino.root,
		imgName: pp
	},
	{
		path: path.casino.root,
		imgName: mg
	},
	{
		path: path.casino.root,
		imgName: pt
	},
	{
		path: path.casino.root,
		imgName: yg
	},
	{
		path: path.casino.root,
		imgName: jk
	},
	{
		path: path.casino.root,
		imgName: sg
	},
	{
		path: path.casino.root,
		imgName: ji
	},
	{
		imgName: tc
	},
	{
		...productList.games_MPoker,
		imgName: mp,
		allowCurrencies: [defCurrencyList.THB]
	},
	{
		path: path.casino.root,
		imgName: rt
	},
	{
		path: path.casino.root,
		imgName: nt
	},
	{
		path: path.casino.root,
		imgName: ap
	},
	{
		...productList.liveCasino_EVO,
		imgName: ev
	},
	{
		path: path.casino.root,
		imgName: ho
	}
];

const PlatformSection = ({ userLang, isLogin, currencyId }) => {
	const { handleGoToGame } = useGotoGame({ autoGetData: false });
	const { t } = useTranslation();

	const { setPopBox } = useContext(popBoxContext);

	const ClickItem = ({ path: _path, urlParams, tpId, gameId, needLogin }) => {
		const loginCallback = () => {
			setPopBox({
				isOpen: true,
				popBoxType: defPopBoxType.Login
			});
		};

		if (_path) {
			return needLogin && !isLogin
				? loginCallback()
				: navigate(`/${userLang}/${_path}${urlParams}`);
		}

		handleGoToGame({
			isLogin,
			userLang,
			tpId,
			gameId
		});
	};

	const rows = defPlatfrom
		?.filter(
			p =>
				!isLogin ||
				!p?.allowCurrencies ||
				p.allowCurrencies.includes(currencyId)
		)
		.map((p, pIdx) => {
			const key = `footer-platform-${pIdx}`;
			let params = null;
			if (p.parameter) {
				params = Object.keys(p.parameter).map(
					_key => `${_key}=${p.parameter[_key]}`
				);
			}

			const _urlParams = params !== null ? `?${params.join("&")}` : "";

			return (
				<button
					key={key}
					onClick={ClickItem.bind(this, {
						...p,
						urlParams: _urlParams
					})}>
					<img
						src={p.imgName}
						alt={`logo`}
						onBlur={() => 0}
						onFocus={() => 0}
					/>
				</button>
			);
		});

	return (
		<div className="platform">
			<div className="title">{t("lbl_Provider")}</div>
			{rows}
		</div>
	);
};

PlatformSection.propTypes = {
	userLang: PropTypes.string.isRequired
};

export default PlatformSection;

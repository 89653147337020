import React, { useEffect, useRef, useContext, useReducer } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "gatsby";
import MainPanel from "./withdrawal.set";
import WalletPanel from "./withdrawal.showWallet";
import {
	popBoxContext,
	userInfoContext,
	loadingContext
} from "../common/context";
import defPopBoxType from "../common/defPopBoxType";
import { initState, reducer, defActionType } from "./withdrawal.reducer";
import RecentHistory from "./withdrawal.recentHistory";
import path from "../../router";
import usePopBox from "../hook/usePopBox";
import { defCurrencyList } from "../common/common";
import { api_payment_GetWithdrawalInfoRecent } from "../../service/api/api.payment";
import { api_account_GetWithdrawalAvailableBalance } from "../../service/api/api.account";

export default () => {
	const { t } = useTranslation();
	const { userInfo } = useContext(userInfoContext);
	const { setPopBox } = useContext(popBoxContext);
	const { loading, setLoading } = useContext(loadingContext);
	const { errorPopBox } = usePopBox();
	const [state, dispatch] = useReducer(reducer, initState);
	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	useEffect(() => {
		if (userInfo.isLogin === true) {
			if (
				(!userInfo.realName && userInfo.realName !== undefined) ||
				(!userInfo.phoneNo && userInfo.phoneNo !== undefined) ||
				(!userInfo.email && userInfo.email !== undefined)
			) {
				setPopBox({
					isOpen: true,
					popBoxType: defPopBoxType.UpdateProfile,
					title: t("lbl_ImportantNote"),
					content: t("lbl_UpdatePersonalInfoWithdrawal"),
					btnBar: (
						<div className="btnGroup">
							<Link
								className="btn-check"
								to={`/${userInfo.userLang}/${path.profile.root}?prevPage=${path.withdrawal.root}`}>
								{t("lbl_UpdatePersonalDetail")}
							</Link>
						</div>
					)
				});
			}
		}
	}, [userInfo]);

	useEffect(() => {
		async function getData() {
			setLoading(true);
			await GetWithdrawalAvailableBalance();
			setLoading(false);
		}
		getData();
	}, []);

	const GetWithdrawalAvailableBalance = async () => {
		await api_account_GetWithdrawalAvailableBalance().then(
			res => {
				if (isMountedRef.current) {
					if (res.data.errorCode === 0) {
						dispatch({
							type: defActionType.GET_BALANCE,
							balanceData: res.data.data
						});
					} else {
						errorPopBox(
							res.data.message || "error",
							res.data.errorCode
						);
					}
					dispatch({
						type: defActionType.SET_LOADING,
						loading: false
					});
				}
			},
			() => {
				if (isMountedRef.current) {
					errorPopBox();
				}
			}
		);
	};

	const GetWithdrawalInfoRecent = async () => {
		await api_payment_GetWithdrawalInfoRecent().then(
			res => {
				if (isMountedRef.current) {
					if (res.data.errorCode === 0) {
						dispatch({
							type: defActionType.GET_RECENT_HISTORY,
							recentHistory: res.data.data
						});
					} else {
						errorPopBox(
							res.data.message || "error",
							res.data.errorCode
						);
					}
				}
			},
			() => {
				if (isMountedRef.current) {
					errorPopBox();
				}
			}
		);
	};

	const refreshData = async () => {
		await GetWithdrawalAvailableBalance();
		await GetWithdrawalInfoRecent();
	};

	return (
		!loading && (
			<div className="rightModule">
				<div className="infoModule">
					<div className="inside-title icon icon-icon32px_Withdraw">
						<span>{t("lbl_Withdrawal")}</span>
					</div>
					<div className="infoModuleLeft">
						<WalletPanel
							balanceData={state.balanceData}
							GetWithdrawalAvailableBalance={
								GetWithdrawalAvailableBalance
							}
						/>
						<MainPanel
							dispatch={dispatch}
							state={state}
							refreshData={refreshData}
						/>
					</div>

					<div className="important">
						<div className="title">
							<span className="icon icon-warning" />
							{t("lbl_ImportantNotice")}
						</div>
						<ul>
							{userInfo.currencyId !== defCurrencyList.USDT && (
								<li>
									{t("lbl_WithdrawalImportantNoticeContent2")}
								</li>
							)}
							<li>
								{t("lbl_WithdrawalImportantNoticeContent3")}
							</li>
							<li>
								{t("lbl_WithdrawalImportantNoticeContent4")}
							</li>
							<li>
								<Trans
									i18nKey="lbl_WithdrawalImportantNoticeContent1"
									components={{
										Link: (
											<a href="https://www.google.com" />
										)
									}}
								/>
							</li>
						</ul>
					</div>
				</div>
				<RecentHistory
					dispatch={dispatch}
					refreshData={refreshData}
					recentHistory={state.recentHistory ?? []}
					GetWithdrawalInfoRecent={GetWithdrawalInfoRecent}
				/>
			</div>
		)
	);
};

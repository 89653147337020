import { useContext } from "react";
import moment from "moment";
import { userInfoContext } from "../common/context";

export const initValue = {
	realName: "",
	birth: "",
	phoneNo: "",
	areaCodeIdx: null,
	email: ""
};

export const initStatus = {
	realName: {
		checked: null,
		tip: ""
	},
	birth: {
		checked: null,
		tip: ""
	},
	phoneNo: {
		checked: null,
		tip: ""
	},
	email: {
		checked: null,
		tip: ""
	}
};

export const InfoList = () => {
	const { userInfo } = useContext(userInfoContext);

	const infoList = [
		{
			displayKey: "lbl_realName",
			content: userInfo?.realName,
			show: Boolean(userInfo.realName)
		},
		{
			displayKey: "lbl_Birthday",
			content: `${userInfo?.birth_d}/${userInfo?.birth_m}/${userInfo?.birth_y}`,
			show:
				Boolean(userInfo.birth_d) &&
				Boolean(userInfo.birth_m) &&
				Boolean(userInfo.birth_y)
		},
		{
			displayKey: "lbl_Username",
			content: userInfo.username,
			show: Boolean(userInfo.username)
		},
		{
			displayKey: "lbl_Phone",
			content: userInfo?.phoneNo,
			show: Boolean(userInfo.phoneNo)
		},
		{
			displayKey: "lbl_Email",
			content: userInfo?.email,
			show: Boolean(userInfo.email)
		},
		{
			displayKey: "lbl_RegisterTime",
			content: moment(userInfo.registeredAt).format(
				"DD/MM/YYYY HH:mm:ss"
			),
			show: Boolean(userInfo.registeredAt)
		},
		{
			displayKey: "lbl_LastLogin",
			content: moment(userInfo.lastLoginAt).format("DD/MM/YYYY HH:mm:ss"),
			show: Boolean(userInfo.lastLoginAt)
		}
	];

	return infoList;
};

import React from "react";
import { Helmet } from "react-helmet";

function ThirdPartyJS() {
	return (
		<>
			<Helmet />
			{/* <GATag gaCode="UA-163834722-1" /> */}
			{/* <Iovation /> */}
			{/* <FPS /> */}
			<PlayTech />
		</>
	);
}

// const GATag = ({ gaCode }) => {
// 	return (
// 		<Helmet>
// 			<script
// 				async
// 				src={`https://www.googletagmanager.com/gtag/js?id=${gaCode}`}
// 			/>
// 			<script>
// 				{`window.dataLayer = window.dataLayer || [];
//                      function gtag(){dataLayer.push(arguments);}
//                      gtag('js', new Date());

//                      gtag('config', '${gaCode}');`}
// 			</script>
// 		</Helmet>
// 	);
// };

// const Iovation = () => {
// 	return (
// 		<Helmet>
// 			<script>
// 				{`
//                         window.IGLOO = window.IGLOO || {
//                          "enable_flash" : false,
//                          "loader" : {
//                          "fp_dyn" : false,
//                          "version" : "5.2.2"
//                          }
//                        };
//                  `}
// 			</script>
// 			<script
// 				src={`${config.openURL}/common/js/loader_only.js?v=201027`}
// 				type="text/javascript"
// 			/>
// 		</Helmet>
// 	);
// };

// const FPS = () => {
// 	return (
// 		<Helmet>
// 			<script
// 				async=""
// 				defer=""
// 				src="//sc.detecas.com/di/activator.ashx"
// 			/>
// 		</Helmet>
// 	);
// };

const PlayTech = () => (
	<Helmet>
		{/* <script
			async
			src="https://login-am.cat.com/jswrapper/hotspin88am/integration.js"
			type="text/javascript"
		/> */}
	</Helmet>
);

export default ThirdPartyJS;

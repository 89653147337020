import path from "../../router";
import { defCurrencyList } from "./common";

export const defLeftMenu = {
	Cashier: {
		displayKey: "lbl_Cashier",
		subMenu: {
			deposit: {
				path: path.deposit.root,
				displayKey: "lbl_Deposit"
			},
			withdrawal: {
				path: path.withdrawal.root,
				displayKey: "lbl_Withdrawal"
			},
			history: {
				path: path.history.root,
				displayKey: "lbl_History"
			}
		}
	},

	MyAccount: {
		displayKey: "lbl_MyAccount",
		subMenu: {
			profile: {
				path: path.profile.root,
				displayKey: "lbl_Profile"
			},
			bankInfo: {
				path: path.bankInfo.root,
				displayKey: "lbl_BankInfo",
				notAllowCurrencies: [defCurrencyList.USDT]
			},
			cryptoWallet: {
				path: path.cryptoWallet.root,
				displayKey: "lbl_CryptoWallet",
				haveCryptoInfo: true
			},
			changePassword: {
				path: path.changePassword.root,
				displayKey: "lbl_ChangePassword"
			}
		}
	},
	Message: {
		displayKey: "lbl_Message",
		subMenu: {
			message: {
				path: path.message.root,
				displayKey: "lbl_PersonalMsg"
			},
			announcement: {
				path: path.announcement.root,
				displayKey: "lbl_Announcement"
			}
		}
	}
};

import React, { useEffect, useRef, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link } from "gatsby";
import { userInfoContext, popBoxContext } from "../common/context";
import { InfoList, initValue, initStatus } from "./profile.data";
import areaCode from "../../datas/areaCode";
import {
	api_entrance_ExistsPhoneNo,
	api_entrance_ExistsEmail
} from "../../service/api/api.entrance";
import { api_accountStrict_SetProfile } from "../../service/api/api.accountStrict";
import defPopBoxType from "../common/defPopBoxType";
import usePopBox from "../hook/usePopBox";
import ProfileForm from "./profile.form";
import {
	getUrlParameter,
	currencyToAreaCode,
	currencyToCountry,
	defCurrencyList
} from "../common/common";
import { defPrevPage } from "./profile.def";
import Loading from "../common/loading";

export default () => {
	const { t } = useTranslation();
	const { userInfo, setUserInfo } = useContext(userInfoContext);
	const { setPopBox } = useContext(popBoxContext);
	const { errorPopBox } = usePopBox();
	const [userValue, setUserValue] = useState(initValue);
	const [status, setStatus] = useState(initStatus);
	const [loading, setLoading] = useState(true);
	const isMountedRef = useRef(null);
	const infoList = InfoList();

	const country = currencyToCountry[userInfo.currencyId] ?? "";

	const isUSDT = userInfo.currencyId === defCurrencyList.USDT;
	const allAreaCodeArr = Object.values(areaCode)
		.map(a => a.AreaCode)
		.sort();

	const areaCodeOptions = Array.from(new Set(allAreaCodeArr)).map(
		(_a, idx) => ({
			id: idx,
			displayText: _a
		})
	);

	// 若Profile資料已有areaCode就直接取用，若沒有再看目前是否已經有選取好的areaCode，再沒有就看currencyId有無對應的areaCode
	const _areaCodeVal = userInfo.areaCode
		? userInfo.areaCode
		: userValue.areaCodeIdx !== null
		? areaCodeOptions[userValue.areaCodeIdx - 1].displayText
		: currencyToAreaCode[userInfo.currencyId] ?? "";

	const hasRequireValueNull =
		(!userInfo.realName && userValue.realName === "") ||
		(!userInfo.birth_d && userValue.birth === "") ||
		(!userInfo.phoneNo &&
			(userValue.phoneNo === "" || _areaCodeVal === "")) ||
		(!userInfo.email && userValue.email === "");

	const hasCheckFalseStatus = Object.values(status)
		.map(item => item.checked)
		.includes(false);

	const disableSubmit = hasRequireValueNull || hasCheckFalseStatus;

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	useEffect(() => {
		if (userInfo.realName !== undefined || userInfo.phoneNo !== undefined) {
			setLoading(false);
		}
	}, [userInfo.realName, userInfo.phoneNo]);

	const onSelectAreaCode = i => {
		setUserValue(prev => ({ ...prev, areaCodeIdx: i }));
	};

	const closePopBox = () => {
		setPopBox({ isOpen: false });
	};

	const clickInfo = col => {
		let _tip = "";

		if (col === "realName") {
			_tip = status.realName.tip === "" ? t("lbl_RemindForName") : "";
		} else if (col === "phone") {
			_tip = status.phoneNo.tip === "" ? t("lbl_PhoneTooltip") : "";
		}

		if (_tip) {
			setStatus(prev => ({
				...prev,
				[col]: {
					checked: null,
					tip: _tip
				}
			}));
		}
	};

	const editField = (col, e) => {
		let val = e?.target?.value;

		if (col === "birth") {
			val = moment(e).format("YYYY-MM-DD");
		} else if (col === "realName") {
			const pattern = new RegExp(/["'`_^+-@#!<>%;)(&$.]/);
			val = val.replace(pattern, "");
		} else if (col === "phone") {
			val = val.replace(/[^\d]+/g, "");
		}

		setUserValue(prev => ({
			...prev,
			[col]: val
		}));
	};

	const phoneCheck = async (col, val) => {
		let checked = true;
		let tip = "";

		if (_areaCodeVal === "") {
			checked = false;
			tip = t("lbl_PhoneRequired");
		} else if (/[^\d]+/.test(val)) {
			checked = false;
			tip = t("lbl_PhoneFormatError");
		} else if (
			(col === "areaCode" && userValue?.phoneNo.length < 1) ||
			(col === "phoneNo" && !val)
		) {
			checked = false;
			tip = t("lbl_PhoneRequired");
		} else {
			try {
				const res = await api_entrance_ExistsPhoneNo({
					areaCode: _areaCodeVal,
					phone: col === "areaCode" ? userValue.phoneNo : val,
					lang: userInfo.userLang
				});

				if (isMountedRef.current) {
					if (res.data.errorCode === 0) {
						if (res.data.data) {
							setStatus(prev => ({
								...prev,
								phoneNo: {
									checked: false,
									tip: t("msg_PhoneExisted")
								}
							}));
						} else {
							setStatus(prev => ({
								...prev,
								phoneNo: {
									checked: true,
									tip: ""
								}
							}));
						}
					} else {
						errorPopBox(
							res.data.message || "error",
							res.data.errorCode
						);
					}
				}
			} catch (error) {
				if (!isMountedRef.current) return;
				errorPopBox();
			}
		}
		setStatus(prev => ({
			...prev,
			phoneNo: {
				checked,
				tip
			}
		}));

		return checked;
	};

	const valueCheck = async (val, col) => {
		let checked = true;
		let tip = "";

		if (col === "realName") {
			tip = !val ? t("lbl_NameRequired") : "";
			checked = !!val;
		} else if (col === "birth") {
			tip = !val ? t("lbl_BirthRequired") : "";
			checked = !!val;
		} else if (col === "email") {
			if (!val) {
				tip = t("lbl_EmailRequired");
			} else if (!/\S+@\S+\.\S+/.test(val)) {
				tip = t("lbl_EmailFormatError");
			} else {
				try {
					const res = await api_entrance_ExistsEmail({ email: val });
					if (res.data?.errorCode === 0 && res.data?.data === true) {
						tip = t("msg_MailExisted");
					} else if (res.data.errorCode !== 0) {
						errorPopBox(
							res.data.message || "error",
							res.data.errorCode
						);
					}
				} catch (error) {}
			}
			checked = !tip;
		}

		setStatus(prev => ({
			...prev,
			[col]: {
				checked,
				tip
			}
		}));

		return checked;
	};

	const clickSubmit = e => {
		e.preventDefault();

		if (disableSubmit) return;

		const realNameCheck = userInfo.realName
			? true
			: valueCheck(userValue.realName, "realName");

		const birthCheck = userInfo.birth_d
			? true
			: valueCheck(userValue.birth, "birth");

		const phoneNoCheck = userInfo.phoneNo
			? true
			: phoneCheck("phoneNo", userValue.phoneNo);

		const SetProfile = () => {
			const profileDatas = {
				email: userInfo.email ?? userValue.email,
				areaCode: userInfo.areaCode ?? _areaCodeVal,
				phoneNo: userInfo.phoneNo ?? userValue.phoneNo,
				countryCode: country,
				birth_d: userInfo.birth_d
					? userInfo.birth_d
					: userValue.birth.slice(8, 10),
				birth_m: userInfo.birth_m
					? userInfo.birth_m
					: userValue.birth.slice(5, 7),
				birth_y: userInfo.birth_y
					? userInfo.birth_y
					: userValue.birth.slice(0, 4),
				realName: userInfo.realName ?? userValue.realName
			};

			closePopBox();
			setLoading(true);

			api_accountStrict_SetProfile(profileDatas).then(
				res => {
					if (isMountedRef.current) {
						if (res.data.errorCode === 0) {
							setUserInfo(prev => ({
								...prev,
								realName: profileDatas.realName,
								birth_d: profileDatas.birth_d,
								birth_m: profileDatas.birth_m,
								birth_y: profileDatas.birth_y,
								phoneNo: profileDatas.phoneNo,
								email: profileDatas.email
							}));

							setUserValue(initValue);
							setStatus(initStatus);

							const prevPage = getUrlParameter("prevPage");

							if (defPrevPage[prevPage])
								return setPopBox({
									isOpen: true,
									popBoxType: defPopBoxType.ProfileCompelete,
									contentMsg: t(
										defPrevPage[prevPage].contentMsg
									),
									btnBar: (
										<div className="btnGroup">
											<button
												className="btn-cancel"
												onClick={closePopBox}>
												{t("lbl_Later")}
											</button>
											<Link
												className="btn-check"
												to={`/${userInfo.userLang}/${defPrevPage[prevPage].path}`}>
												{t(defPrevPage[prevPage].label)}
											</Link>
										</div>
									)
								});
						} else {
							setPopBox({
								isOpen: true,
								popBoxType: defPopBoxType.ErrorMsg,
								message: `[${res.data.errorCode}] ${res.data.message}`,
								btnBar: (
									<div className="btnGroup">
										<button
											className="btn-check"
											onClick={closePopBox}>
											{t("lbl_Close")}
										</button>
									</div>
								)
							});
						}
					}
					setLoading(false);
				},
				() => {
					if (isMountedRef.current) {
						setPopBox({
							isOpen: true,
							popBoxType: defPopBoxType.ErrorMsg,
							message: t("msg_ApiServerError"),
							btnBar: (
								<div className="btnGroup">
									<button
										className="btn-check"
										onClick={closePopBox}>
										{t("lbl_Close")}
									</button>
								</div>
							)
						});
						setLoading(false);
					}
				}
			);
		};

		if (realNameCheck && birthCheck && phoneNoCheck) {
			setPopBox({
				isOpen: true,
				popBoxType: defPopBoxType.ProfileConfirmCheck,
				realName: userValue.realName,
				btnBar: (
					<div className="btnGroup">
						<button className="btn-cancel" onClick={closePopBox}>
							{t("lbl_ReModify")}
						</button>
						<button className="btn-check" onClick={SetProfile}>
							{t("lbl_Confirm")}
						</button>
					</div>
				)
			});
		}
	};

	const doneFillIn = [
		Boolean(userInfo?.realName && userInfo?.birth_d),
		Boolean(userInfo.email),
		Boolean(userInfo.phoneNo)
	];

	const defSafetyLevel = {
		0: t("lbl_Weak"),
		1: t("lbl_Weak"),
		2: t("lbl_Normal"),
		3: t("lbl_Excellent")
	};

	const fillIncount = doneFillIn.filter(f => f).length;
	const safetyLevel = defSafetyLevel[fillIncount];

	return loading ? (
		<Loading />
	) : (
		<div className="infoModule">
			<div className="inside-title icon icon-icon32px_Profile">
				<span>{t("lbl_Profile")}</span>
				<div>{t("lbl_ProfileModifyWarning")}</div>
			</div>

			<div className="profileModule">
				<div className="vipLv">
					<div className="icon icon-member" />
					<div className="accountSafety">
						<div>{t("lbl_AccountSafety")}：</div>
						<div>{safetyLevel}</div>
					</div>
					<div className="safetyLv">
						<span className={fillIncount >= 1 ? "active" : ""} />
						<span className={fillIncount >= 2 ? "active" : ""} />
						<span className={fillIncount >= 3 ? "active" : ""} />
					</div>
					<div className="userVerification">
						<div>{t("lbl_UserVerification")}</div>
						<div>
							<div
								className={`icon icon-person${
									doneFillIn[0] ? " active" : ""
								}`}>
								<div className="description">
									<div className="title">
										{t("lbl_ProfileVerification")}
									</div>
									<div>
										{t("lbl_ProfileVerificationHint1")}
									</div>
									<div>
										{t("lbl_ProfileVerificationHint2")}
									</div>
								</div>
							</div>
							<div
								className={`icon icon-email${
									doneFillIn[1] ? " active" : ""
								}`}>
								<div className="description">
									<div className="title">
										{t("lbl_EmailVerification")}
									</div>
									<div>{t("lbl_EmailVerificationHint1")}</div>
								</div>
							</div>
							<div
								className={`icon icon-mobile${
									doneFillIn[2] ? " active" : ""
								}`}>
								<div className="description">
									<div className="title">
										{t("lbl_PhoneVerification")}
									</div>
									<div>{t("lbl_PhoneVerificationHint1")}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ProfileForm
					t={t}
					infoList={infoList}
					areaCodeVal={_areaCodeVal}
					userInfo={userInfo}
					userValue={userValue}
					status={status}
					editField={editField}
					valueCheck={valueCheck}
					phoneCheck={phoneCheck}
					clickInfo={clickInfo}
					clickSubmit={clickSubmit}
					isUSDT={isUSDT}
					areaCodeOptions={areaCodeOptions}
					onSelectAreaCode={onSelectAreaCode}
				/>
			</div>

			{!userInfo?.realName ||
			!userInfo?.birth_d ||
			!userInfo?.phoneNo ||
			!userInfo?.email ? (
				<div className="btnGroup">
					<button
						className={disableSubmit ? "btn-cancel" : "btn-check"}
						onClick={clickSubmit}>
						{t("lbl_Submit")}
					</button>
				</div>
			) : null}
		</div>
	);
};

import React from "react";
import moment from "moment";
import { number_format } from "../common/common";
import ReloadSpin from "../common/reloadSpin";

const defDepositStatusClassName = {
	1: "pending",
	2: "paid",
	3: "approved",
	4: "reject",
	5: "cancel"
};

const defWithdrawalStatusClassName = {
	1: "pending",
	2: "pending",
	3: "pending",
	4: "pending",
	6: "approved",
	7: "reject",
	5: "cancel"
};

export const defHistoryIdList = {
	depositId: "deposit",
	withdrawalId: "withdrawal",
	statement: "statement",
	bonusStatement: "bonusStatement"
};

export const defHistoryNav = [
	{
		displayKey: "lbl_Deposit",
		id: defHistoryIdList.depositId
	},
	{ displayKey: "lbl_Withdrawal", id: defHistoryIdList.withdrawalId },
	{ displayKey: "lbl_Statement", id: defHistoryIdList.statement },
	{ displayKey: "lbl_BonusStatement", id: defHistoryIdList.bonusStatement }
];

export const defColumns = ({
	t,
	_isConvert,
	filterData,
	updateHistoryList,
	depositActivateCancelConfirmModal,
	activateWithdrawalCancelConfirmModal
}) => ({
	[defHistoryIdList.depositId]: [
		{
			title: t("lbl_NO"),
			dataIndex: "no",
			key: "no",
			render: (_, index) => index + 1
		},
		{
			title: t("lbl_Date"),
			dataIndex: "createdAt",
			key: "createdAt",
			render: item => (
				<>
					{moment(item.createdAt).format("MM/DD/YYYY")}
					<span className="txt-blue">
						{moment(item.createdAt).format("hh:mm:ss A")}
					</span>
				</>
			)
		},
		{
			title: t("lbl_ID"),
			dataIndex: "orderId",
			key: "orderId"
		},
		{
			title: t("lbl_Method"),
			dataIndex: "type",
			key: "type",
			render: item => t(`lbl_DepositType${item.type}`)
		},
		{
			title: t("lbl_Amount"),
			dataIndex: "amount",
			key: "amount",
			render: item => number_format(item.amount, _isConvert)
		},
		{
			title: t("lbl_Status"),
			dataIndex: "orderStatus",
			key: "orderStatus",
			render: item => {
				const statusClassName =
					defDepositStatusClassName[item.orderStatus];
				const statusText = t(`lbl_DepositStatus${item.orderStatus}`);

				return <div className={statusClassName}>{statusText}</div>;
			}
		},
		{
			title: (
				<>
					{t("lbl_Action")}
					<ReloadSpin
						func={() =>
							updateHistoryList(
								filterData?.startDate,
								filterData?.endDate,
								true
							)
						}
					/>
				</>
			),
			dataIndex: "action",
			key: "action",
			render: item => {
				let result = null;
				if (
					item.type === 3 &&
					(item.orderStatus === 1 || item.orderStatus === 7)
				)
					result = (
						<button
							className="gray"
							onClick={() =>
								depositActivateCancelConfirmModal({
									filterData,
									orderId: item.orderId
								})
							}>
							{t("lbl_Cancel")}
						</button>
					);
				return (
					<div className="btnGroup">
						{result}
						{item.thirdId}
					</div>
				);
			}
		}
	],
	[defHistoryIdList.withdrawalId]: [
		{
			title: t("lbl_NO"),
			dataIndex: "no",
			key: "no",
			render: (_, index) => index + 1
		},
		{
			title: t("lbl_Date"),
			dataIndex: "createdAt",
			key: "createdAt",
			render: item => (
				<>
					{moment(item.createdAt).format("MM/DD/YYYY")}
					<span className="txt-blue">
						{moment(item.createdAt).format("hh:mm:ss A")}
					</span>
				</>
			)
		},
		{
			title: t("lbl_ID"),
			dataIndex: "orderId",
			key: "orderId"
		},
		{
			title: t("lbl_WithdrawalAccountType.accountType"),
			dataIndex: "accountType",
			key: "accountType",
			render: item => t(`lbl_WithdrawalAccountType.${item.accountType}`)
		},
		{
			title: t("lbl_Amount"),
			dataIndex: "amount",
			key: "amount",
			render: item => number_format(item.amount, false)
		},
		{
			title: t("lbl_Status"),
			dataIndex: "status",
			key: "status",
			render: item => {
				const statusClassName =
					defWithdrawalStatusClassName[item.orderStatus];
				const statusText = t(`lbl_WithdrawalStatus${item.orderStatus}`);

				return <div className={statusClassName}>{statusText}</div>;
			}
		},
		{
			title: (
				<>
					{t("lbl_Action")}
					<ReloadSpin
						func={() =>
							updateHistoryList(
								filterData?.startDate,
								filterData?.endDate,
								true
							)
						}
					/>
				</>
			),
			dataIndex: "action",
			key: "action",
			render: item => {
				if (item.orderStatus !== 1) return null;

				return (
					<div className="btnGroup">
						<button
							className="btn-cancel"
							onClick={() =>
								activateWithdrawalCancelConfirmModal(
									item.orderId
								)
							}>
							{t("lbl_Cancel")}
						</button>
					</div>
				);
			}
		}
	],
	[defHistoryIdList.statement]: [
		{
			title: t("lbl_NO"),
			dataIndex: "no",
			key: "no",
			render: (_, index) => index + 1
		},
		{
			title: `${t("lbl_Date")} (${moment().format("Z")})`,
			dataIndex: "createdAt",
			key: "createdAt",
			render: item => (
				<>
					{moment(item.createdAt).format("MM/DD/YYYY")}
					<span className="txt-blue">
						{moment(item.createdAt).format("HH:mm:ss")}
					</span>
				</>
			)
		},
		{
			title: t("lbl_RefTransId"),
			dataIndex: "refId",
			key: "refId"
		},
		{
			title: t("lbl_TypeName"),
			dataIndex: "typeName",
			key: "typeName"
		},
		{
			title: t("lbl_ChangeAmount"),
			dataIndex: "amount",
			key: "amount",
			render: item => number_format(item.amount, _isConvert)
		},
		{
			title: t("lbl_BeforeAmount"),
			dataIndex: "beforeBalance",
			key: "beforeBalance",
			render: item => number_format(item.beforeBalance, _isConvert)
		},
		{
			title: (
				<>
					{t("lbl_AfterAmount")}
					<ReloadSpin
						func={() =>
							updateHistoryList(
								filterData?.startDate,
								filterData?.endDate,
								true
							)
						}
					/>
				</>
			),
			dataIndex: "afterBalance",
			key: "afterBalance",
			render: item => number_format(item.afterBalance, _isConvert)
		}
	],
	[defHistoryIdList.bonusStatement]: [
		{
			title: t("lbl_NO"),
			dataIndex: "no",
			key: "no",
			render: (_, index) => index + 1
		},
		{
			title: `${t("lbl_Date")} (${moment().format("Z")})`,
			dataIndex: "createdAt",
			key: "createdAt",
			render: item => (
				<>
					{moment(item.createdAt).format("MM/DD/YYYY")}
					<span className="txt-blue">
						{moment(item.createdAt).format("HH:mm:ss")}
					</span>
				</>
			)
		},
		{
			title: t("lbl_RefTransId"),
			dataIndex: "refId",
			key: "refId"
		},
		{
			title: t("lbl_TypeName"),
			dataIndex: "typeName",
			key: "typeName"
		},
		{
			title: t("lbl_ChangeAmount"),
			dataIndex: "amount",
			key: "amount",
			render: item => number_format(item.amount, _isConvert)
		},
		{
			title: t("lbl_BeforeAmount"),
			dataIndex: "beforeBalance",
			key: "beforeBalance",
			render: item => number_format(item.beforeBalance, _isConvert)
		},
		{
			title: (
				<>
					{t("lbl_AfterAmount")}
					<ReloadSpin
						func={() =>
							updateHistoryList(
								filterData?.startDate,
								filterData?.endDate,
								true
							)
						}
					/>
				</>
			),
			dataIndex: "afterBalance",
			key: "afterBalance",
			render: item => number_format(item.afterBalance, _isConvert)
		}
	]
});

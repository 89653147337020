import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { defMetaData } from "./def";
import { defLangList } from "../../datas/languageData";
import { cloudImgUrl } from "../../config";

function SEO({ lang, meta }) {
	const location = useLocation()
		?.pathname?.split("/")
		.filter(p => p !== "");

	let metaData = defMetaData["/"];
	let _title = metaData?.[lang]?.title ?? "";
	let _keyword = metaData?.[lang]?.keyword ?? "";
	let _description = metaData?.[lang]?.description ?? "";
	let links = metaData?.[lang]?.links ?? [];

	if (
		location.length >= 2 &&
		defLangList.includes(location[0]) &&
		defMetaData[location[1]]
	) {
		metaData = defMetaData[location[1]] ?? metaData;
		_title = defMetaData[location[1]]?.[lang]?.title ?? _title;

		_keyword = defMetaData[location[1]]?.[lang]?.keyword ?? _keyword;

		_description =
			defMetaData[location[1]]?.[lang]?.description ?? _description;

		links = defMetaData[location[1]]?.[lang]?.links ?? links;
	}

	return (
		<Helmet
			htmlAttributes={{
				lang,
				class: "newIndex"
			}}
			title={_title}
			link={[
				{
					rel: "icon",
					type: "image/x-icon",
					href: `${cloudImgUrl}/favicon.ico`
				}
			]}
			meta={[
				{
					name: `description`,
					content: _description
				},
				{
					name: `keyword`,
					content: _keyword
				},
				{
					property: `og:title`,
					content: _title
				},
				{
					property: `og:description`,
					content: _description
				},
				{
					property: `og:type`,
					content: `website`
				},
				{
					name: `twitter:card`,
					content: `summary`
				},
				{
					name: `twitter:creator`,
					content: _title
				},
				{
					name: `twitter:title`,
					content: _title
				},
				{
					name: `twitter:description`,
					content: _description
				}
			].concat(meta ?? [])}
		/>
	);
}

SEO.defaultProps = {
	lang: `en`
};

SEO.propTypes = {
	lang: PropTypes.string
};

export default SEO;

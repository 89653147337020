import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { api_accountStrict_DelCustCryptoWallet } from "../../service/api/api.accountStrict";
import usePopBox from "../hook/usePopBox";
import defPopBoxType from "../common/defPopBoxType";
import { popBoxContext, cryptoInfoContext } from "../common/context";
import Loading from "../common/loading";

const WalletCard = ({ walletList, loading, GetCryptoWallet }) => {
	const { t } = useTranslation();
	const { errorPopBox } = usePopBox();
	const { setPopBox } = useContext(popBoxContext);
	const { cryptoInfo } = useContext(cryptoInfoContext);

	const delCryptowalletConfirm = ({
		cryptoId,
		groupId,
		nickName,
		walletAddress
	}) => {
		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.ConfirmDelete,
			message: t("lbl_AreYouSureDelete"),
			message2: t("lbl_CantBeRestore"),
			btnBar: (
				<div className="btnGroup">
					<button
						className="btn-check"
						onClick={() =>
							onDeleteWallet({
								cryptoId,
								groupId,
								nickName,
								walletAddress
							})
						}>
						{t("lbl_Yes")}
					</button>
					<button
						className="btn-cancel"
						onClick={() => setPopBox({ isOpen: false })}>
						{t("lbl_No")}
					</button>
				</div>
			)
		});
	};

	const onDeleteWallet = ({ cryptoId, groupId, nickName, walletAddress }) => {
		api_accountStrict_DelCustCryptoWallet({
			cryptoId,
			groupId,
			nickName,
			walletAddress
		})
			.then(res => {
				if (res.data.errorCode === 0) {
					setPopBox({ isOpen: false });
					GetCryptoWallet();
				} else {
					errorPopBox(
						res.data.message || "error",
						res.data.errorCode
					);
				}
			})
			.catch(() => {
				errorPopBox();
			});
	};

	const arr = walletList?.map(item => {
		const _cryptoInfo = cryptoInfo?.find(c => c.cryptoId === item.cryptoId);

		const cryptoName = _cryptoInfo?.crypto;

		const chainName = _cryptoInfo?.chains?.find(
			ci => ci.groupId === item.groupId
		)?.chain;

		return (
			<div
				className="addBankName"
				key={`walletCard-${item.cryptoId}-${item.groupId}`}>
				<div className="cryptoInfo">
					<i className={`icon icon-${cryptoName}`} />
					<div>{item.walletAddress}</div>
					<button
						className="icon icon-bin"
						onClick={() =>
							delCryptowalletConfirm({
								cryptoId: item.cryptoId,
								groupId: item.groupId,
								nickName: item.nickName,
								walletAddress: item.walletAddress
							})
						}
					/>
				</div>
				<div className="walletNickname">
					{`${item.nickName} (${cryptoName}/${chainName})`}
				</div>
			</div>
		);
	});
	return loading ? (
		<Loading />
	) : walletList?.length > 0 ? (
		<div className="infoModule">
			<div className="walletModule">
				<div className="addBank haveBank">{arr}</div>
			</div>
		</div>
	) : null;
};

WalletCard.propTypes = {
	walletList: PropTypes.arrayOf(PropTypes.any).isRequired,
	loading: PropTypes.bool.isRequired
};

export default WalletCard;

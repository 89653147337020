import React from "react";
import { number_format, isConvert } from "../common/common";
import ReloadSpin from "../common/reloadSpin";
import { defBonusType } from "./promotion.def";

export default ({
	custBonus,
	isJoin,
	t,
	currencyId,
	refreshProcessBar,
	hideReloadBtn,
	bonusType,
	fullFillType
}) => {
	if (
		isJoin &&
		bonusType !== defBonusType.cashback &&
		bonusType !== defBonusType.rebate
	) {
		const _isConvert = isConvert(currencyId);
		const percentage =
			custBonus.turnover >= custBonus.rollover
				? 100
				: ((custBonus.turnover / custBonus.rollover) * 100).toFixed(2);

		return (
			<div className="progressBarModule">
				<div className="bounsName">
					{fullFillType === 2
						? t("lbl_WinOverAccumulated")
						: t("lbl_TurnoverAccumulated")}
				</div>
				<div className="turnover">
					<span>
						{t(`Currency_${currencyId}`)}{" "}
						{number_format(custBonus.turnover, _isConvert)}
					</span>{" "}
					({t("lbl_Req")} {t(`Currency_${currencyId}`)}{" "}
					{number_format(custBonus.rollover, _isConvert)})
					{hideReloadBtn ? null : (
						<ReloadSpin func={refreshProcessBar} />
					)}
				</div>
				<div className="progressBar">
					<div className="bar">
						<div
							className="progress"
							style={{ width: `${percentage}%` }}
						/>
					</div>
					<div className="percentage">{percentage}%</div>
				</div>
			</div>
		);
	}

	return null;
};

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SiteInfo } from "../../config";
import qrcode from "../../styles/img/qrcode.png";

const defElemType = {
	button: "button"
};

export default () => {
	const { t } = useTranslation();
	const [isIconListOpen, setIsIconListOpen] = useState(false);
	const [activeIcon, setActiveIcon] = useState(null);

	const switchIconActive = _mouseOverIcon => {
		setActiveIcon(_mouseOverIcon);
	};

	const removeIconActive = () => {
		setActiveIcon(null);
	};

	const toggleIconMenu = _nowShow => {
		setIsIconListOpen(false);
		if (_nowShow === "contactUs") {
			setIsIconListOpen(true);
		}
	};

	const defIconList = [
		{
			iconClass: "icon icon-instagram",
			onClick: () => window.open(SiteInfo.socialMedia.ig.link),
			imgInfo: "@Beyond88Insta",
			imgSrc: qrcode,
			link: SiteInfo.socialMedia.ig.link
		},
		{
			iconClass: "icon icon-facebook2",
			link: SiteInfo.socialMedia.fb.link,
			imgInfo: "@Beyond88FB",
			imgSrc: qrcode
		},
		{
			iconClass: "icon icon-twitter",
			link: SiteInfo.socialMedia.twitter.link,
			imgInfo: "@Beyond88Twitter",
			imgSrc: qrcode
		},
		{
			iconClass: "icon icon-whatsapp",
			imgInfo: "+12 345 678 90",
			imgSrc: qrcode,
			link: "https://wa.me"
		},
		{
			iconClass: "icon icon-line",
			imgInfo: "@Beyond88Line",
			imgSrc: qrcode,
			link: "https://line.me"
		},
		{
			iconClass: "icon icon-telegram",
			link: "https://t.me",
			imgInfo: "@Beyond88Telegram",
			imgSrc: qrcode
		},
		{
			iconClass: "icon icon-envelope",
			link: "mailto:support@beyond88.com"
		}
	];

	const icons = defIconList;

	const iconList = (icons.length === 0 ? defIconList : icons).map(
		(_icon, idx) => {
			const img = _icon.imgSrc && (
				<div>
					{_icon.imgInfo}
					<img src={_icon.imgSrc} alt={`${_icon.iconClass}`} />
				</div>
			);

			if (_icon.elemType === defElemType.button) {
				return (
					<button
						key={`sidebar-link-${idx}`}
						onClick={() => _icon.onClick() ?? null}
						className={`icon ${_icon.iconClass}${
							activeIcon === idx ? " active" : ""
						}`}
						onMouseOver={switchIconActive.bind(this, idx)}
						onFocus={() => 0}
						onMouseOut={removeIconActive.bind(this)}
						onBlur={() => 0}>
						{img}
					</button>
				);
			}

			return (
				<a
					key={`sidebar-link-${idx}`}
					href={_icon.link}
					target="_blank"
					rel="noreferrer"
					className={`icon ${_icon.iconClass}${
						activeIcon === idx ? " active" : ""
					}`}
					onMouseOver={switchIconActive.bind(this, idx)}
					onFocus={() => 0}
					onMouseOut={removeIconActive.bind(this)}
					onBlur={() => 0}>
					{img}
				</a>
			);
		}
	);

	return (
		<>
			<div
				className={`quickLinkNew1${isIconListOpen ? " active" : ""}`}
				onMouseOver={toggleIconMenu.bind(this, "contactUs")}
				onFocus={() => 0}>
				<i className="icon icon-arrow-left2" />
				<div>{t("lbl_ContactUs")}</div>
			</div>
			<div
				className={`quickLinkNew2${isIconListOpen ? "" : " active"}`}
				onMouseLeave={toggleIconMenu.bind(this, "")}
				onBlur={() => 0}>
				<i className="icon icon-arrow-right2" />
				{iconList}
			</div>
		</>
	);
};

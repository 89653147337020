export const defDepositType = {
	localBank: "LocalBank",
	cryptoPay: "CryptoPay",
	mobilePay: "MobilePay",
	autoDeposit: "AutoDeposit"
};

export const defDepositOptions = ({
	localBankGroupList = [],
	cryptoPayGroupList = [],
	mobilePayGroupList = [],
	autoDepositGroupList = []
}) => [
	{
		type: defDepositType.localBank,
		depositDisplayKey: "lbl_WireTransfers",
		isHide: !localBankGroupList.length,
		creditTime: "lbl_5mins",
		iconClass: "icon icon-library"
	},
	{
		type: defDepositType.cryptoPay,
		depositDisplayKey: "lbl_CryptoPay",
		isHide: !cryptoPayGroupList.length,
		creditTime: "lbl_Instant",
		iconClass: "icon icon-USDT"
	},
	{
		type: defDepositType.mobilePay,
		isHide: !mobilePayGroupList.length,
		depositDisplayKey: "lbl_DepositType2",
		creditTime: "lbl_Instant",
		iconClass: "icon icon-mobile"
	},
	{
		type: defDepositType.autoDeposit,
		isHide: !autoDepositGroupList.length,
		depositDisplayKey: "lbl_DepositType1",
		creditTime: "lbl_Instant",
		iconClass: "icon icon-connection"
	}
];

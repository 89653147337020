import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { userInfoContext } from "../common/context";
import PlatformSection from "./footer.platform";
import FollowUsSection from "./footer.followUs";
import { getCurrencyCode } from "../common/common";

const Footer = ({ isFullFooter = false }) => {
	const { t } = useTranslation();
	const { userInfo } = useContext(userInfoContext);
	const isMountedRef = useRef(null);

	let currencyId = null;
	if (userInfo.isLogin) {
		currencyId = userInfo.currencyId;
	} else if (userInfo.isLogin === false) {
		currencyId = getCurrencyCode(userInfo.userLang);
	}

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	let _userLang = userInfo?.defaultLang;
	if (userInfo.userLang) {
		_userLang = userInfo.userLang;
	}

	return (
		<footer id="footer">
			{isFullFooter && (
				<PlatformSection
					t={t}
					isLogin={userInfo.isLogin}
					userLang={userInfo.userLang}
					currencyId={currencyId}
				/>
			)}
			<FollowUsSection
				t={t}
				userLang={_userLang}
				currencyId={userInfo.currencyId ?? null}
			/>
			<CopyrightSection t={t} userLang={_userLang} />
		</footer>
	);
};

export const CopyrightSection = () => (
	<div className="copyright">
		<span>{`© Copyright ${new Date().getFullYear()}. Beyond88. All Rights Reserved. 18+`}</span>
	</div>
);

Footer.defaultProps = {
	isFullFooter: undefined
};

Footer.propTypes = {
	isFullFooter: PropTypes.bool
};

export default Footer;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useGotoGame } from "../hook/useGotoGame";
import Jackpot from "./jackpot";

import PG_01 from "../../styles/img/slot/pg/PG_01.png";
import PG_02 from "../../styles/img/slot/pg/PG_02.png";
import PG_03 from "../../styles/img/slot/pg/PG_03.png";
import PG_04 from "../../styles/img/slot/pg/PG_04.png";
import PG_05 from "../../styles/img/slot/pg/PG_05.png";
import PG_06 from "../../styles/img/slot/pg/PG_06.png";
import PG_07 from "../../styles/img/slot/pg/PG_07.png";
import PG_08 from "../../styles/img/slot/pg/PG_08.png";
import PG_09 from "../../styles/img/slot/pg/PG_09.png";
import PG_10 from "../../styles/img/slot/pg/PG_10.png";

const HotGameList = ({ t, userInfo }) => {
	const [gameList, setGameList] = useState([
		{
			tpId: 9,
			gameId: "byz81hmsq748k",
			tpGameType: 13,
			tpCategory: 2,
			gameName: "Hip Hop Panda",
			gameLogo: PG_01
		},
		{
			tpId: 9,
			gameId: "byz81hmsq748k",
			tpGameType: 13,
			tpCategory: 2,
			gameName: "Legend Of Houyi",
			gameLogo: PG_02
		},
		{
			tpId: 27,
			gameId: "test22",
			tpGameType: 46,
			tpCategory: 2,
			gameName: "Mr Hallow Win",
			gameLogo: PG_03
		},
		{
			tpId: 9,
			gameId: "byz81hmsq748k",
			tpGameType: 13,
			tpCategory: 2,
			gameName: "Prosperity Lion",
			gameLogo: PG_04
		},
		{
			tpId: 27,
			gameId: "test22",
			tpGameType: 46,
			tpCategory: 2,
			gameName: "Santa's Gift Rush",
			gameLogo: PG_05
		},
		{
			tpId: 27,
			gameId: "test22",
			tpGameType: 46,
			tpCategory: 2,
			gameName: "Gem Saviour Sword",
			gameLogo: PG_06
		},
		{
			tpId: 27,
			gameId: "test223",
			tpGameType: 46,
			tpCategory: 2,
			gameName: "Piggy Gold",
			gameLogo: PG_07
		},
		{
			tpId: 27,
			gameId: "test221",
			tpGameType: 46,
			tpCategory: 2,
			gameName: "Jungle Delight",
			gameLogo: PG_08
		},
		{
			tpId: 27,
			gameId: "test221",
			tpGameType: 46,
			tpCategory: 2,
			gameName: "Symbols Of Egypt",
			gameLogo: PG_09
		},
		{
			tpId: 27,
			gameId: "test221",
			tpGameType: 46,
			tpCategory: 2,
			gameName: "Ganesha Fortune",
			gameLogo: PG_10
		}
	]);

	const { handleGoToGame } = useGotoGame({
		isLogin: userInfo.isLogin,
		userLang: userInfo.userLang,
		autoGetData: false
	});

	const ClickGame = (tpId, gameId, tpGameType) => {
		handleGoToGame({
			isLogin: userInfo.isLogin,
			userLang: userInfo.userLang,
			tpId,
			gameId,
			tpGameType
		});
	};

	const rows = gameList?.map((g, gIdx) => {
		return (
			<div
				key={`home-hotGame-${g.tpId}-${g.gameName}-${gIdx}`}
				onClick={() => ClickGame(g.tpId, g.gameId, g.tpGameType)}>
				<img src={g.gameLogo} alt={g.gameName} />
				<div className="gameMask">
					<div className="gameInfo">
						<div>{g.gameName}</div>
						{/* <button className="icon icon-star-empty"/> */}
					</div>
					<div className="gameBtnGroup">
						<button>{t("lbl_Free")}</button>
						<button>{t("lbl_PlayNow")}</button>
					</div>
				</div>
			</div>
		);
	});

	return (
		rows?.length > 0 && (
			<>
				<div className="moduleTitle">{t("lbl_HOT_GAMES")}</div>
				<div className="jpotModule">
					<Jackpot t={t} userInfo={userInfo} />
					<div className="slotModule">{rows}</div>
				</div>
			</>
		)
	);
};

HotGameList.propTypes = {
	t: PropTypes.func.isRequired,
	userInfo: PropTypes.objectOf(PropTypes.any).isRequired
};

export default HotGameList;

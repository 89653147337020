import React, { useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { defActionType, defPanel } from "./withdrawal.reducer";
import { api_accountStrict_DelCustBankInfo } from "../../service/api/api.accountStrict";
import { api_payment_GetBankInfoList } from "../../service/api/api.payment";
import {
	popBoxContext,
	userInfoContext,
	cryptoInfoContext
} from "../common/context";
import defPopBoxType from "../common/defPopBoxType";
import usePopBox from "../hook/usePopBox";
import BankCard from "./withdrawal.set.card.bank";
import CryptoCard from "./withdrawal.set.card.crypto";
import { defCurrencyList } from "../common/common";

const CardPanel = ({ dispatch, state, GetCustBankInfo, GetCryptoWallet }) => {
	const { t } = useTranslation();
	const { userInfo } = useContext(userInfoContext);
	const { setPopBox } = useContext(popBoxContext);
	const { cryptoInfo } = useContext(cryptoInfoContext);
	const { errorPopBox } = usePopBox();
	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	const selectCryptoWallet = accountData => {
		dispatch({
			type: defActionType.SELECT_ACCOUNT,
			selectedAccount: {
				cryptoId: accountData.cryptoId,
				groupId: accountData.groupId,
				nickName: accountData.nickName,
				walletAddress: accountData.walletAddress
			}
		});
	};

	const selectBankAccount = accountData => {
		dispatch({
			type: defActionType.SELECT_ACCOUNT,
			selectedAccount: {
				bankName: accountData.bankName,
				accountNo: accountData.accountNo,
				bankInfoId: accountData.bankInfoId
			}
		});
	};

	const ClosePopBox = () => {
		setPopBox({ isOpen: false });
	};

	const addBankAccount = () => {
		api_payment_GetBankInfoList().then(
			res => {
				if (isMountedRef.current) {
					if (res.data.errorCode === 0) {
						const datas = res.data.data;
						setPopBox({
							isOpen: true,
							popBoxType: defPopBoxType.AddBankAccount,
							bankInfoSysList: datas,
							custBankInfo: state.custBankInfo,
							GetCustBankInfo
						});
					} else {
						errorPopBox(
							res.data.message || "error",
							res.data.errorCode
						);
					}
				}
			},
			() => {
				if (isMountedRef.current) {
					errorPopBox();
				}
			}
		);
	};

	const addCryptoWallet = () => {
		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.AddCryptoWallet,
			GetCryptoWallet
		});
	};

	const delAccount = (accountNo, sysId) => {
		const delBankInfo = () => {
			api_accountStrict_DelCustBankInfo({
				bankInfoId: sysId,
				accountNo
			}).then(
				res => {
					if (isMountedRef.current) {
						if (res.data.errorCode === 0) {
							GetCustBankInfo();
							if (accountNo === state.selectedAccount.accountNo) {
								dispatch({
									type: defActionType.SELECT_ACCOUNT,
									selectedAccount: {},
									step: 1
								});
							}
							setPopBox({ isOpen: false });
						} else {
							errorPopBox(
								res.data.message || "error",
								res.data.errorCode
							);
						}
						dispatch({
							type: defActionType.SET_LOADING,
							loading: false
						});
					}
				},
				() => {
					if (isMountedRef.current) {
						errorPopBox();
						dispatch({
							type: defActionType.SET_LOADING,
							loading: false
						});
					}
				}
			);
		};

		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.ConfirmDelete,
			message: t("lbl_AreYouSureDelete"),
			message2: t("lbl_CantBeRestore"),
			btnBar: (
				<div className="btnGroup">
					<button className="gray" onClick={delBankInfo}>
						{t("lbl_Yes")}
					</button>
					<button className="btn-check" onClick={ClosePopBox}>
						{t("lbl_No")}
					</button>
				</div>
			)
		});
	};
	return (
		<>
			{userInfo.currencyId !== defCurrencyList.USDT && (
				<BankCard
					t={t}
					state={state}
					defPanel={defPanel}
					addBankAccount={addBankAccount}
					selectAccount={selectBankAccount}
					delAccount={delAccount}
				/>
			)}
			{cryptoInfo && cryptoInfo.length > 0 && (
				<CryptoCard
					t={t}
					state={state}
					selectAccount={selectCryptoWallet}
					addCryptoWallet={addCryptoWallet}
					GetCryptoWallet={GetCryptoWallet}
					defPanel={defPanel}
				/>
			)}
		</>
	);
};

CardPanel.propTypes = {
	state: PropTypes.objectOf(PropTypes.any).isRequired,
	dispatch: PropTypes.func.isRequired,
	GetCustBankInfo: PropTypes.func.isRequired
};

export default CardPanel;

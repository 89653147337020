import React, { useState, useEffect, useRef, useContext } from "react";
import moment from "moment";
import { useLocation } from "@reach/router";
import { useTranslation } from "react-i18next";
import { isConvert, getUrlParameter } from "../common/common";
import { userInfoContext } from "../common/context";

import useDepositHistory from "./hook/useDepositHistory";
import useWithdrawalHistory from "./hook/useWithdrawalHistory";
import useStatementHistory from "./hook/useStatement";
import useBonusStatement from "./hook/useBonusStatement";

import HistoryTagNav from "./historyTagNav";
import HistoryFilter from "./historyFilter";
import HistoryTable from "./historyTable";
import { Pagination } from "../common/pagination";
import { defHistoryIdList, defHistoryNav, defColumns } from "./def";

const initFilterData = {
	startDate: moment().startOf("months").format("YYYY-MM-DD"),
	endDate: moment().format("YYYY-MM-DD")
};

const initPagination = {
	totalPages: 0,
	currentPage: 1
};

const History = () => {
	const paramType = getUrlParameter("type");

	const { t } = useTranslation();
	const location = useLocation();

	const { userInfo } = useContext(userInfoContext);

	const _isConvert = isConvert(userInfo.currencyId);

	const isMountedRef = useRef(null);

	const [historyDatas, setHistoryDatas] = useState([]);
	const [processing, setProcessing] = useState(false);
	const [filterData, setFilterData] = useState(initFilterData);
	const [cacheFilterData, setCacheFilterData] = useState({});
	const [pagination, setPagination] = useState(initPagination);

	const updateHistoryList = (
		startDate,
		endDate,
		isRefresh,
		selectedIdx,
		selectedBonusIdx
	) => {
		if (!processing) {
			setProcessing(true);
			let transactionData = cacheFilterData;
			const currentPage = isRefresh ? pagination.currentPage : 1;

			if (!isRefresh) {
				transactionData = {
					startDate: moment(startDate).unix(),
					endDate: moment(endDate).endOf("day").unix(),
					promotionCode: bonusList?.[selectedBonusIdx]?.promotionCode,
					bonusId: bonusList?.[selectedBonusIdx]?.bonusId,
					refId: bonusList?.[selectedBonusIdx]?.refId
				};
				setCacheFilterData(transactionData);
				setPagination(prev => ({ ...prev, currentPage }));
			}

			getHistory(transactionData, currentPage);
		}
	};

	const { getDepositHistory, depositActivateCancelConfirmModal } =
		useDepositHistory({
			t,
			isMountedRef,
			pagination,
			setHistoryDatas,
			setPagination
		});

	const { getWithdrawalInfoHistory, activateWithdrawalCancelConfirmModal } =
		useWithdrawalHistory({
			t,
			isMountedRef,
			setHistoryDatas,
			setPagination
		});

	const { getStatementHistory } = useStatementHistory({
		isMountedRef,
		setHistoryDatas,
		setPagination
	});

	const { getBonusStatementHistory, bonusList } = useBonusStatement({
		isMountedRef,
		setHistoryDatas,
		setPagination,
		setFilterData
	});

	const [selectedTagId, setSelectedTagId] = useState(paramType);

	const columns = defColumns({
		t,
		_isConvert,
		filterData,
		updateHistoryList,
		depositActivateCancelConfirmModal,
		activateWithdrawalCancelConfirmModal
	})[selectedTagId];

	const clickPageNumber = page => {
		if (!processing) {
			const transactionData = cacheFilterData;
			setProcessing(true);
			setPagination(prev => ({ ...prev, currentPage: page }));
			getHistory(transactionData, page);
		}
	};

	const getHistory = (transactionData, pageNumber) => {
		switch (selectedTagId) {
			case defHistoryIdList.depositId:
				getDepositHistory(transactionData, pageNumber);

				break;

			case defHistoryIdList.withdrawalId:
				getWithdrawalInfoHistory(transactionData, pageNumber);

				break;

			case defHistoryIdList.statement:
				getStatementHistory(transactionData, pageNumber);
				break;

			case defHistoryIdList.bonusStatement:
				getBonusStatementHistory(transactionData, pageNumber);
				break;

			default:
				console.log("!!");
				break;
		}

		setProcessing(false);
	};

	const resetPage = () => {
		setFilterData(initFilterData);
		setCacheFilterData({});
		setHistoryDatas([]);
		setPagination(initPagination);
	};

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	useEffect(() => {
		const kindParamsSome = Object.values(defHistoryIdList).some(
			item => item === paramType
		);

		if (!kindParamsSome) {
			window.history.replaceState(
				{},
				"",
				`${location.pathname}?type=${defHistoryIdList.depositId}`
			);

			setSelectedTagId(defHistoryIdList.depositId);
		}
	}, [paramType, location.pathname]);

	useEffect(() => {
		if (
			bonusList?.length > 0 &&
			selectedTagId === defHistoryIdList.bonusStatement
		) {
			setFilterData(prev => ({ ...prev, selectedBonusIdx: 0 }));
		}
	}, [bonusList?.length, selectedTagId]);

	return (
		<div className="infoModule">
			<div className="inside-title icon icon-icon32px_History">
				<span>{t("lbl_History")}</span>
			</div>

			<HistoryTagNav
				t={t}
				pathname={location.pathname}
				defHistoryNav={defHistoryNav}
				resetPage={resetPage}
				selectedTagId={selectedTagId}
				setSelectedTagId={setSelectedTagId}
			/>

			<div className="historyModule active">
				<HistoryFilter
					submitHandler={updateHistoryList}
					filterData={filterData}
					setFilterData={setFilterData}
					bonusList={bonusList}
					selectedTagId={selectedTagId}
				/>

				<HistoryTable
					t={t}
					dataSource={historyDatas}
					columns={columns}
				/>

				<Pagination
					totalPages={pagination.totalPages}
					currentPage={pagination.currentPage}
					clickPageNumber={clickPageNumber}
				/>
			</div>
		</div>
	);
};

export default History;

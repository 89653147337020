import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CardPanel from "./withdrawal.set.card";
import AmountPanel from "./withdrawal.set.amount";
import ConfirmPanel from "./withdrawal.set.confirm";
import { isConvert } from "../common/common";
import { userInfoContext, popBoxContext } from "../common/context";
import defPopBoxType from "../common/defPopBoxType";
import { defActionType } from "./withdrawal.reducer";
import {
	api_account_GetCustBankInfo,
	api_account_GetCustCryptoWallet
} from "../../service/api/api.account";
import usePopBox from "../hook/usePopBox";

const MainPanel = ({ dispatch, state, refreshData }) => {
	const { t } = useTranslation();
	const { userInfo } = useContext(userInfoContext);
	const { setPopBox } = useContext(popBoxContext);
	const _isConvert = isConvert(userInfo.currencyId);
	const isMountedRef = useRef(null);
	const { errorPopBox } = usePopBox();

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	useEffect(() => {
		GetCustBankInfo();
		GetCryptoWallet();
	}, []);

	const GetCustBankInfo = () => {
		const ClosePopBox = () => {
			setPopBox({ isOpen: false });
		};

		api_account_GetCustBankInfo().then(
			res => {
				if (isMountedRef.current) {
					if (res.data.errorCode === 0) {
						const datas = res.data.data;

						const _custBankInfo = {
							uSDTDetail: datas?.uSDTDetail || [],
							bankInfo: datas?.bankInfo
						};

						dispatch({
							type: defActionType.GET_CUST_BANKINFO,
							custBankInfo: _custBankInfo
						});
					} else {
						setPopBox({
							isOpen: true,
							popBoxType: defPopBoxType.ErrorMsg,
							message: `[${res.data.errorCode}] ${res.data.message}`,
							btnBar: (
								<div className="btnGroup">
									<button
										className="btn-check"
										onClick={ClosePopBox}>
										{t("lbl_Close")}
									</button>
								</div>
							)
						});
					}
					dispatch({
						type: defActionType.SET_LOADING,
						loading: false
					});
				}
			},
			() => {
				if (isMountedRef.current) {
					setPopBox({
						isOpen: true,
						popBoxType: defPopBoxType.ErrorMsg,
						message: t("msg_ApiServerError"),
						btnBar: (
							<div className="btnGroup">
								<button
									className="btn-check"
									onClick={ClosePopBox}>
									{t("lbl_Close")}
								</button>
							</div>
						)
					});
					dispatch({
						type: defActionType.SET_LOADING,
						loading: false
					});
				}
			}
		);
	};

	const GetCryptoWallet = () => {
		api_account_GetCustCryptoWallet().then(
			res => {
				if (isMountedRef.current) {
					if (res.data.errorCode === 0) {
						const resData = res.data.data;
						if (resData) {
							dispatch({
								type: defActionType.GET_CRYPTO_WALLET,
								cryptoWallet: resData.wallets
							});
						}
					} else {
						errorPopBox(
							res.data.message || "error",
							res.data.errorCode
						);
					}

					dispatch({
						type: defActionType.SET_LOADING,
						loading: false
					});
				}
			},
			() => {
				if (isMountedRef.current) {
					errorPopBox();
					dispatch({
						type: defActionType.SET_LOADING,
						loading: false
					});
				}
			}
		);
	};

	return (
		<>
			<CardPanel
				dispatch={dispatch}
				state={state}
				GetCustBankInfo={GetCustBankInfo}
				GetCryptoWallet={GetCryptoWallet}
			/>
			<div className="subModuleList">
				{state.panel === "amount" ? (
					<AmountPanel
						dispatch={dispatch}
						state={state}
						t={t}
						_isConvert={_isConvert}
						userInfo={userInfo}
					/>
				) : (
					<ConfirmPanel
						dispatch={dispatch}
						t={t}
						_isConvert={_isConvert}
						selectedAccount={state.selectedAccount}
						realName={userInfo.realName}
						amount={state.amount}
						refreshData={refreshData}
						receiveUSDT={state.receiveUSDT}
					/>
				)}
			</div>
		</>
	);
};

MainPanel.propTypes = {
	state: PropTypes.objectOf(PropTypes.any).isRequired,
	dispatch: PropTypes.func.isRequired,
	refreshData: PropTypes.func.isRequired
};

export default MainPanel;

import { useEffect } from "react";
import {
	getUrlParameter,
	getLocalStorage,
	removeLocalStorage
} from "../../common/common";
import { defDepositOptions } from "../deposit.def";
import usePopBox from "../../hook/usePopBox";

const useDepositEffect = ({
	userInfo,
	formInfo,
	isMountedRef,
	depositOptions,
	isInitDataDone,
	localBankGroupList,
	cryptoPayGroupList,
	mobilePayGroupList,
	autoDepositGroupList,
	setLoading,
	onValidAmount,
	setDepositType,
	setIsInitDataDone,
	setDepositOptions,
	getLocalBankGroupList,
	getCryptoPayGroupList,
	getMobilePayGroupList,
	getAutoDepositGroupList
}) => {
	const parameterType = getUrlParameter("type");
	const { errorPopBox } = usePopBox();

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			try {
				await Promise.all([
					getLocalBankGroupList(),
					getCryptoPayGroupList(),
					getMobilePayGroupList(),
					getAutoDepositGroupList()
				]);
				setIsInitDataDone(true);
			} catch (e) {
				errorPopBox();
			}

			setLoading(false);
		};

		getData();
	}, []);

	useEffect(() => {
		const checkRemoveStorage = () => {
			const savedCheck = getLocalStorage(
				`${userInfo.username}-beyond88depositquerySavedChecked`
			);

			if (!savedCheck) {
				removeLocalStorage(`${userInfo.username}-beyond88depositquery`);
				removeLocalStorage(
					`${userInfo.username}-beyond88depositquerySavedChecked`
				);
			}
		};
		const initializeDepositOptions = () => {
			if (isInitDataDone) {
				const originalDepositOptions = defDepositOptions({
					localBankGroupList,
					cryptoPayGroupList,
					mobilePayGroupList,
					autoDepositGroupList
				});

				const filterDepositOptions = originalDepositOptions.filter(
					item => !item.isHide
				);

				if (filterDepositOptions.length) {
					setDepositOptions(filterDepositOptions);
				}
			}
		};

		initializeDepositOptions();

		window.addEventListener("beforeunload", checkRemoveStorage);

		return () => {
			checkRemoveStorage();

			window.removeEventListener("beforeunload", checkRemoveStorage);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInitDataDone]);

	useEffect(() => {
		if (isInitDataDone) {
			const originalDepositOptions = defDepositOptions({
				localBankGroupList,
				cryptoPayGroupList,
				mobilePayGroupList,
				autoDepositGroupList
			});
			const filterDepositOptions = originalDepositOptions.filter(
				item => !item.isHide
			);
			setDepositOptions(filterDepositOptions);
		}
	}, [userInfo, isInitDataDone]);

	useEffect(() => {
		const initializeDepositType = () => {
			const getDepositType = () => {
				if (
					Object.values(depositOptions).some(
						item => item.type === parameterType
					)
				)
					return parameterType;

				return depositOptions[0].type;
			};

			if (userInfo.username) {
				const initialDepositType = getDepositType();

				setDepositType(initialDepositType);
			}
		};

		if (depositOptions.length > 0) {
			initializeDepositType();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInfo.username, depositOptions]);

	useEffect(() => {
		if (formInfo.amount !== null) {
			onValidAmount(formInfo.amount);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formInfo.amount, formInfo.minAmount, formInfo.maxAmount]);
};

export { useDepositEffect };

import React from "react";
import moment from "moment";
import { Link } from "gatsby";
import { defBonusType } from "./promotion.def";
import defPopBoxType from "../common/defPopBoxType";
import path from "../../router";
import { isConvert, number_format } from "../common/common";

export const isArrayNotEmpty = arr => Array.isArray(arr) && arr.length;

export const isExpiry = ({ periodTo, custBonus, isLogin, isJoin, bonusType }) =>
	isLogin &&
	isJoin &&
	bonusType !== defBonusType.rebate &&
	bonusType !== defBonusType.cashback
		? moment(custBonus?.expiryTime).isBefore(new moment())
		: moment(periodTo).isBefore(new moment());

export const isNew = ({ periodFrom }) =>
	moment(periodFrom).isAfter(new moment().add(-14, "days"));

export const isClaimed = ({ isJoin }) => isJoin;

export const checkPromotion = ({ promotionInfo, userInfo, setPopBox, t }) => {
	const {
		canJoin,
		isJoin,
		result,
		requiredAmount,
		depositAmount,
		promotionCode
	} = promotionInfo;

	const { isLogin, currencyId, realName, phoneNo, email, userLang } =
		userInfo;

	const clickClose = () => {
		setPopBox({ isOpen: false });
	};

	if (promotionInfo.showComing) {
		return false;
	}

	if (isLogin === false)
		return setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.Login
		});

	if (currencyId && (!realName || !phoneNo || !email))
		return setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.UpdateProfile,
			title: t("lbl_ImportantNote"),
			content: t("lbl_UpdatePersonalInfoPromotion"),
			btnBar: (
				<div className="btnGroup">
					<Link
						className="btn-check"
						to={`/${userLang}/${path.profile.root}?prevPage=${path.promotion.root}&promotionCode=${promotionCode}`}>
						{t("lbl_UpdatePersonalDetail")}
					</Link>
				</div>
			)
		});

	if (canJoin && !isJoin && result === 1 && requiredAmount > 0) {
		const lessAmount = number_format(
			requiredAmount - depositAmount,
			isConvert(currencyId)
		);
		const _currency = t(`Currency_${currencyId}`);
		const hint = `${_currency} ${lessAmount}$`;

		return setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.PromotionDepositConfirm,
			hint,
			btnBar: (
				<div className="btnGroup">
					<button className="btn-cancel" onClick={clickClose}>
						{t("lbl_Later")}
					</button>
					<Link
						to={`/${userLang}/${path.deposit.root}`}
						type="button"
						className="btn-check">
						{t("lbl_DepositNow")}
					</Link>
				</div>
			)
		});
	}

	return true;
};

export const RULE_TYPE = {
	info: "info",
	list: "list"
};

import React from "react";
import {
	api_payment_GetDepositInfoHistory,
	api_payment_SetDepositCancel
} from "../../../service/api/api.payment";
import defPopBoxType from "../../common/defPopBoxType";

import usePopBox from "../../hook/usePopBox";

export default function useDepositHistory({
	t,
	isMountedRef,
	pagination,
	setHistoryDatas,
	setPagination
}) {
	const { errorPopBox, closePopBox, setPopBox } = usePopBox();

	const depositActivateCancelConfirmModal = ({ filterData, orderId }) => {
		const clickConfirm = () => {
			closePopBox();
			api_payment_SetDepositCancel(orderId).then(
				res => {
					if (isMountedRef.current) {
						if (res.data.errorCode === 0) {
							getDepositHistory(
								filterData,
								pagination.currentPage
							);
						} else {
							errorPopBox(
								res.data.message || "error",
								res.data.errorCode
							);
						}
					}
				},
				() => {
					if (isMountedRef.current) {
						errorPopBox();
					}
				}
			);
		};
		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.Cancel,
			btnBar: (
				<div className="btnGroup">
					<button className="btn-check" onClick={clickConfirm}>
						{t("lbl_Yes")}
					</button>
					<button className="btn-cancel" onClick={closePopBox}>
						{t("lbl_No")}
					</button>
				</div>
			)
		});
	};

	const getDepositHistory = async (transactionData, pageNumber) => {
		await api_payment_GetDepositInfoHistory({
			...transactionData,
			pageNumber
		}).then(
			res => {
				if (!isMountedRef.current) return;
				const { errorCode, data, totalPages, message } = res.data;
				if (errorCode === 0) {
					setHistoryDatas(data);
					setPagination(prev => ({
						...prev,
						totalPages
					}));
				} else {
					errorPopBox(message || "error", errorCode);
				}
			},
			() => {
				if (!isMountedRef.current) return;

				errorPopBox();
			}
		);
	};

	return {
		getDepositHistory,
		// depositActivateUploadModal,
		depositActivateCancelConfirmModal
	};
}

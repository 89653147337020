import React from "react";
import Wrapper from "../components/common/wrapper";
import Layout from "../components/common/layout";
import Footer from "../components/footer/footer";
import Fishing from "../components/fishing";

export default () => (
	<Wrapper>
		<Layout>
			<main>
				<Fishing />
			</main>
			<Footer isFullFooter={true} />
		</Layout>
	</Wrapper>
);

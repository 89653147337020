import { req } from "./base";

export const api_promotion_GetPromotionList = () =>
	req.post(`/Promotion/GetPromotionList`);

export const api_promotion_GetPromotionDetail = ({ promotionCode }) =>
	req.post(`/Promotion/GetPromotionDetail`, {
		promotionCode
	});

export const api_promotion_SetClaimPromotion = ({
	promotionCode,
	lockedAmount
}) =>
	req.post(`/Promotion/SetClaimPromotion`, {
		promotionCode,
		lockedAmount
	});

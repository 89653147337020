import React from "react";
import PropTypes from "prop-types";
import { cloudImgUrl } from "../../config";

import hpa from "../../styles/img/footer/hpa.png";
import eeziepay from "../../styles/img/footer/eeziepay.png";
import fpay from "../../styles/img/footer/fpay.png";
import truepay from "../../styles/img/footer/truepay.png";
import tngqr from "../../styles/img/footer/tngqr.png";
import duitnowqr from "../../styles/img/footer/duitnowqr.png";
import thaiqr from "../../styles/img/footer/thaiqr.png";
import ovo from "../../styles/img/footer/ovo.png";
import dana from "../../styles/img/footer/dana.png";
import linkaja from "../../styles/img/footer/linkaja.png";
import shopeepay from "../../styles/img/footer/shopeepay.png";
import doku from "../../styles/img/footer/doku.png";

const paymentList = [
	{
		name: "help2pay",
		img: `${cloudImgUrl}/footer/hpa.png`
	},
	{
		name: "eeziepay",
		img: `${cloudImgUrl}/footer/eeziepay.png`
	},
	{ name: "fpay", img: `${cloudImgUrl}/footer/fpay.png` },
	{
		name: "truepay",
		img: `${cloudImgUrl}/footer/truepay.png`
	},
	{ name: "tngqr", img: `${cloudImgUrl}/footer/tngqr.png` },
	{
		name: "duitnowqr",
		img: `${cloudImgUrl}/footer/duitnowqr.png`
	},
	{
		name: "thaiqr",
		img: `${cloudImgUrl}/footer/thaiqr.png`
	},
	{
		name: "ovo",
		img: `${cloudImgUrl}/footer/ovo.png`
	},
	{
		name: "dana",
		img: `${cloudImgUrl}/footer/dana.png`
	},
	{
		name: "linkaja",
		img: `${cloudImgUrl}/footer/linkaja.png`
	},
	{
		name: "shopeepay",
		img: `${cloudImgUrl}/footer/shopeepay.png`
	},
	{
		name: "doku",
		img: `${cloudImgUrl}/footer/doku.png`
	}
];

const defSocialMedia = {
	fb: "https://www.facebook.com ",
	twitter: "https://twitter.com",
	ig: "https://www.instagram.com ",
	yt: "https://www.youtube.com",
	telegram: "https://t.me",
	pinterest: "https://id.pinterest.com"
};

const socialMedias = () => (
	<>
		<a href={defSocialMedia.fb} target="_blank" rel="noreferrer">
			<img src={`${cloudImgUrl}/footer/fba.png`} alt="fb" />
		</a>
		<a href={defSocialMedia.ig} target="_blank" rel="noreferrer">
			<img src={`${cloudImgUrl}/footer/ina.png`} alt="ig" />
		</a>
		<a href={defSocialMedia.yt} target="_blank" rel="noreferrer">
			<img src={`${cloudImgUrl}/footer/yta.png`} alt="youtube" />
		</a>
		<a href={defSocialMedia.twitter} target="_blank" rel="noreferrer">
			<img src={`${cloudImgUrl}/footer/twa.png`} alt="twitter" />
		</a>
		<a href={defSocialMedia.telegram} target="_blank" rel="noreferrer">
			<img src={`${cloudImgUrl}/footer/tga.png`} alt="telegram" />
		</a>
	</>
);

const FollowUsSection = ({ t }) => {
	const paymentArr = paymentList?.map(_p => (
		<a key={_p.name}>
			<img src={_p.img} alt={_p.name} />
		</a>
	));

	return (
		<div className="sfp">
			<div>
				<div className="title">{t("lbl_Follow_Us")}</div>
				<div>{socialMedias()}</div>
			</div>
			<div>
				<div className="title">{t("lbl_Suggested_Browser")}</div>
				<div>
					<img src={`${cloudImgUrl}/footer/gga.png`} alt="chrome" />
					<img src={`${cloudImgUrl}/footer/ffa.png`} alt="firefox" />
					<img src={`${cloudImgUrl}/footer/iea.png`} alt="edge" />
					<img src={`${cloudImgUrl}/footer/sfa.png`} alt="safari" />
				</div>
			</div>
			<div>
				<div className="title">{t("lbl_Payment_Method")}</div>
				<div className="paymentLogo">{paymentArr}</div>
			</div>
		</div>
	);
};

FollowUsSection.propTypes = {
	t: PropTypes.func.isRequired
};

export default FollowUsSection;

import { useState } from "react";
import usePopBox from "../../hook/usePopBox";
import {
	api_payment_GetBankInfoListStatus,
	api_payment_DepositBankTransfer,
	api_payment_GetMerchantBankInfo
} from "../../../service/api/api.payment";

const useDepositLocalBank = ({ isMountedRef }) => {
	const [localBankGroupList, setLocalBankGroupList] = useState([]);
	const [localBankInfo, setLocalBankInfo] = useState({});
	const { errorPopBox } = usePopBox();

	const getLocalBankGroupList = async () => {
		await api_payment_GetBankInfoListStatus().then(
			res => {
				if (!isMountedRef.current) return;

				const { data, message, errorCode } = res.data;
				const success = errorCode === 0;

				if (success && data.length > 0) {
					setLocalBankGroupList(data);
				} else if (!success) {
					errorPopBox(message || `error`, errorCode);
				}
			},
			() => {
				if (!isMountedRef.current) return;

				errorPopBox();
			}
		);
	};

	const getLocalBankInfo = async ({ bankInfoId }) => {
		await api_payment_GetMerchantBankInfo({ bankInfoId }).then(
			res => {
				if (!isMountedRef.current) return;

				const { data, message, errorCode } = res.data;
				const success = errorCode === 0;

				if (success) {
					setLocalBankInfo(data);
				} else {
					errorPopBox(message || "error", errorCode);
				}
			},
			() => {
				if (!isMountedRef.current) return;

				errorPopBox();
			}
		);
	};

	const postLocalBankReqWireTransfer = async ({ amount, bankInfoId }) => {
		try {
			const res = await api_payment_DepositBankTransfer({
				amount,
				bankInfoId
			});

			if (!isMountedRef.current) return;

			const { message, errorCode } = res.data;
			const success = errorCode === 0;

			if (!success) {
				errorPopBox(message || `error`, errorCode);
			}
		} catch (error) {
			if (!isMountedRef.current) return;

			errorPopBox();
		}
	};

	return {
		getLocalBankGroupList,
		getLocalBankInfo,
		localBankGroupList,
		localBankInfo,
		postLocalBankReqWireTransfer
	};
};

export { useDepositLocalBank };

import React, { useContext } from "react";
import { Link, navigate } from "gatsby";
import {
	GET_OPEN_RULE,
	_ACTION_TYPE,
	OPEN_IN_BLANK
} from "../../service/enterPage";
import defPopBoxType from "./defPopBoxType";
import { popBoxContext } from "./context";
import path from "../../router";
import { defEventIcon } from "./eventIcon";

export const getUrl = (preUrl, info) =>
	`/${preUrl}?tpId=${info.tpId}&gameId=${info.gameId}`;

export const getCurrentPage = () =>
	typeof window !== undefined
		? `${window.location.pathname}${window.location.search}`
		: "";
export default ({
	defMenuLinks,
	defMenuType,
	t,
	showSubMenu,
	currentMenuKey,
	userLang,
	isLogin,
	checkMatchPage,
	toUpperCase = false,
	currencyId
}) => {
	const { setPopBox } = useContext(popBoxContext);
	const showLogin = () => {
		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.Login
		});
	};

	const _showSubMenu = key => {
		if (showSubMenu) {
			showSubMenu(key);
		}
	};

	const currentPage = checkMatchPage ? getCurrentPage() : "";

	const fullMenuLinks = Object.keys(defMenuLinks);

	const filterMenuLinks = fullMenuLinks.filter(key => {
		if (defMenuLinks[key].subMenu?.length > 0) {
			return defMenuLinks[key].subMenu.length > 0;
		}

		return true;
	});

	const showRows = isLogin ? filterMenuLinks : fullMenuLinks;

	const rows = showRows.map(key => {
		const m = defMenuLinks[key];
		let _className = m.className ? m.className : "";
		if (m.isHot) {
			_className = `${_className} hot`;
		}

		const iconList = m.eventIcons?.length > 0 ? [...m.eventIcons] : [];
		const isAllowCurrencies = obj =>
			obj?.allowCurrencies
				? obj.allowCurrencies.includes(currencyId)
				: true;

		if (m.subMenu) {
			m.subMenu
				.filter(sub => !isLogin || isAllowCurrencies(sub))
				.forEach(sub => {
					if (sub.eventIcons) {
						for (let i = 0; i < sub.eventIcons.length; i++) {
							if (sub.eventIcons[i] !== "") {
								iconList.push(sub.eventIcons[i]);
							}
						}
					}
				});
		}

		let iconStrings = "";

		if (iconList.length > 0) {
			iconStrings = `${Array.from(new Set(iconList)).join(" ")}`;
			if (iconStrings === defEventIcon.new) {
				iconStrings = defEventIcon.new_header;
			}
		}

		let iconContent = null;
		switch (iconStrings) {
			case defEventIcon.new_header:
				iconContent = <>NEW</>;
				break;
			case defEventIcon.live:
				iconContent = <i />;
				break;
			case defEventIcon.hot:
				iconContent = <>HOT</>;
				break;
		}

		_className = `${_className}${currentMenuKey === key ? " active" : ""}`;

		const displayText = toUpperCase
			? t(m.displayKey)?.toUpperCase()
			: t(m.displayKey);

		const _path = m.path;

		if (m.productInfo && isAllowCurrencies(m)) {
			const rule = GET_OPEN_RULE(
				userLang,
				m.productInfo.tpId,
				m.productInfo.gameId
			);

			const isBlank = rule.actionType === _ACTION_TYPE.blank;

			if (rule.needLogin && isLogin === false) {
				return (
					<button
						key={key}
						onClick={showLogin}
						className={_className}
						onMouseOver={_showSubMenu.bind(this, key)}
						onFocus={() => 0}>
						<div className={iconStrings}>{iconContent}</div>
						{displayText}
					</button>
				);
			}

			if (isBlank && rule.windowKey) {
				return (
					<a
						key={key}
						className={_className}
						onMouseOver={_showSubMenu.bind(this, key)}
						onClick={OPEN_IN_BLANK.bind(
							this,
							rule.entPageUrl,
							rule.windowKey
						)}
						onFocus={() => 0}>
						<div className={iconStrings}>{iconContent}</div>
						{displayText}
					</a>
				);
			}

			if (!_path) {
				return (
					<button
						key={key}
						className={_className}
						onMouseOver={_showSubMenu.bind(this, key)}
						onFocus={() => 0}>
						<div className={iconStrings}>{iconContent}</div>
						{displayText}
					</button>
				);
			}

			const _url = getUrl(`${userLang}/${_path}`, m.productInfo);

			_className =
				checkMatchPage && _url === currentPage
					? _className
						? `${_className} active`
						: "active"
					: _className;

			return (
				<Link
					key={key}
					to={_url}
					className={_className}
					onMouseOver={_showSubMenu.bind(this, key)}>
					<div className={iconStrings}>{iconContent}</div>
					{displayText}
				</Link>
			);
		}

		const _menuType = m.menuType ?? "";

		if ((_path || _menuType === defMenuType.Link) && isAllowCurrencies(m)) {
			_className =
				checkMatchPage && `${userLang}/${_path}` === currentPage
					? _className
						? `${_className} active`
						: "active"
					: _className;

			return (
				<Link
					key={key}
					to={
						_path === "/" ? `/${userLang}` : `/${userLang}/${_path}`
					}
					onMouseOver={_showSubMenu.bind(this, key)}
					className={_className}>
					<div className={iconStrings}>{iconContent}</div>
					{displayText}
				</Link>
			);
		}

		return !isLogin || isAllowCurrencies(m) ? (
			<button
				key={key}
				onMouseOver={_showSubMenu.bind(this, key)}
				className={_className}
				onFocus={() => 0}>
				<div className={iconStrings}>{iconContent}</div>
				{displayText}
			</button>
		) : null;
	});

	return rows;
};

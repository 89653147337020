import { api_account_GetStatement } from "../../../service/api/api.account";
import usePopBox from "../../hook/usePopBox";

export default function useStatementHistory({
	t,
	isMountedRef,
	setHistoryDatas,
	setPagination
}) {
	const { errorPopBox } = usePopBox();

	const getStatementHistory = async (transactionData, pageNumber) => {
		await api_account_GetStatement({
			...transactionData,
			pageNumber
		}).then(
			res => {
				if (!isMountedRef.current) return;
				const { errorCode, data, totalPages, message } = res.data;
				if (errorCode === 0) {
					setHistoryDatas(data);
					setPagination(prev => ({
						...prev,
						totalPages
					}));
				} else {
					errorPopBox(message || "error", errorCode);
				}
			},
			() => {
				if (!isMountedRef.current) return;

				errorPopBox();
			}
		);
	};

	return {
		getStatementHistory
	};
}

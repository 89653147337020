import React from "react";

import {
	api_payment_SetWithdrawalCancel,
	api_payment_GetWithdrawalInfoHistory
} from "../../../service/api/api.payment";

import defPopBoxType from "../../common/defPopBoxType";
import usePopBox from "../../hook/usePopBox";

export default function useWithdrawalHistory({
	t,
	isMountedRef,
	setHistoryDatas,
	setPagination
}) {
	const { errorPopBox, closePopBox, setPopBox } = usePopBox();

	const setWithdrawalCancel = async orderId => {
		await api_payment_SetWithdrawalCancel(orderId).then(
			res => {
				if (!isMountedRef.current) return;
				const { errorCode, message } = res.data;
				if (errorCode === 0) {
					setHistoryDatas(prev => {
						const newData = [...prev];
						const idx = newData.findIndex(
							x => x.orderId === orderId
						);
						newData[idx].orderStatus = 5;
						return newData;
					});
				} else {
					errorPopBox(message || "error", errorCode);
				}
			},
			() => {
				if (!isMountedRef.current) return;

				errorPopBox();
			}
		);
	};

	const activateWithdrawalCancelConfirmModal = orderId => {
		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.Cancel,
			btnBar: (
				<div className="btnGroup">
					<button
						className="btn-cancel"
						onClick={() => {
							closePopBox();
							setWithdrawalCancel(orderId);
						}}>
						{t("lbl_Yes")}
					</button>
					<button className="btn-check" onClick={closePopBox}>
						{t("lbl_No")}
					</button>
				</div>
			)
		});
	};

	const getWithdrawalInfoHistory = async (transactionData, pageNumber) => {
		await api_payment_GetWithdrawalInfoHistory({
			...transactionData,
			pageNumber
		}).then(
			res => {
				if (!isMountedRef.current) return;
				const { errorCode, data, totalPages, message } = res.data;
				if (errorCode === 0) {
					setHistoryDatas(data);
					setPagination(prev => ({
						...prev,
						totalPages
					}));
				} else {
					errorPopBox(message || "error", errorCode);
				}
			},
			() => {
				if (!isMountedRef.current) return;

				errorPopBox();
			}
		);
	};

	return {
		getWithdrawalInfoHistory,
		activateWithdrawalCancelConfirmModal
	};
}

import React, { useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { popBoxContext, cryptoInfoContext } from "../common/context";
import defPopBoxType from "../common/defPopBoxType";
import usePopBox from "../hook/usePopBox";
import { api_accountStrict_DelCustCryptoWallet } from "../../service/api/api.accountStrict";

const CryptoCard = ({
	t,
	state,
	selectAccount,
	addCryptoWallet,
	GetCryptoWallet,
	defPanel
}) => {
	const isMountedRef = useRef(null);
	const { errorPopBox } = usePopBox();
	const { setPopBox } = useContext(popBoxContext);
	const { cryptoInfo } = useContext(cryptoInfoContext);
	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});
	const cryptoWallet = state.cryptoWallet || [];

	const delCryptowalletConfirm = ({
		cryptoId,
		groupId,
		nickName,
		walletAddress
	}) => {
		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.ConfirmDelete,
			message: t("lbl_AreYouSureDelete"),
			message2: t("lbl_CantBeRestore"),
			btnBar: (
				<div className="btnGroup">
					<button
						className="btn-check"
						onClick={() =>
							onDeleteWallet({
								cryptoId,
								groupId,
								nickName,
								walletAddress
							})
						}>
						{t("lbl_Yes")}
					</button>
					<button
						className="btn-cancel"
						onClick={() => setPopBox({ isOpen: false })}>
						{t("lbl_No")}
					</button>
				</div>
			)
		});
	};

	const onDeleteWallet = ({ cryptoId, groupId, nickName, walletAddress }) => {
		api_accountStrict_DelCustCryptoWallet({
			cryptoId,
			groupId,
			nickName,
			walletAddress
		})
			.then(res => {
				if (res.data.errorCode === 0) {
					setPopBox({ isOpen: false });
					GetCryptoWallet();
				} else {
					errorPopBox(
						res.data.message || "error",
						res.data.errorCode
					);
				}
			})
			.catch(() => {
				errorPopBox();
			});
	};

	const cryptoWalletArr = cryptoWallet?.map(item => {
		const _key = `${item.cryptoId}-${item.walletAddress}`;
		const { walletAddress, cryptoId } = state.selectedAccount;

		const _checked =
			item.walletAddress === walletAddress && item.cryptoId === cryptoId;

		const _cryptoInfo = cryptoInfo?.find(c => c.cryptoId === item.cryptoId);

		const cryptoName = _cryptoInfo.crypto;

		const chainName = _cryptoInfo?.chains?.find(
			ci => ci.groupId === item.groupId
		)?.chain;

		return (
			<CryptoCardLayout
				{...item}
				key={_key}
				delUSDTwallet={delCryptowalletConfirm}
				checkKey={`${item.cryptoId}-${item.walletAddress}`}
				selectAccount={selectAccount.bind(this, item)}
				checked={_checked}
				hideDelBtn={state.panel === defPanel.confirm}
				chainName={chainName}
				cryptoName={cryptoName}
			/>
		);
	});

	return (
		<div className="walletModule">
			<div className="addBank">
				<div className="title">{t("lbl_CryptoWallet")}</div>
				{cryptoWalletArr}
				{cryptoWalletArr?.length < 3 && (
					<div
						className="addBankName noBank"
						onClick={addCryptoWallet}
						key={`withdrawal-crypto-noBank`}>
						<div className="icon icon-plus" />
						<div>{t("lbl_Add_Crypto_Wallet")}</div>
					</div>
				)}
				<div className="remainder">
					{t("lbl_Number_Of_Wallet")}:{cryptoWallet?.length ?? 0} / 3
				</div>
			</div>
		</div>
	);
};

const CryptoCardLayout = props => (
	<div className="addBankName">
		<div className="cryptoInfo">
			<div className="title">{props.nickName}</div>
			<div>{`${props.cryptoName} / ${props.chainName}`}</div>
			<div className="addBankInfo">
				<i className={`icon icon-${props.cryptoName}`} />
				<div>{props.walletAddress}</div>
				<div className="checkbox">
					<input
						type="checkbox"
						id={props.checkKey}
						onChange={props.selectAccount}
						checked={props.checked}
					/>
					<label
						htmlFor={props.checkKey}
						className="icon icon-checkbox-checked"
					/>
					{props.hideDelBtn ? null : (
						<button
							className="icon icon-bin"
							onClick={() =>
								props.delUSDTwallet({
									cryptoId: props.cryptoId,
									groupId: props.groupId,
									nickName: props.nickName,
									walletAddress: props.walletAddress
								})
							}
						/>
					)}
				</div>
			</div>
		</div>
	</div>
);

CryptoCard.propTypes = {
	t: PropTypes.func.isRequired,
	state: PropTypes.objectOf(PropTypes.any).isRequired,
	selectAccount: PropTypes.func.isRequired,
	addCryptoWallet: PropTypes.func.isRequired
};

CryptoCardLayout.defaultProps = {
	checkKey: null,
	nickName: ""
};

CryptoCardLayout.propTypes = {
	checkKey: PropTypes.string,
	selectAccount: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
	nickName: PropTypes.string
};
export default CryptoCard;

import React, { useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import Swiper from "react-id-swiper";
import SwiperCore, {
	Navigation,
	Pagination,
	Autoplay as SwiperCoreAutoPlay
} from "swiper";
import { Link } from "gatsby";
import { defBannerActionMode } from "../../service/api/site";
import { userInfoContext, popBoxContext } from "./context";
import path from "../../router";
import defPopBoxType from "./defPopBoxType";
import banner1 from "../../styles/img/big-banner.jpg";
import banner2 from "../../styles/img/big-banner2.jpg";

const NeedLoginRoute = [];

SwiperCore.use([Navigation, Pagination, SwiperCoreAutoPlay]);

export const ClickLink = ({
	tpId,
	gameId,
	matchId,
	actionMode,
	bannerLink,
	lang,
	isLogin,
	setPopBox,
	openInBlank,
	tpGameType
}) => {
	const HandleLogin = () => {
		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.Login
		});
	};

	if (actionMode === defBannerActionMode.OpenWindow) {
		window.open(bannerLink, "_blank");
	} else if (actionMode === defBannerActionMode.RedirectPage) {
		window.location.href = bannerLink;
	} else if (actionMode === defBannerActionMode.RedirectPageOfThisSite) {
		HandleLogin();
	} else if (actionMode === defBannerActionMode.GoToGame) {
		// use go to game
	}
};

export const SetPathParameter = ({ urlRouter, tpId, gameId, matchId }) => {
	let result = "";
	if (urlRouter === path.casino.root) {
		result = `?tpId=${tpId}${gameId ? `&gameId=${gameId}` : ""}`;
	} else if (urlRouter === path.sports.root) {
		result = `?tpId=${tpId}${gameId ? `&gameId=${gameId}` : ""}${
			matchId ? `&matchId=${matchId}` : ""
		}`;
	}
	return result;
};

export const useGetBanner = ({ userLang, ImgFolder }) => {
	const [banner, setBanner] = useState([
		{
			bannerName: "Iconic",
			bannerType: 1,
			actionMode: 6,
			promotionCode: null,
			matchId: "0",
			tpId: "24",
			gameId: null,
			urlRouter: "casino",
			leaguesId: null,
			details: null,
			bannerLink: "",
			bannerPCUrl: banner1,
			bannerMobileUrl: banner1,
			logo: null,
			backgroundPic: null,
			tpGameType: 39
		},
		{
			bannerName: "Welcome Bonus",
			bannerType: 1,
			actionMode: 2,
			promotionCode: null,
			matchId: "0",
			tpId: "1",
			gameId: "2001",
			urlRouter: null,
			leaguesId: null,
			details: null,
			bannerLink: "",
			bannerPCUrl: banner2,
			bannerMobileUrl: banner2,
			logo: null,
			backgroundPic: null,
			tpGameType: 1
		}
	]);

	return { banner };
};

const Panel = ({ ImgFolder, className, openInBlank }) => {
	const { userInfo } = useContext(userInfoContext);
	const { banner } = useGetBanner({ userLang: userInfo.userLang, ImgFolder });
	const { setPopBox } = useContext(popBoxContext);

	const handleClick = ({
		tpId,
		gameId,
		matchId,
		actionMode,
		bannerLink,
		tpGameType
	}) => {
		return ClickLink({
			tpId,
			gameId,
			matchId,
			actionMode,
			bannerLink,
			tpGameType,
			lang: userInfo.userLang,
			isLogin: userInfo.isLogin,
			setPopBox,
			openInBlank
		});
	};

	const rows = banner?.map((b, bIdx) => {
		let redirectLink = null;

		if (b.actionMode === defBannerActionMode.GoToBonus) {
			redirectLink = `/${path.promotion.root}/${path.promotion.sub.promotionInfo.root}?promotionCode=${b.promotionCode}`;
		} else if (
			b.actionMode === defBannerActionMode.RedirectPageOfThisSite
		) {
			const parameter = SetPathParameter({ ...b });
			redirectLink =
				!userInfo.isLogin && NeedLoginRoute.includes(b.urlRouter)
					? null
					: `/${b.urlRouter}${parameter}`;
		}

		if (redirectLink) {
			return (
				<Link
					key={`${userInfo.userLang}-${bIdx}`}
					to={`/${userInfo.userLang}${redirectLink}`}>
					<img
						src={b.bannerMobileUrl}
						className="banner"
						alt={b.bannerName ?? ""}
					/>
				</Link>
			);
		}

		return (
			<button
				key={`${userInfo.userLang}-${bIdx}`}
				onClick={() => handleClick(b)}>
				<img
					src={b.bannerMobileUrl}
					className="banner"
					alt={b.bannerName ?? ""}
				/>
			</button>
		);
	});
	return <Autoplay Rows={rows} className={className} />;
};

Panel.defaultProps = {
	openInBlank: false,
	className: null
};

Panel.propTypes = {
	ImgFolder: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	).isRequired,
	openInBlank: PropTypes.bool,
	className: PropTypes.string
};

const defSwipperConfig = {
	spaceBetween: 30,
	centeredSlides: true,
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false
	},
	pagination: {
		el: ".swiper-pagination",
		clickable: true
	},
	rebuildOnUpdate: true
};

export const Autoplay = ({ Rows, className }) => {
	const params = defSwipperConfig;
	return Rows && Rows.length > 0 ? (
		<div className={`swiper-container ${className}`}>
			<div className="swiper-wrapper">
				<Swiper {...params}>{Rows}</Swiper>
			</div>
		</div>
	) : null;
};

Autoplay.defaultProps = {
	className: null
};

Autoplay.propTypes = {
	Rows: PropTypes.arrayOf(PropTypes.node).isRequired,
	className: PropTypes.string
};

export const AutoPlayWithBtn = ({ Rows, className }) => {
	const params = {
		...defSwipperConfig,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev"
		},
		getSwiper: obj => {
			ref.current = obj;
		},
		renderPrevButton: () => (
			<div className="swiper-button-prev" onClick={goPrev} />
		),
		renderNextButton: () => (
			<div className="swiper-button-next" onClick={goNext} />
		)
	};
	const ref = useRef(null);

	const goNext = () => {
		if (ref.current !== null) {
			ref.current.slideNext();
		}
	};

	const goPrev = () => {
		if (ref.current !== null) {
			ref.current.slidePrev();
		}
	};

	return Rows && Rows.length > 0 ? (
		<div className={`swiper-container ${className}`}>
			<div className="swiper-wrapper">
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Swiper {...params}>{Rows}</Swiper>
			</div>
		</div>
	) : null;
};

AutoPlayWithBtn.defaultProps = {
	className: null
};

AutoPlayWithBtn.propTypes = {
	Rows: PropTypes.arrayOf(PropTypes.node).isRequired,
	className: PropTypes.string
};

export default Panel;

import React from "react";
import PropTypes from "prop-types";
import { cloudImgUrl } from "../../config";

const LivecasinoNewBanner = ({ t }) => (
	<div className="mainBanner">
		<img
			src={`${cloudImgUrl}/livecasino/mainbanner.png`}
			alt="live casino banner"
		/>
	</div>
);

LivecasinoNewBanner.propTypes = {
	t: PropTypes.func.isRequired
};

export default LivecasinoNewBanner;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Inputbox from "../../common/inputbox";
import { getUrlParameter } from "../../common/common";
import ShortcutAmountList from "./shortcutAmountList";
import config from "../../../config";
import { useSyncRouteQuery } from "../hooks";

const CryptoPayForm = ({
	t,
	depositType,
	formatAmount,
	cryptoPayGroupList,
	formInfo,
	setFormInfo,
	onChangeAmount,
	onValidAmount,
	onInputboxKeyDown,
	isGroupListFetchDone
}) => {
	const defaultGroupId = Number(getUrlParameter("gId"));
	const defaultChannelId = Number(getUrlParameter("channelId"));
	const [groupIdForRecord, setGroupIdForRecord] = useState(defaultGroupId);
	const [infoChannelIdForRecord, setInfoChannelIdForRecord] =
		useState(defaultChannelId);

	const onGetCryptoPayList = async gId => {
		setGroupIdForRecord(gId);
		setInfoChannelIdForRecord(null);

		if (
			cryptoPayGroupList.some(
				item => item.channelId === infoChannelIdForRecord
			)
		) {
			const defaultInfoListIdx = cryptoPayGroupList.findIndex(
				item => item.channelId === infoChannelIdForRecord
			);
			onSetInfoData(cryptoPayGroupList[defaultInfoListIdx]);
		} else if (cryptoPayGroupList.length > 0) {
			onSetInfoData(cryptoPayGroupList[0]);
		}
	};

	const onSetInfoData = item => {
		setInfoChannelIdForRecord(item.channelId);
		setFormInfo(() => ({
			...item,
			amount: null
		}));
	};

	useSyncRouteQuery({
		type: depositType,
		gId: groupIdForRecord,
		channelId: infoChannelIdForRecord
	});

	useEffect(() => {
		const getData = async () => {
			if (
				cryptoPayGroupList.some(item => item.groupId === defaultGroupId)
			) {
				await onGetCryptoPayList(defaultGroupId);
			} else if (cryptoPayGroupList.length > 0) {
				await onGetCryptoPayList(cryptoPayGroupList[0].groupId);
			}
		};
		if (isGroupListFetchDone) {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isGroupListFetchDone]);

	const isSelAmountType = formInfo.amountRule === 3;

	if (cryptoPayGroupList.length <= 0) return null;

	return (
		<>
			<div className="subModule">
				<div className="subModuleTitle">
					<i className="txt-blue">*</i>
					{t("lbl_SelectChannel")}
				</div>
				<div>
					{cryptoPayGroupList.map(item => (
						<button
							key={`deposit-crypto-channel-${item.channelCode}}`}
							onClick={() => onSetInfoData(item)}
							className={
								item.channelId === infoChannelIdForRecord
									? "active"
									: ""
							}>
							<div>
								<img
									src={`${
										config.cloudImgUrl
									}/CryptoPay/${item.channelCode?.toLowerCase()}.png`}
									alt={item.channelCode}
								/>
								<i className="icon icon-icon32px_check" />
							</div>
							<div className="bank">
								<div>{item.channelCode}</div>
								<div>
									<div>
										{`${t("lbl_Min")}: ${formatAmount(
											item.minAmount
										)}`}
									</div>
									<div>
										{`${t("lbl_Max")}: ${formatAmount(
											item.maxAmount
										)}`}
									</div>
								</div>
							</div>
						</button>
					))}
				</div>
			</div>

			<div className="subModule">
				<div className="subModuleTitle">
					<i className="txt-blue">*</i>
					{t("lbl_DepositAmount")}
				</div>

				<Inputbox
					className="inputBox-white"
					value={formInfo.amount}
					onChange={e => onChangeAmount(e.target.value)}
					onBlur={() => onValidAmount(formInfo.amount)}
					checked={formInfo?.checked}
					tip={formInfo?.tip}
					_isConvert={false}
					isAmount={true}
					placeholder={`${t("lbl_MinMaxAmount")} ${formatAmount(
						formInfo.minAmount
					)} ~ ${formatAmount(formInfo.maxAmount)}`}
					onKeyDown={onInputboxKeyDown}
				/>
				{!isSelAmountType && (
					<ShortcutAmountList
						amount={formInfo.amount}
						minAmount={formInfo.minAmount}
						maxAmount={formInfo.maxAmount}
						onClick={amount => onChangeAmount(amount)}
					/>
				)}
			</div>
		</>
	);
};

CryptoPayForm.propTypes = {
	formInfo: PropTypes.objectOf(PropTypes.any).isRequired,
	depositType: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired,
	formatAmount: PropTypes.func.isRequired,
	cryptoPayGroupList: PropTypes.arrayOf(PropTypes.any).isRequired,
	setFormInfo: PropTypes.func.isRequired,
	onChangeAmount: PropTypes.func.isRequired,
	onValidAmount: PropTypes.func.isRequired,
	onInputboxKeyDown: PropTypes.func.isRequired,
	isGroupListFetchDone: PropTypes.bool.isRequired
};
export default CryptoPayForm;

import axios from "axios";

const rootURL = "";

export const defDeviceType = {
	Desktop: 1,
	Mobile: 2,
	NativeAPP: 3
};

export const req = axios.create({
	withCredentials: true,
	baseURL: `${rootURL}/api`
});

export const siteRequest = axios.create({
	withCredentials: true,
	baseURL: `${rootURL}/api/Site`
});

export const accountStrictRequest = axios.create({
	withCredentials: true,
	baseURL: `${rootURL}/api/AccountStrict`
});

export const accountRequest = axios.create({
	withCredentials: true,
	baseURL: `${rootURL}/api/Account`
});

export const paymentRequest = axios.create({
	withCredentials: true,
	baseURL: `${rootURL}/api/Payment`
});

export const tpRequest = axios.create({
	withCredentials: true,
	baseURL: `${rootURL}/api/Tp`
});

export const promotionRequest = axios.create({
	withCredentials: true,
	baseURL: `${rootURL}/api/Promotion`
});

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactMarquee from "react-fast-marquee";
import { Link } from "gatsby";
import path from "../../router";
import usePrmotion from "../promotion/hook/usePromotion";
import usePopBox from "../hook/usePopBox";
import { useHandlePromotionInfo } from "../promotion/promotionInfo.popbox";

const PromoList = ({ t, userInfo }) => {
	const isMountedRef = useRef(null);
	const { errorPopBox } = usePopBox();
	const { promoList } = usePrmotion({
		userInfo,
		isMountedRef,
		errorPopBox,
		filterExpiry: true,
		autoGetData: true
	});

	const { getPromotionDetail } = useHandlePromotionInfo({
		showAllPromosBtn: true
	});

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	const clickPromotion = promotionCode => {
		getPromotionDetail(promotionCode);
	};

	return (
		<div className="projpot">
			<div className="moduleTitle">
				{t("lbl_Promotion")}
				<Link to={`/${userInfo.userLang}/${path.promotion.root}`}>
					{`${t("lbl_More")}...`}
				</Link>
			</div>{" "}
			<div className="promos">
				{promoList && promoList.length > 0 && (
					<div className="swiper-container promosSwiper">
						{/* <Swiper {...defSwipperConfig}>{Rows}</Swiper> */}
						<ReactMarquee
							speed={145}
							pauseOnHover={true}
							gradientColor={false}>
							<div className="swiper-container">
								<div
									className="swiper-wrapper"
									style={{ display: "flex" }}>
									{promoList.map((p, pidx) => (
										<div
											className="swiper-slide"
											role="group"
											aria-label="3 / 6"
											style={{
												width: "268px",
												marginRight: "15px"
											}}
											key={`promotion.list-${p.promotionCode}-${p.tpId}-${pidx}`}>
											<img src={p.banner} alt={p.title} />
											<div>
												<div className="title">
													{p.title}
												</div>
												<div className="txt">
													{p.description}
												</div>
												<button
													onClick={() =>
														clickPromotion(
															p.promotionCode
														)
													}>
													{t(
														"promotion.lbl_promo_more_info"
													)}
												</button>
											</div>
										</div>
									))}
								</div>
							</div>
						</ReactMarquee>
					</div>
				)}
			</div>
		</div>
	);
};

PromoList.propTypes = {
	userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
	t: PropTypes.func.isRequired
};

export default PromoList;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import QRCode from "qrcode.react";
import path from "../../router";
import { cloudImgUrl } from "../../config";

const SubMenuSingle = ({ subClassName, menuKey, userInfo, t, clickItem }) => {
	if (menuKey === "sports") {
		return (
			<div className={subClassName} onClick={clickItem}>
				<button />
			</div>
		);
	}

	if (menuKey === "appDownload") {
		const andriodDownLoadUrl =
			"https://play.google.com/store/apps/details?id=com.google.android.googlequicksearchbox";
		const IOSDownloadUrl =
			"https://apps.apple.com/tw/app/google/id284815942";

		return (
			<a className={subClassName}>
				<div>
					<div className="txt-orange">BEYOND88</div>
					<div>{t("lbl_AppBannerText")}</div>
					<div className="appListQrcode">
						{IOSDownloadUrl && (
							<div>
								<QRCode
									value={IOSDownloadUrl}
									renderAs="svg"
									size={112}
									includeMargin={true}
								/>
								<img
									src={`${cloudImgUrl}/menu/appdownload_ios.jpeg`}
									alt="IOS Download"
								/>
							</div>
						)}
						{andriodDownLoadUrl && (
							<div>
								<QRCode
									value={andriodDownLoadUrl}
									renderAs="svg"
									size={112}
									includeMargin={true}
								/>
								<img
									src={`${cloudImgUrl}/menu/appdownload_and.jpeg`}
									alt="Andriod Download"
								/>
							</div>
						)}
					</div>
				</div>
			</a>
		);
	}

	if (menuKey === "promotion") {
		return (
			<div className={subClassName}>
				<div>
					<div className="txt-orange">
						{t("lbl_Promos_wording_title")}
					</div>
					<div>{t("lbl_Promos_wording_desc")}</div>
					<div className="btnGroup">
						<Link
							to={`/${userInfo.userLang}/${path.promotion.root}`}
							className="lightblue">
							{t("lbl_ClaimNow")}
						</Link>
					</div>
				</div>
			</div>
		);
	}
	return <div />;
};

SubMenuSingle.propTypes = {
	userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
	t: PropTypes.func.isRequired,
	menuKey: PropTypes.string.isRequired,
	subClassName: PropTypes.string.isRequired
};

export default SubMenuSingle;

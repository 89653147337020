import React from "react";

export default ({ EditSearchField, searchInfo, t }) => (
	<div className="inputBox-white">
		<i className="txt-blue12 icon icon-search" />
		<input
			type="text"
			onChange={e => EditSearchField("searchText", e.target.value)}
			placeholder={t("lbl_SearchGames")}
			value={searchInfo.searchText}
		/>
	</div>
);

import { api_entrance_SignIn } from "./api/api.entrance";
import {
	api_account_GetCustProfile,
	api_account_Logout
} from "./api/api.account";

const isBrowser = typeof window !== `undefined`;

export const LOGIN = async ({ userName, password, userLang }) => {
	if (!isBrowser) return false;

	let result = {};
	try {
		const res = await api_entrance_SignIn({
			userName,
			userpwd: password,
			userLang
		});

		let errMsg = res.data.message;
		let isLogin = false;
		let userData = {};
		if (res.data.errorCode === 0) {
			const useruserLang = res.data.message?.replace("/", "");

			const res_custInfo = await api_account_GetCustProfile();
			let _loginUser = {
				userName,
				userLang: useruserLang,
				isLogin: true
			};

			if (res_custInfo.data.errorCode === 0) {
				const { data } = res_custInfo.data;
				_loginUser = {
					..._loginUser,
					...data,
					userLang: data.userLang
				};
			}

			errMsg = "";
			isLogin = true;
			userData = _loginUser;
		}

		result = {
			errorCode: res.data.errorCode,
			message: errMsg,
			isLogin,
			userData
		};
	} catch (error) {
		result = {
			errorCode: 999,
			message: "",
			isLogin: false,
			userData: {}
		};
	}

	return result;
};

export const LOGOUT = callback => {
	if (!isBrowser) return;
	api_account_Logout().then(
		res => {
			if (res.data.errorCode === 0) {
				callback();
			}
		},
		err => {
			if (err.response.status === 401) {
				callback();
			}
		}
	);
};

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "gatsby";
import { userInfoContext, cryptoInfoContext } from "./context";
import { defLeftMenu } from "./leftMenu.def";

export default () => {
	const { t } = useTranslation();
	const { userInfo } = useContext(userInfoContext);
	const { cryptoInfo } = useContext(cryptoInfoContext);

	const currentPage =
		typeof window !== undefined ? `${window.location.pathname}/` : "";

	const arr = Object.keys(defLeftMenu).map(menuKey => {
		const menu = defLeftMenu[menuKey];

		const subMenuArr = Object.keys(menu.subMenu)
			.filter(_key => {
				const s = menu.subMenu[_key];
				return (
					(!s.notAllowCurrencies ||
						!s.notAllowCurrencies.includes(userInfo.currencyId)) &&
					(!s.haveCryptoInfo ||
						(s.haveCryptoInfo &&
							cryptoInfo &&
							cryptoInfo.length > 0))
				);
			})
			.map(_key => {
				const _active = currentPage.includes(
					`/${userInfo.userLang}/${menu.subMenu[_key].path}/`
				)
					? " active"
					: "";

				let _iconClassName = "";
				const s = menu.subMenu[_key];
				if (s.eventIcons) {
					let iconStrings = "";
					for (let i = 0; i < s.eventIcons.length; i++) {
						iconStrings += `${s.eventIcons[i]} `;
					}
					_iconClassName = `icon ${iconStrings}`;
				}

				return (
					<Link
						key={`leftMenu-subMenu-${_key}`}
						to={`/${userInfo.userLang}/${s.path}`}
						className={`menuSelect${_active}`}>
						<span className={_iconClassName}>
							{t(s.displayKey)}
						</span>
					</Link>
				);
			});

		return (
			<div className="menuModule" key={`leftMenu-${menu.displayKey}`}>
				<button className="menuHeader">{t(menu.displayKey)}</button>
				{subMenuArr}
			</div>
		);
	});

	return <div id="leftMenu">{arr}</div>;
};

import { useState, useCallback, useEffect } from "react";
import { api_promotion_GetPromotionList } from "../../../service/api/api.promotion";
import { isExpiry } from "../promotion.common";

export default ({
	userInfo,
	isMountedRef,
	errorPopBox,
	filterExpiry = false,
	autoGetData = false
}) => {
	const [promoDatas, setPromoList] = useState([]);

	const getPromotionList = useCallback(async () => {
		await api_promotion_GetPromotionList({
			userLang: userInfo.userLang
		}).then(
			({ data: { data, message, errorCode } }) => {
				if (!isMountedRef.current) return;
				const success = errorCode === 0;
				if (success) {
					setPromoList(data);
				} else errorPopBox(message || "error", errorCode);
			},
			() => {
				if (!isMountedRef.current) return;
				errorPopBox();
			}
		);
	}, [userInfo.userLang]);

	useEffect(() => {
		if (autoGetData) {
			getPromotionList();
		}
	}, [autoGetData]);

	const promoList = filterExpiry
		? promoDatas.filter(
				p =>
					!isExpiry({
						...p,
						isLogin: userInfo.isLogin
					})
		  )
		: promoDatas;

	return {
		promoList,
		setPromoList,
		getPromotionList
	};
};

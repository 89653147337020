import React, { useState, useRef, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { api_account_GetCustBonusWallet } from "../../service/api/api.account";
import PromotionNavBar, { defFilterType } from "./promotion.nav";
import PromotionList from "./promotion.list";
import { userInfoContext, loadingContext } from "../common/context";
import usePopBox from "../hook/usePopBox";
import usePromotion from "./hook/usePromotion";
import { isExpiry } from "./promotion.common";
import { defCategoryId } from "./promotion.def";

export default () => {
	const { t } = useTranslation();

	const isMountedRef = useRef(null);

	const [filter, setFilter] = useState(defFilterType.all);

	const { userInfo } = useContext(userInfoContext);
	const { loading, setLoading } = useContext(loadingContext);

	const { errorPopBox } = usePopBox();

	const { promoList, setPromoList, getPromotionList } = usePromotion({
		userInfo,
		isMountedRef,
		errorPopBox
	});

	const onSetPromotionType = type => {
		setFilter(type);
	};

	const refreshProcessBar = ({ promotionCode }) => {
		api_account_GetCustBonusWallet().then(
			({ data: { data, message, errorCode } }) => {
				if (!isMountedRef.current) return;

				const success = errorCode === 0;
				if (success) {
					let idx = null;
					let newPromoList = [];
					idx = promoList.findIndex(
						x => x.promotionCode === promotionCode
					);
					if (idx !== -1) {
						newPromoList = [...promoList];
						if (data && newPromoList[idx].custBonus) {
							newPromoList[idx].custBonus.rollover =
								data.rollover;
							newPromoList[idx].custBonus.turnover =
								data.turnover;
						}
					}

					setPromoList(newPromoList);
				} else {
					errorPopBox(message || "error", errorCode);
				}
			},
			() => {
				if (isMountedRef.current) {
					errorPopBox();
				}
			}
		);
	};

	useEffect(() => {
		if (userInfo.isLogin === null) return;

		async function getData() {
			setLoading(true);
			await getPromotionList();
			setLoading(false);
		}
		getData();
	}, [userInfo.isLogin, userInfo.userLang]);

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	const typeFilter = type => {
		if (type === defFilterType.all)
			return p =>
				!isExpiry({
					...p,
					isLogin: userInfo.isLogin
				});

		if (type === defFilterType.firstDeposit)
			return p =>
				p.category?.includes(defCategoryId.NewMember) &&
				!isExpiry({
					...p,
					isLogin: userInfo.isLogin
				});

		if (type === defFilterType.sportsbook)
			return p =>
				p.category?.includes(defCategoryId.Sportsbook) &&
				!isExpiry({
					...p,
					isLogin: userInfo.isLogin
				});

		if (type === defFilterType.liveCasino)
			return p =>
				p.category?.includes(defCategoryId.LiveCasino) &&
				!isExpiry({
					...p,
					isLogin: userInfo.isLogin
				});

		if (type === defFilterType.slots)
			return p =>
				p.category?.includes(defCategoryId.Slots) &&
				!isExpiry({
					...p,
					isLogin: userInfo.isLogin
				});

		return p =>
			p.bonusType === type &&
			!isExpiry({ ...p, isLogin: userInfo.isLogin });
	};

	const afterFilterPromotionList = promoList.filter(typeFilter(filter));

	return (
		!loading && (
			<div className="infoModule">
				<div className="inside-title icon icon-gift">
					<span>{t("lbl_Promotions")}</span>
				</div>

				<PromotionNavBar
					t={t}
					userInfo={userInfo}
					selected={filter}
					onClick={onSetPromotionType}
				/>

				<PromotionList
					t={t}
					filter={filter}
					userInfo={userInfo}
					promoList={afterFilterPromotionList}
					refreshProcessBar={refreshProcessBar}
					afterJoinCallBack={getPromotionList}
				/>
			</div>
		)
	);
};

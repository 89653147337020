import React, { useState } from "react";
import PropTypes from "prop-types";
import qrcode from "../../styles/img/qrcode.png";

const QuickdownloadLink = ({ t }) => {
	const [active, setActive] = useState(false);

	return (
		<>
			<DownloadBar
				t={t}
				className={active ? "active" : ""}
				onMouseOver={() => setActive(true)}
			/>
			<IconBar
				t={t}
				className={active ? "" : "active"}
				onMouseLeave={() => setActive(false)}
			/>
		</>
	);
};

export const DownloadBar = ({ t, className, onMouseOver }) => (
	<div
		className={`quickLinkNew3 ${className}`}
		onMouseOver={onMouseOver}
		onFocus={() => 0}>
		<i className="icon icon-arrow-right2" />
		<div>{t("lbl_Download")}</div>
	</div>
);

export const IconBar = ({ t, className, onMouseLeave }) => (
	<div className={`quickLinkNew4 ${className}`} onMouseLeave={onMouseLeave}>
		<i className="icon icon-arrow-left2" />
		<button className="icon icon-appleinc">
			{t("lbl_iOS")?.toUpperCase()}
			<div>
				<img src={qrcode} alt="ios qrcode" />
			</div>
		</button>
		<button className="icon icon-android">
			{t("lbl_Android")?.toUpperCase()}
			<div>
				<img src={qrcode} alt="android qrcode" />
			</div>
		</button>
	</div>
);

QuickdownloadLink.propTypes = {
	t: PropTypes.func.isRequired
};

DownloadBar.propTypes = {
	t: PropTypes.func.isRequired,
	className: PropTypes.string.isRequired,
	onMouseOver: PropTypes.func.isRequired
};

IconBar.propTypes = {
	t: PropTypes.func.isRequired,
	className: PropTypes.string.isRequired,
	onMouseLeave: PropTypes.func.isRequired
};

export default QuickdownloadLink;

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export default ({
	btnBar,
	message,
	errorCode,
	icon = "icon-library",
	apiUrl = ""
}) => {
	const { t } = useTranslation();
	const [countDownSecond, setCountDownSecond] = useState(300);
	const timerRef = useRef(null);
	const hasTooManyRequestError = errorCode === 1004;
	const errMsg = `${errorCode ? `[${errorCode}] ` : ""}${message}`;

	const startTimer = () => {
		timerRef.current = setInterval(() => {
			setCountDownSecond(prev => prev - 1);
		}, 1000);
	};

	const secondsToTime = secondsInTime => {
		const pad = (num, size) => `000${num}`.slice(size * -1);
		const time = parseFloat(secondsInTime).toFixed(3);
		const minutes = Math.floor(time / 60) % 60;
		const seconds = Math.floor(time - minutes * 60);
		return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
	};

	useEffect(() => {
		if (hasTooManyRequestError) {
			startTimer();
		}

		return () => {
			clearInterval(timerRef.current);
		};
	}, []);

	useEffect(() => {
		const timeIsUp = countDownSecond === 0 && timerRef.current;

		if (timeIsUp) {
			clearInterval(timerRef.current);
			timerRef.current = null;
		}
	}, [countDownSecond]);

	return (
		<div className="Success active">
			<div className={`icon ${icon}`} />
			<div className="title">{t("lbl_Information")}</div>
			<div>
				{hasTooManyRequestError ? (
					<span>
						{t(`msg_API_SendVerifySMS_1004`, {
							time: secondsToTime(countDownSecond)
						})}
					</span>
				) : (
					<>
						{apiUrl && <p>{apiUrl}</p>}
						<span>{errMsg}</span>
					</>
				)}
			</div>
			{btnBar}
		</div>
	);
};

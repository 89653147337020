import React, { useState } from "react";
import PropTypes from "prop-types";
import { useGotoGame } from "../hook/useGotoGame";

import IMG01 from "../../styles/img/fish/01.jpeg";
import IMG02 from "../../styles/img/fish/02.jpeg";
import IMG03 from "../../styles/img/fish/03.jpeg";
import IMG04 from "../../styles/img/fish/04.jpeg";
import IMG05 from "../../styles/img/fish/05.jpeg";
import IMG06 from "../../styles/img/fish/06.jpeg";
import IMG07 from "../../styles/img/fish/07.jpeg";

const FishingList = ({ t, userInfo }) => {
	const [gameList, setGameList] = useState([
		{
			tpId: 9,
			gameId: "byz81hmsq748k",
			tpGameType: 13,
			tpCategory: 2,
			gameName: "Hip Hop Panda",
			gameLogo: IMG01
		},
		{
			tpId: 9,
			gameId: "byz81hmsq748k",
			tpGameType: 13,
			tpCategory: 2,
			gameName: "Legend Of Houyi",
			gameLogo: IMG02
		},
		{
			tpId: 27,
			gameId: "test22",
			tpGameType: 46,
			tpCategory: 2,
			gameName: "Mr Hallow Win",
			gameLogo: IMG03
		},
		{
			tpId: 9,
			gameId: "byz81hmsq748k",
			tpGameType: 13,
			tpCategory: 2,
			gameName: "Prosperity Lion",
			gameLogo: IMG04
		},
		{
			tpId: 27,
			gameId: "test22",
			tpGameType: 46,
			tpCategory: 2,
			gameName: "Santa's Gift Rush",
			gameLogo: IMG05
		},
		{
			tpId: 27,
			gameId: "test22",
			tpGameType: 46,
			tpCategory: 2,
			gameName: "Gem Saviour Sword",
			gameLogo: IMG06
		},
		{
			tpId: 27,
			gameId: "test223",
			tpGameType: 46,
			tpCategory: 2,
			gameName: "Piggy Gold",
			gameLogo: IMG07
		}
	]);

	const { handleGoToGame } = useGotoGame({
		isLogin: userInfo.isLogin,
		userLang: userInfo.userLang,
		autoGetData: false
	});

	const ClickGame = (tpId, gameId, tpGameType) => {
		handleGoToGame({
			isLogin: userInfo.isLogin,
			userLang: userInfo.userLang,
			tpId,
			gameId,
			tpGameType
		});
	};

	const rows = gameList?.map((g, gIdx) => {
		return (
			<div
				key={`home-fishing-${g.tpId}-${g.gameName}-${gIdx}`}
				onClick={() => ClickGame(g.tpId, g.gameId, g.tpGameType)}>
				<img src={g.gameLogo} alt={g.gameName} />
				<div>{g.gameName}</div>
			</div>
		);
	});

	return (
		rows?.length > 0 && (
			<>
				<div className="moduleTitle">{t("lbl_Fishing")}</div>
				<div className="fishModule">{rows}</div>
			</>
		)
	);
};

FishingList.propTypes = {
	t: PropTypes.func.isRequired,
	userInfo: PropTypes.objectOf(PropTypes.any).isRequired
};

export default FishingList;

import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import {
	userInfoContext,
	popBoxContext,
	msgCountContext,
	cryptoInfoContext
} from "../common/context";
import path from "../../router";
import DropdownHeader from "../common/dropdown.headerLink";
import Wallet from "./header.wallet";
import { LOGIN } from "../../service/auth";
import { setCookie, defCurrencyList } from "../common/common";
import { SiteInfo } from "../../config";
import { HeaderLogin } from "./header.login";
import usePopBox from "../hook/usePopBox";

export default () => {
	const { userInfo } = useContext(userInfoContext);
	const { msgCount } = useContext(msgCountContext);
	const { t } = useTranslation();

	return userInfo.isLogin === true ? (
		<AfterLogin t={t} userInfo={userInfo} msgCount={msgCount} />
	) : (
		<BeforeLogin t={t} />
	);
};

const initValue = {
	username: "",
	password: ""
};

const BeforeLogin = ({ t }) => {
	const { userInfo, setUserInfo } = useContext(userInfoContext);
	const [userValue, setUserValue] = useState(initValue);
	const [showPsw, setShowPsw] = useState(false);
	const isMountedRef = useRef(null);
	const { setPopBox } = useContext(popBoxContext);
	const { errorPopBox } = usePopBox();

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	const showPswHandler = () => {
		setShowPsw(!showPsw);
	};

	const EditField = (col, e) => {
		let val = e.target.value;

		if (col === "username") {
			val = val.replace(/[\W]/g, "");
		}

		setUserValue(prev => ({ ...prev, [col]: val }));
	};

	const checkValue = (col, val) => {
		let checked = true;
		let msg = "";

		if (val === "") {
			msg =
				col === "username"
					? t("msg_UsernameReq")
					: t("msg_PasswordReq");
			checked = false;
		}

		if (!checked) {
			errorPopBox(msg);
		}

		return checked;
	};

	const handleLogin = async e => {
		e.preventDefault();

		const checkAll =
			checkValue("username", userValue.username) &&
			checkValue("password", userValue.password);

		if (checkAll) {
			await LOGIN({
				userName: userValue.username,
				password: userValue.password,
				userLang: userInfo.userLang
			}).then(res => {
				if (res.errorCode === 0) {
					setCookie(SiteInfo.langCookie, res.userData.userLang);
					setUserInfo(() => ({
						...res.userData,
						isLogin: res.isLogin
					}));
					setPopBox({ isOpen: false });
					navigate(`/${userInfo.userLang}/${path.deposit.root}`);
				} else if (
					res.errorCode === 1101 ||
					res.errorCode === 1102 ||
					res.errorCode === 9000
				) {
					if (isMountedRef.current) {
						errorPopBox(res.message, res.errorCode);
					}
				} else if (res.errorCode === 1001) {
					errorPopBox(res.message, res.errorCode);
				}
			});
		}
	};

	return (
		<HeaderLogin
			showPswHandler={showPswHandler}
			showPsw={showPsw}
			EditField={EditField}
			handleLogin={handleLogin}
			userValue={userValue}
			userLang={userInfo.userLang}
			path={path}
			t={t}
		/>
	);
};

const defCashierLinks = [
	{
		displayKey: "lbl_Withdrawal",
		href: path.withdrawal.root
	},
	{
		displayKey: "lbl_Deposit",
		href: path.deposit.root
	},
	{
		displayKey: "lbl_History",
		href: path.history.root
	}
];

const AfterLogin = ({ t, userInfo, msgCount }) => {
	const { cryptoInfo } = useContext(cryptoInfoContext);
	const defSettingLinks = [
		{
			displayKey: "lbl_Profile",
			href: path.profile.root
		},
		{
			displayKey: "lbl_BankInfo",
			href: path.bankInfo.root,
			notAllowCurrencies: [defCurrencyList.USDT]
		},
		{
			displayKey: "lbl_CryptoWallet",
			href: path.cryptoWallet.root,
			haveCryptoInfo: true
		},
		{
			displayKey: "lbl_ChangePassword",
			href: path.changePassword.root
		}
	];

	const navMenu = defCashierLinks.map(item => (
		<Link key={item.displayKey} to={`/${userInfo.userLang}/${item.href}`}>
			<span>{t(item.displayKey)}</span>
		</Link>
	));

	return (
		<>
			{navMenu}
			<Wallet i18n={t} />
			<DropdownHeader
				mainKey="header-settingLink"
				userLang={userInfo.userLang}
				userLevel={userInfo.userLevel}
				className={`memberfile${
					userInfo.userLevel ? "" : " icon icon-member"
				}`}
				subClassName="submenu"
				i18n={t}
				options={defSettingLinks.filter(
					item =>
						(!item.notAllowCurrencies ||
							!item.notAllowCurrencies.includes(
								userInfo.currencyId
							)) &&
						(!item.haveCryptoInfo ||
							(item.haveCryptoInfo &&
								cryptoInfo &&
								cryptoInfo.length > 0))
				)}
				placeholder={userInfo.username}
			/>
			<Link
				className={`icon icon-email${msgCount > 0 ? " active" : ""}`}
				to={`/${userInfo.userLang}/${path.message.root}`}
			/>
		</>
	);
};

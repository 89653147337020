import config, { SiteInfo } from "../config";

export default userLang => ({
	terms: `${config.t_and_cURL}/Static/${SiteInfo.siteName}/${userLang}/TermsConditions.html`,
	rule: `${config.t_and_cURL}/Static/${SiteInfo.siteName}/${userLang}/RulesandRegulations.html`,
	OurPolicies: `${config.t_and_cURL}/Static/${SiteInfo.siteName}/${userLang}/ResposibleGaming.html`,
	PrivacyPolicy: `${config.t_and_cURL}/Static/${SiteInfo.siteName}/${userLang}/PrivacyPolicy.html`,
	CookiePolicy: `${config.t_and_cURL}/Static/${SiteInfo.siteName}/${userLang}/CookiePolicy.html`,
	RuleRegulations: `${config.t_and_cURL}/Static/${SiteInfo.siteName}/${userLang}/RulesandRegulations.html`,
	AccountTerms: `${config.t_and_cURL}/Static/${SiteInfo.siteName}/${userLang}/AccountTerms.html`,
	FAQ: `${config.t_and_cURL}/Static/${SiteInfo.siteName}/${userLang}/FAQ.html`,
	AboutUs: `${config.t_and_cURL}/Static/${SiteInfo.siteName}/${userLang}/AboutUs.html`,
	Terms: `${config.t_and_cURL}/Static/${SiteInfo.siteName}/${userLang}/Terms.html`,
	ContactUs: `${config.t_and_cURL}/Static/${SiteInfo.siteName}/${userLang}/ContactUs.html`,
	ResposibleGaming: `${config.t_and_cURL}/Static/${SiteInfo.siteName}/${userLang}/ResposibleGaming.html`
});

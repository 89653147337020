import { useState, useEffect } from "react";
import {
	api_account_GetBonusStatements,
	api_account_GetCustPromotionCode
} from "../../../service/api/api.account";

import usePopBox from "../../hook/usePopBox";

export default function useBonusStatement({
	isMountedRef,
	setHistoryDatas,
	setPagination
}) {
	const [bonusList, setBonusList] = useState([]);
	const { errorPopBox } = usePopBox();

	useEffect(() => {
		const apiCall = async () => {
			try {
				const res = await api_account_GetCustPromotionCode();
				if (!isMountedRef.current) return;
				const { errorCode, data } = res.data;

				if (errorCode === 0) {
					setBonusList(data);
				}
			} catch (error) {}
		};

		apiCall();
	}, []);

	const getBonusStatementHistory = async (transactionData, pageNumber) => {
		await api_account_GetBonusStatements({
			...transactionData,
			pageNumber
		}).then(
			res => {
				if (!isMountedRef.current) return;
				const { errorCode, data, totalPages, message } = res.data;
				if (errorCode === 0) {
					setHistoryDatas(data);
					setPagination(prev => ({
						...prev,
						totalPages
					}));
				} else {
					errorPopBox(message || "error", errorCode);
				}
			},
			() => {
				if (!isMountedRef.current) return;

				errorPopBox();
			}
		);
	};

	return {
		getBonusStatementHistory,
		bonusList
	};
}

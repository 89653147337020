import React from "react";
import Wrapper from "../components/common/wrapper";
import Layout from "../components/common/layout";
import Content from "../components/password/forgetPassword";
import Footer from "../components/footer/footer";

export default () => (
	<Wrapper afterLogin={false}>
		<Layout>
			<main>
				<Content />
			</main>
			<Footer />
		</Layout>
	</Wrapper>
);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useGotoGame } from "../hook/useGotoGame";

import PG_01 from "../../styles/img/casino/01.png";
import PG_02 from "../../styles/img/casino/02.png";
import PG_03 from "../../styles/img/casino/03.png";
import PG_04 from "../../styles/img/casino/04.png";

const CasinoList = ({ t, userInfo }) => {
	const [gameList, setGameList] = useState([
		{
			tpId: 9,
			gameId: "byz81hmsq748k",
			tpGameType: 13,
			tpCategory: 2,
			gameName: "WM Video",
			gameLogo: PG_01
		},
		{
			tpId: 9,
			gameId: "byz81hmsq748k",
			tpGameType: 13,
			tpCategory: 2,
			gameName: "WM Baccarat",
			gameLogo: PG_02
		},
		{
			tpId: 27,
			gameId: "test22",
			tpGameType: 46,
			tpCategory: 2,
			gameName: "WM Roulette",
			gameLogo: PG_03
		},
		{
			tpId: 9,
			gameId: "byz81hmsq748k",
			tpGameType: 13,
			tpCategory: 2,
			gameName: "WM SIC Bo",
			gameLogo: PG_04
		}
	]);

	const { handleGoToGame } = useGotoGame({
		isLogin: userInfo.isLogin,
		userLang: userInfo.userLang,
		autoGetData: false
	});

	const ClickGame = (tpId, gameId, tpGameType) => {
		handleGoToGame({
			isLogin: userInfo.isLogin,
			userLang: userInfo.userLang,
			tpId,
			gameId,
			tpGameType
		});
	};

	const rows = gameList?.map((g, gIdx) => {
		return (
			<div
				key={`home-casinolist-${g.tpId}-${g.gameName}-${gIdx}`}
				onClick={() => ClickGame(g.tpId, g.gameId, g.tpGameType)}>
				<img src={g.gameLogo} alt={g.gameName} />
				<div className="gameMask">
					<div className="gameInfo">
						<div>{g.gameName}</div>
					</div>
					<div className="gameBtnGroup">
						<button>{t("lbl_PlayNow")}</button>
					</div>
				</div>
			</div>
		);
	});

	return (
		rows?.length > 0 && (
			<>
				<div className="moduleTitle">{t("lbl_LiveCasino")}</div>
				<div className="casinoModule">{rows}</div>
			</>
		)
	);
};

CasinoList.propTypes = {
	t: PropTypes.func.isRequired,
	userInfo: PropTypes.objectOf(PropTypes.any).isRequired
};

export default CasinoList;

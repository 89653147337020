import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { navigate } from "gatsby";
import { defMenuLinks } from "../header/header.menu.def";
import { userInfoContext, popBoxContext } from "../common/context";
import defPopBoxType from "../common/defPopBoxType";
import { useGotoGame } from "../hook/useGotoGame";
import LivecasinoBanner from "./livecasino.banner";
import LivecasinoList from "./livecasino.list";

export default () => {
	const { t } = useTranslation();
	const { userInfo } = useContext(userInfoContext);
	const { setPopBox } = useContext(popBoxContext);

	const [subMenu, setSubMenu] = useState([]);

	const { handleGoToGame } = useGotoGame({
		isLogin: userInfo.isLogin,
		userLang: userInfo.userLang,
		autoGetData: false
	});

	const clickItem = ({ tpId, gameId, needLogin, path, tpGameType }) => {
		const showLoginPopBox = () => {
			setPopBox({
				isOpen: true,
				popBoxType: defPopBoxType.Login
			});
		};

		if (!tpId && !gameId && path) {
			return needLogin && !userInfo.isLogin
				? showLoginPopBox()
				: navigate(`/${userInfo.userLang}/${path}`);
		}

		handleGoToGame({
			isLogin: userInfo.isLogin,
			userLang: userInfo.userLang,
			tpId,
			gameId,
			tpGameType
		});
	};

	useEffect(() => {
		setSubMenu(
			defMenuLinks.casino.subMenu.filter(
				subMenuItem => subMenuItem?.lobbyPage
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInfo.currencyId, userInfo.isLogin, userInfo.userLang]);

	return (
		<>
			<LivecasinoBanner t={t} />

			<section className="inside">
				<div className="rightModule">
					<div className="livecasino newDesign">
						<LivecasinoList
							listData={subMenu}
							clickItem={clickItem}
							t={t}
						/>
					</div>
				</div>
			</section>
		</>
	);
};

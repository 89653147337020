import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	loadingContext,
	userInfoContext,
	popBoxContext
} from "../common/context";
import { defDepositType } from "./deposit.def";
import defPopBoxType from "../common/defPopBoxType";
import { checkAmount, isConvert, number_format } from "../common/common";
import usePopBox from "../hook/usePopBox";
import {
	useDeposit,
	useDepositCryptoPay,
	useDepositLocalBank,
	useMobilePay,
	useDepositEffect,
	useAutoDeposit
} from "./hooks";
import DepositInfoModule from "./deposit.infoMoule";
import DepositRecentHistory from "./deposit.recentHistory";
import LocalBankForm from "./components/localBankForm";
import CryptoPayForm from "./components/cryptoPayForm";
import MobilePayForm from "./components/mobilePayForm";
import AutoDepositForm from "./components/autoDepositForm";

import {
	api_payment_DepositReqCrypto,
	api_payment_DepositReqMobile,
	api_payment_DepositReqBank
} from "../../service/api/api.payment";

const Deposit = () => {
	const { t } = useTranslation();
	const isMountedRef = useRef(null);
	const { loading, setLoading } = useContext(loadingContext);
	const { userInfo } = useContext(userInfoContext);
	const { setPopBox } = useContext(popBoxContext);
	const { errorPopBox, closePopBox } = usePopBox();
	const _isConvert = isConvert(userInfo.currencyId);

	const [depositOptions, setDepositOptions] = useState([]);

	const [depositType, setDepositType] = useState(null);

	const [isGroupListFetchDone, setIsGroupListFetchDone] = useState(true);

	const [formInfo, setFormInfo] = useState({
		imgFile: null,
		amount: null,
		fromChannel: null,
		fromAccountNum: null,
		checked: null,
		tip: ""
	});

	const [isInitDataDone, setIsInitDataDone] = useState(false);

	const {
		getDepositInfoRecent,
		depositInfoRecentDatas,
		setDepositInfoRecentDatas
	} = useDeposit({
		isMountedRef
	});

	const {
		getLocalBankGroupList,
		getLocalBankInfo,
		localBankGroupList,
		localBankInfo,
		postLocalBankReqWireTransfer
	} = useDepositLocalBank({
		isMountedRef
	});

	const {
		getCryptoPayGroupList,
		getCryptoPayInfo,
		cryptoPayGroupList,
		cryptoPayInfo
	} = useDepositCryptoPay({ isMountedRef });

	const {
		getMobilePayInfoList,
		getMobilePayGroupList,
		mobilePayGroupList,
		mobilePayInfoList
	} = useMobilePay({
		isMountedRef
	});

	const { autoDepositGroupList, getAutoDepositGroupList } = useAutoDeposit({
		isMountedRef
	});

	const onChangeDepositType = async type => {
		setDepositType(type);

		setIsGroupListFetchDone(false);
		if (type === defDepositType.localBank) {
			await getLocalBankGroupList();
		} else if (type === defDepositType.cryptoPay) {
			await getCryptoPayGroupList();
		} else if (type === defDepositType.mobilePay) {
			await getMobilePayGroupList();
		} else if (type === defDepositType.autoDeposit) {
			await getAutoDepositGroupList();
		}

		setIsGroupListFetchDone(true);
	};

	const onChangeAmount = _amount => {
		const amount = String(_amount).replace(/^(0+)|[^\d]+/g, "");
		setFormInfo(prev => ({ ...prev, amount }));
	};

	const onReset = () => {
		setFormInfo(prev => ({
			...prev,
			imgFile: null,
			amount: null,
			fromChannel: null,
			fromAccountNum: null,
			checked: null,
			tip: ""
		}));
	};

	const onSubmit = async () => {
		const asyncModalPopBox = () =>
			new Promise(resolve =>
				setPopBox({
					isOpen: true,
					closePopBox: () => {
						closePopBox();
						resolve(false);
					},
					content: (
						<div className="Success active">
							<div className="icon icon-bell" />
							<div className="title">{t("lbl_Information")}</div>
							<div>{t("lbl_Deposit_Promotion_Pop_Msg1")}</div>
							<div className="btnGroup">
								<button
									className="btn-red"
									onClick={() => {
										closePopBox();
										resolve(true);
									}}>
									{t("lbl_Deposit_Promotion_Button_Proceed")}
								</button>
								<button
									className="btn-org"
									onClick={() => {
										closePopBox();
										resolve(false);
									}}>
									{t("lbl_Deposit_Promotion_Button_Back")}
								</button>
							</div>
						</div>
					)
				})
			);

		if (formInfo.checked && typeof window !== "undefined") {
			const { channelId, amount } = formInfo;
			const resultAmount = _isConvert ? amount * 1000 : Number(amount);

			if (userInfo.phoneVerified) {
				const success = await asyncModalPopBox();

				if (!success) return;
			}

			if (depositType === defDepositType.localBank) {
				setLoading(true);

				postLocalBankReqWireTransfer({
					amount: resultAmount,
					bankInfoId: formInfo.bankInfoId
				});
			} else if (depositType === defDepositType.cryptoPay) {
				setLoading(true);

				/**
				 * Mode :
				 * ================================
				 * 1.開新視窗，將製作 FormData 並且Post Submit.
				 * 2. 開新視窗，導頁至該Url.
				 * 3. 將 url 以QrCode 呈現.
				 * 4. 顯示Crypto 資訊
				 */
				await api_payment_DepositReqCrypto({
					amount: resultAmount,
					channelId
				}).then(
					res => {
						if (res.data.errorCode === 0) {
							const { mode } = res.data.data;
							if (mode === 2 && res.data.data.url) {
								window.open(
									res.data.data.url,
									"_blank",
									// eslint-disable-next-line no-restricted-globals
									`toolbar=no, menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no, top=0, left=0, width=640,height=${screen?.height}`
								);
							} else if (mode === 4) {
								const promoContent = {
									cashbackAmount: null,
									receivedAmount: null,
									PromotionRate: null,
									Promotion: null
								};
								if (res.data.data.formData?.Promotion === "1") {
									promoContent.cashbackAmount =
										res.data.data.formData?.PromotionAmount;
									promoContent.receivedAmount =
										Number(
											res.data.data.formData
												?.PromotionAmount
										) +
										Number(
											res.data.data.formData
												?.DepositAmount
										);
									promoContent.PromotionRate = `${Number(
										res.data.data.formData?.PromotionRate
									).toFixed(2)}%`;

									promoContent.Promotion =
										res.data.data.formData?.Promotion;
								}

								setPopBox({
									isOpen: true,
									title: t("lbl_DepositType4"),
									popBoxType: defPopBoxType.PaymentQR,
									depositAmount:
										res.data.data.formData?.DepositAmount,
									cashbackAmount: promoContent.cashbackAmount,
									receivedAmount: promoContent.receivedAmount,
									PromotionRate: promoContent.PromotionRate,
									promoContent: promoContent.Promotion,
									rate:
										parseFloat(
											res.data.data.formData?.Rate
										) ?? null,
									requestAmount:
										parseFloat(
											res.data.data.formData
												?.RequestAmount
										) ?? null,
									token:
										res.data.data.formData?.Token ?? null,
									amount: resultAmount,
									currencyId: userInfo.currencyId,
									imgUrl: res.data.data.url,
									thirdId: res.data.data.thirdId ?? null,
									depositType: defDepositType.cryptoPay,
									handlePopBox: () => {
										setPopBox(prevState => ({
											...prevState,
											isOpen: false
										}));
									},
									currencyName: formInfo?.currencyName
								});
							}
						} else {
							errorPopBox(
								res.data.message || "error",
								res.data.errorCode
							);
						}
					},
					() => {
						errorPopBox();
					}
				);
			} else if (depositType === defDepositType.mobilePay) {
				setLoading(true);

				try {
					const res = await api_payment_DepositReqMobile({
						amount: resultAmount,
						channelId
					});

					if (res.data.errorCode === 0) {
						const { mode } = res.data.data;
						if (mode === 2 && res.data.data.url) {
							window.open(
								res.data.data.url,
								"_blank",
								// eslint-disable-next-line no-restricted-globals
								`toolbar=no, menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no, top=0, left=0, width=640,height=${screen?.height}`
							);
						}
					} else {
						errorPopBox(
							res.data.message || "error",
							res.data.errorCode
						);
					}
				} catch (error) {}
			} else if (depositType === defDepositType.autoDeposit) {
				setLoading(true);

				try {
					const res = await api_payment_DepositReqBank({
						amount: resultAmount,
						bankInfoId: formInfo.bankInfoId
					});

					if (res.data.errorCode === 0) {
						const { mode } = res.data.data;
						if (mode === 2 && res.data.data.url) {
							window.open(
								res.data.data.url,
								"_blank",
								// eslint-disable-next-line no-restricted-globals
								`toolbar=no, menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no, top=0, left=0, width=640,height=${screen?.height}`
							);
						}
					} else {
						errorPopBox(
							res.data.message || "error",
							res.data.errorCode
						);
					}
				} catch (error) {}
			}

			await getDepositInfoRecent();

			setLoading(false);
			onReset();
		}
	};

	const onInputboxKeyDown = event => {
		if (event.key === "Enter") {
			onSubmit();
		}
	};

	const onValidAmount = _amount => {
		const result = checkAmount({
			amount: _amount,
			minAmount: formInfo.minAmount === 0 ? 1 : formInfo.minAmount,
			maxAmount: formInfo.maxAmount,
			_isConvert,
			t,
			digits: 0
		});

		if (result.status !== 0) {
			setFormInfo(prev => ({
				...prev,
				checked: false,
				tip: result.msg
			}));
		} else {
			setFormInfo(prev => ({
				...prev,
				checked: true,
				tip: ""
			}));
		}

		return result.status === 0;
	};

	const formatAmount = _val => number_format(_val, _isConvert, 0);

	useDepositEffect({
		t,
		userInfo,
		formInfo,
		isMountedRef,
		depositOptions,
		isInitDataDone,
		localBankGroupList,
		cryptoPayGroupList,
		mobilePayGroupList,
		autoDepositGroupList,
		setLoading,
		onValidAmount,
		setDepositType,
		setIsInitDataDone,
		setDepositOptions,
		onChangeAmount,
		getLocalBankGroupList,
		getCryptoPayGroupList,
		getMobilePayGroupList,
		getAutoDepositGroupList
	});

	if (loading || !isInitDataDone) return null;

	return (
		<>
			<DepositInfoModule
				t={t}
				userLang={userInfo.userLang}
				depositType={depositType}
				depositOptions={depositOptions}
				checked={!!formInfo.checked}
				onReset={onReset}
				onSubmit={onSubmit}
				setDepositType={onChangeDepositType}
				setPopBox={setPopBox}
				userName={userInfo.username}>
				{depositType === defDepositType.localBank && (
					<LocalBankForm
						t={t}
						setPopBox={setPopBox}
						depositType={depositType}
						defPopBoxType={defPopBoxType}
						formatAmount={formatAmount}
						localBankGroupList={localBankGroupList}
						isGroupListFetchDone={isGroupListFetchDone}
						formInfo={formInfo}
						localBankInfo={localBankInfo}
						setFormInfo={setFormInfo}
						onChangeAmount={onChangeAmount}
						getLocalBankInfo={getLocalBankInfo}
						onInputboxKeyDown={onInputboxKeyDown}
					/>
				)}

				{depositType === defDepositType.cryptoPay && (
					<CryptoPayForm
						t={t}
						userInfo={userInfo}
						depositType={depositType}
						defPopBoxType={defPopBoxType}
						_isConvert={_isConvert}
						formatAmount={formatAmount}
						cryptoPayGroupList={cryptoPayGroupList}
						formInfo={formInfo}
						cryptoPayInfo={cryptoPayInfo}
						isGroupListFetchDone={isGroupListFetchDone}
						getCryptoPayInfo={getCryptoPayInfo}
						setFormInfo={setFormInfo}
						onChangeAmount={onChangeAmount}
						onValidAmount={onValidAmount}
						onInputboxKeyDown={onInputboxKeyDown}
					/>
				)}

				{depositType === defDepositType.mobilePay && (
					<MobilePayForm
						t={t}
						_isConvert={_isConvert}
						formatAmount={formatAmount}
						depositType={depositType}
						mobilePayGroupList={mobilePayGroupList}
						getMobilePayInfoList={getMobilePayInfoList}
						mobilePayInfoList={mobilePayInfoList}
						isGroupListFetchDone={isGroupListFetchDone}
						formInfo={formInfo}
						setFormInfo={setFormInfo}
						onChangeAmount={onChangeAmount}
						onInputboxKeyDown={onInputboxKeyDown}
					/>
				)}

				{depositType === defDepositType.autoDeposit && (
					<AutoDepositForm
						t={t}
						userInfo={userInfo}
						_isConvert={_isConvert}
						formatAmount={formatAmount}
						depositType={depositType}
						autoDepositGroupList={autoDepositGroupList}
						formInfo={formInfo}
						setFormInfo={setFormInfo}
						onChangeAmount={onChangeAmount}
						onInputboxKeyDown={onInputboxKeyDown}
					/>
				)}
			</DepositInfoModule>

			<DepositRecentHistory
				getDepositInfoRecent={getDepositInfoRecent}
				depositInfoRecentDatas={depositInfoRecentDatas}
				setDepositInfoRecentDatas={setDepositInfoRecentDatas}
			/>
		</>
	);
};

export default Deposit;

import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
	userInfoContext,
	popBoxContext,
	isRootContext
} from "../common/context";
import { readCookie, deleteCookie, getUrlParameter } from "../common/common";
import defPopBoxType from "../common/defPopBoxType";
import { defBannerType } from "../../service/api/site";
import SwipperBanner from "../common/swipperBanner";
import Marquee from "./marquee";
import SideBarQuickLink from "./sideBarQuickLink";
import PromoList from "./promoList";
import HotGameList from "./hotGameList";
import IndexCenterBanner from "./indexCenterBanner";
import QuickdownloadLink from "./quickdownloadLink";
import CasinoList from "./casinoGameList";
import FishingList from "./fishingList";

const IndexPage = () => {
	const { t } = useTranslation();
	const { userInfo } = useContext(userInfoContext);
	const { setPopBox } = useContext(popBoxContext);
	const { setIsRoot } = useContext(isRootContext);
	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true;
		setIsRoot(true);
		return () => {
			isMountedRef.current = false;
			setIsRoot(false);
		};
	});

	useEffect(() => {
		const popBoxMsg = readCookie("popBoxMsg");
		if (popBoxMsg) {
			const closePopBox = () => {
				deleteCookie("popBoxMsg");
				setPopBox({ isOpen: false });
			};

			setPopBox({
				isOpen: true,
				popBoxType: defPopBoxType.ErrorMsg,
				message: popBoxMsg,
				btnBar: (
					<div className="btnGroup">
						<button className="btn-cancel" onClick={closePopBox}>
							{t("lbl_Close")}
						</button>
					</div>
				)
			});
		}
	}, []);

	return (
		<main>
			<SwipperBanner ImgFolder={[defBannerType.Promotions]} />
			<Marquee />

			<PromoList t={t} userInfo={userInfo} />

			<HotGameList t={t} userInfo={userInfo} />

			<CasinoList t={t} userInfo={userInfo} />

			<FishingList t={t} userInfo={userInfo} />

			<IndexCenterBanner userLang={userInfo.userLang} />

			<SideBarQuickLink />

			<QuickdownloadLink t={t} />
		</main>
	);
};

export default IndexPage;

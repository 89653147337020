import { req } from "./base";

export const api_entrance_GetAnnouncement = userLang =>
	req.post(`/Entrance/GetAnnouncement${userLang ? `/${userLang}` : ""}`);

export const api_entrance_GetCurrencies = userLang =>
	req.get(`/Entrance/GetCurrencies${userLang ? `/${userLang}` : ""}`);

export const api_entrance_CheckSignIn = userLang =>
	req.post(`/Entrance/CheckSignIn${userLang ? `/${userLang}` : ""}`);

export const api_entrance_SignIn = ({ userLang, userName, userpwd }) =>
	req.post(`/Entrance/SignIn${userLang ? `/${userLang}` : ""}`, {
		userName,
		userpwd
	});

export const api_entrance_MerchantInfo = ({ userLang }) =>
	req.post(`/Entrance/MerchantInfo${userLang ? `/${userLang}` : ""}`);

export const api_entrance_Signup = ({
	userLang,
	username,
	userpwd,
	currencyId,
	referralId
}) =>
	req.post(`/Entrance/Signup${userLang ? `/${userLang}` : ""}`, {
		username,
		userpwd,
		currencyId,
		userLang,
		referralId
	});

export const api_entrance_ExistsUserName = ({ userLang, userName }) =>
	req.post(`/Entrance/ExistsUserName${userLang ? `/${userLang}` : ""}`, {
		userName
	});

export const api_entrance_ExistsPhoneNo = ({ areaCode, phone, lang }) =>
	req.post(`/entrance/ExistsPhoneNo${lang ? `/${lang}` : ""}`, {
		areaCode,
		phoneNo: phone
	});

export const api_entrance_ExistsEmail = ({ email }) =>
	req.post(`/entrance/ExistsEmail`, {
		email
	});

/**
 * gameListType
 * Casino = 1,
 * P2P = 2,
 * Fishing = 3,
 */
export const defGameListType = {
	casino: 1,
	p2p: 2,
	fishing: 3
};

export const api_entrance_GetGameList = ({
	userLang,
	gameListType = defGameListType.casino
}) =>
	req.post(`/entrance/GetGameList${userLang ? `/${userLang}` : ""}`, {
		gameListType
	});

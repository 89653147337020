export const defBonusType = {
	freeBet: 1,
	firstDeposit: 2,
	redeposit: 3,
	rebate: 4,
	cashback: 5,
	other: 8
};

export const defRateType = {
	percentage: 1,
	amount: 2
};

export const defCategoryId = {
	NewMember: 1,
	Sportsbook: 2,
	LiveCasino: 3,
	Slots: 4
};

import { createContext } from "react";

// wrapper
export const userInfoContext = createContext({ isLogin: false });
export const popBoxContext = createContext({});
export const walletInfoContext = createContext({});
export const msgCountContext = createContext({});
export const loadingContext = createContext({});
export const isRootContext = createContext({});
export const popUpMsgContext = createContext();
export const merchantInfoContext = createContext();
export const cryptoInfoContext = createContext();

// promotion
export const promoPopBox = createContext({});

import React from "react";
import Wrapper from "../components/common/wrapper";
import Layout from "../components/common/layout";
import Footer from "../components/footer/footer";
import P2P from "../components/p2p";

export default () => (
	<Wrapper>
		<Layout>
			<main>
				<P2P />
			</main>
			<Footer isFullFooter={true} />
		</Layout>
	</Wrapper>
);

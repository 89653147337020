import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { number_format } from "../common/common";

const statusClassName = {
	1: "pending",
	2: "paid",
	3: "approved",
	4: "reject",
	5: "cancel"
};

export const TransactionHistory = props => {
	const { t } = useTranslation();

	let actionContent = null;
	if (
		props.type === 3 &&
		(props.orderStatus === 1 || props.orderStatus === 7)
	) {
		actionContent = (
			<button className="gray" onClick={props.ClickCancel}>
				{t("lbl_Cancel")}
			</button>
		);
	}

	return (
		<div>
			<div>{props.idx}</div>
			<div>
				{moment(props.createdAt).format("MM/DD/YYYY")}
				<span className="txt-blue">
					{moment(props.createdAt).format("hh:mm:ss A")}
				</span>
			</div>
			<div>{props.orderId}</div>
			<div>{t(`lbl_DepositType${props.type}`)}</div>
			<div>{number_format(props.amount, props._isConvert)}</div>
			<div className={statusClassName[props.orderStatus]}>
				{t(`lbl_DepositStatus${props.orderStatus}`)}
			</div>
			<div className="btnGroup">
				{actionContent}
				{props.thirdId}
			</div>
		</div>
	);
};

TransactionHistory.defaultProps = {
	thirdId: null
};

TransactionHistory.propTypes = {
	type: PropTypes.number.isRequired,
	ClickCancel: PropTypes.func.isRequired,
	thirdId: PropTypes.string,
	idx: PropTypes.number.isRequired,
	createdAt: PropTypes.string.isRequired,
	orderId: PropTypes.string.isRequired,
	amount: PropTypes.number.isRequired,
	_isConvert: PropTypes.bool.isRequired,
	orderStatus: PropTypes.number.isRequired
};

import React, { useEffect, useRef, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { api_account_GetCustCryptoWallet } from "../../service/api/api.account";
import { userInfoContext, popBoxContext } from "../common/context";
import defPopBoxType from "../common/defPopBoxType";
import InitPanel from "./cryptoWallet.set.init";
import WalletCard from "./cryptoWallet.set.walletCard";
import usePopBox from "../hook/usePopBox";
import Loading from "../common/loading";

export default () => {
	const { t } = useTranslation();
	const { userInfo } = useContext(userInfoContext);
	const { setPopBox } = useContext(popBoxContext);
	const { errorPopBox } = usePopBox();
	const isMountedRef = useRef(null);
	const [loading, setLoading] = useState(true);
	const [walletInfo, setWalletInfo] = useState([]);

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	useEffect(() => {
		GetCryptoWallet();
	}, []);

	const GetCryptoWallet = () => {
		api_account_GetCustCryptoWallet().then(
			res => {
				if (isMountedRef.current) {
					if (res.data.errorCode === 0) {
						const resData = res.data.data;
						if (resData) {
							setWalletInfo(resData.wallets || []);
						}
					} else {
						errorPopBox(
							res.data.message || "error",
							res.data.errorCode
						);
					}
					setLoading(false);
				}
			},
			() => {
				if (isMountedRef.current) {
					errorPopBox();
					setLoading(false);
				}
			}
		);
	};

	const addCryptoWallet = () => {
		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.AddCryptoWallet,
			GetCryptoWallet
		});
	};

	return loading ? (
		<Loading />
	) : (
		<>
			<div className="infoModule">
				<div className="inside-title icon icon-BTC">
					<span>{t("lbl_CryptoWallet")}</span>
					<div>{t("lbl_Update_Crypto_Wallet")}</div>
				</div>
				<InitPanel
					t={t}
					addCryptoWallet={addCryptoWallet}
					numberOfAccount={walletInfo ? walletInfo?.length : 0}
				/>
			</div>
			<WalletCard
				walletList={walletInfo}
				loading={loading}
				userInfo={userInfo}
				GetCryptoWallet={GetCryptoWallet}
			/>
		</>
	);
};

import React, { useState, useEffect } from "react";
import Inputbox from "../../common/inputbox";
import ShortcutAmountList from "./shortcutAmountList";
import { getUrlParameter } from "../../common/common";
import { cloudImgUrl } from "../../../config";
import { useSyncRouteQuery } from "../hooks";

const AutoDepositForm = ({
	t,
	formatAmount,
	depositType,
	formInfo,
	onChangeAmount,
	setFormInfo,
	onInputboxKeyDown,
	autoDepositGroupList
}) => {
	const parameterBankInfoId = Number(getUrlParameter("bankInfoId"));

	const [bankInfoIdForRecord, setBankInfoIdForRecord] =
		useState(parameterBankInfoId);

	const onSetBankInfoIdToInfoData = async item => {
		setBankInfoIdForRecord(item.bankInfoId);

		await setFormInfo(prev => {
			return {
				...prev,
				minAmount: item.minAmount,
				maxAmount: item.maxAmount,
				bankInfoId: item.bankInfoId
			};
		});
	};

	useSyncRouteQuery({
		type: depositType,
		bankInfoId: bankInfoIdForRecord
	});

	useEffect(() => {
		const setDefaultBankInfo = () => {
			const defaultIdx = autoDepositGroupList.findIndex(
				item => item.bankInfoId === parameterBankInfoId
			);

			onSetBankInfoIdToInfoData(
				autoDepositGroupList[defaultIdx !== -1 ? defaultIdx : 0]
			);
		};

		setDefaultBankInfo();
	}, []);

	return (
		<>
			<div className="subModuleTitle">
				<i className="txt-blue">*</i>
				{t("lbl_SelectBank")}
			</div>

			<div className="subModule selectBank">
				<div>
					{autoDepositGroupList.map(item => {
						const bankLogo = String(item.bankLogo).toLowerCase();

						return (
							<button
								key={`deposit-auto--${item.bankLogo}}`}
								onClick={() => onSetBankInfoIdToInfoData(item)}
								className={
									item.bankInfoId === bankInfoIdForRecord
										? "active"
										: ""
								}>
								<div>
									<img
										src={`${cloudImgUrl}/BankLogo/${bankLogo}`}
										alt={item.bankName}
									/>
									<i className="icon icon-icon32px_check" />
								</div>

								<div className="bank">
									<div>{item.bankName}</div>
									<div>
										<div>
											{`${t("lbl_Min")}: ${formatAmount(
												item.minAmount
											)}`}
										</div>
										<div>
											{`${t("lbl_Max")}: ${formatAmount(
												item.maxAmount
											)}`}
										</div>
									</div>
								</div>
							</button>
						);
					})}
				</div>
			</div>

			{!formInfo.selBank ||
			autoDepositGroupList.some(
				item => item.bankInfoId === bankInfoIdForRecord
			) ? (
				<>
					<div className="subModuleTitle">
						<i className="txt-blue">*</i>
						{t("lbl_DepositAmount")}
					</div>
					<div className="subModule">
						<Inputbox
							className="inputBox-white"
							value={formInfo.amount}
							onChange={e => onChangeAmount(e.target.value)}
							checked={formInfo?.checked}
							tip={formInfo?.tip}
							_isConvert={false}
							isAmount={true}
							placeholder={`${t(
								"lbl_MinMaxAmount"
							)} ${formatAmount(
								formInfo.minAmount
							)} ~ ${formatAmount(formInfo.maxAmount)}`}
							onKeyDown={onInputboxKeyDown}
						/>

						<ShortcutAmountList
							amount={formInfo.amount}
							minAmount={formInfo.minAmount}
							maxAmount={formInfo.maxAmount}
							onClick={amount => onChangeAmount(amount)}
						/>
					</div>
				</>
			) : null}
		</>
	);
};

export default AutoDepositForm;

import { req } from "./base";

export const api_account_GetCustProfile = () =>
	req.post(`/Account/GetCustProfile`);

export const api_account_Logout = () => req.post(`/Account/Logout`);

export const api_account_OnlineCheck = () => req.post(`/Account/OnlineCheck`);

export const api_account_GetMsgCount = () => req.post("/Account/GetMsgCount");

export const api_account_GetTpWalletList = listType =>
	req.post(`/Account/GetTpWalletList`, { listType });

export const api_account_GetCustBonusWallet = () =>
	req.post("/Account/GetCustBonusWallet");

export const api_account_GetTpWalletBalance = tpId =>
	req.post("/Account/GetTpWalletBalance", { tpId });

export const api_account_GetWalletBalance = () =>
	req.post("/Account/GetWalletBalance");

export const api_account_SetHideBalance = ({ value }) =>
	req.post(`/Account/SetHideBalance`, { isHideBalance: value });

export const api_account_GetMsgList = ({ pageNumber }) =>
	req.post("/Account/GetMsgList", { pageNumber });

export const api_account_GetMsgContent = msgId =>
	req.post("/Account/GetMsgContent", {
		msgId
	});

export const api_account_DelMsg = msgIds =>
	req.post("/Account/DelMsg", { msgIds });

export const api_account_SetLanguage = lang =>
	req.post("/Account/SetLanguage", { langId: lang });

export const api_account_SetFavoriteGame = (tpId, gameCode, isRemove) =>
	req.post(`/Account/SetFavoriteGame`, { tpId, gameCode, isRemove });

export const api_account_GetFavoriteGame = () =>
	req.post("/Account/GetFavoriteGame");

export const api_account_GetCustBankInfo = () =>
	req.post("/Account/GetCustBankInfo");

export const api_account_GetCustCryptoWallet = () =>
	req.post("/Account/GetCustCryptoWallet");

export const api_account_GetWithdrawalAvailableBalance = () =>
	req.post("/Account/GetWithdrawalAvailableBalance");

export const api_account_GetStatement = ({
	startDate,
	endDate,
	refId,
	pageNumber
}) =>
	req.post("/Account/GetStatement", {
		startDate,
		endDate,
		refId,
		pageNumber
	});

export const api_account_GetBonusStatements = ({
	startDate,
	endDate,
	refId,
	pageNumber,
	bonusId
}) =>
	req.post("/Account/GetBonusStatements", {
		startDate,
		endDate,
		refId,
		pageNumber,
		bonusId
	});

export const api_account_GetCustPromotionCode = () =>
	req.post("/Account/GetCustPromotionCode");

export const api_account_ChangePassword = ({ oldPassword, newPassword }) =>
	req.post("/Account/ChangePassword", { oldPassword, newPassword });

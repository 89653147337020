import React from "react";
import PropTypes from "prop-types";
import Inputbox from "../common/inputbox";
import InputboxPSW from "../common/inputbox.password";
import Dropdown from "../common/dropdown";

const JoinForm = ({
	t,
	openLogin,
	userValue,
	tip,
	clickInfo,
	EditField,
	checkValue,
	showPsw,
	showPswHandler,
	siteCurrency,
	onSelectItem,
	tandCTerms,
	clickCancel,
	clickSubmit,
	defaultOption,
	refId
}) => (
	<form className="joinloginFunction" onSubmit={clickSubmit}>
		<span className="title txt-blue">{t("lbl_JoinNow")}</span>
		<div>
			{t("lbl_LoginMsg")}
			<button className="txt-blue" type="button" onClick={openLogin}>
				{t("lbl_LoginHere")}
			</button>
		</div>
		<div className="inputModule">
			<Inputbox
				className="inputBox-blue"
				icon="icon-member txt-blue"
				placeholder={t("lbl_Username")}
				value={userValue.username}
				tip={tip.username.msg}
				checked={tip.username.checked}
				clickInfo={clickInfo.bind(this, "username")}
				onChange={EditField.bind(null, "username")}
				onBlur={checkValue.bind(null, "username")}
			/>
			<InputboxPSW
				className={`inputBox-blue${
					!tip.password.checked && tip.password.msg !== ""
						? " error"
						: ""
				}`}
				icon="icon-key txt-blue"
				inputType={`${!showPsw ? "password" : "text"}`}
				placeholder={t("lbl_Password")}
				value={userValue.password}
				tip={tip.password.msg}
				onChange={EditField.bind(null, "password")}
				onBlur={checkValue.bind(null, "password")}
				showPsw={showPsw}
				showPswHandler={showPswHandler}
				checked={tip.password.checked}
				clickInfo={clickInfo.bind(this, "password")}
			/>
			{!refId && (
				<>
					<div
						className={`inputBox-blue${
							tip.currency.msg !== "" ? " error" : ""
						}`}>
						<Dropdown
							defaultOption={defaultOption}
							mainKey="join_currency"
							icon="icon-coin-dollar txt-blue"
							options={siteCurrency}
							selectedIdx={userValue.currencyIdx}
							i18n={t}
							onSelectItem={onSelectItem}
							onBlur={checkValue.bind(null, "currency")}
						/>
					</div>
					{tip.currency.msg ? (
						<div className="tip active">{tip.currency.msg}</div>
					) : (
						<div className="tip active">
							{t("lbl_CantChangePreferredCurrency")}
						</div>
					)}
				</>
			)}

			{refId && (
				<Inputbox
					className="inputBox-blue"
					icon="icon-affiliate txt-blue"
					placeholder={t("lbl_Referral")}
					value={refId}
					readOnly={true}
				/>
			)}
		</div>

		<div className="Terms">
			{t("lbl_sign_up_terms_start")}
			<a
				className="txt-blue"
				target="_blank"
				rel="noreferrer"
				href={tandCTerms}>
				{t("lbl_sign_up_terms_center")}
			</a>
			{t("lbl_sign_up_terms_end")}
		</div>

		<div className="btnGroup">
			<button type="button" className="btn-cancel" onClick={clickCancel}>
				{t("lbl_Cancel")}
			</button>
			<button className="btn-check" type="submit">
				{t("lbl_Submit")}
			</button>
		</div>
	</form>
);

JoinForm.defaultProps = {
	defaultOption: null
};

JoinForm.propTypes = {
	t: PropTypes.func.isRequired,
	openLogin: PropTypes.func.isRequired,
	userValue: PropTypes.objectOf(PropTypes.any).isRequired,
	tip: PropTypes.objectOf(PropTypes.any).isRequired,
	clickInfo: PropTypes.func.isRequired,
	EditField: PropTypes.func.isRequired,
	checkValue: PropTypes.func.isRequired,
	showPsw: PropTypes.bool.isRequired,
	showPswHandler: PropTypes.func.isRequired,
	siteCurrency: PropTypes.arrayOf(PropTypes.any).isRequired,
	onSelectItem: PropTypes.func.isRequired,
	tandCTerms: PropTypes.string.isRequired,
	clickCancel: PropTypes.func.isRequired,
	clickSubmit: PropTypes.func.isRequired,
	defaultOption: PropTypes.string
};

export default JoinForm;

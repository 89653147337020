import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { api_account_SetLanguage } from "../../service/api/api.account";
import { setCookie } from "./common";
import { SiteInfo } from "../../config";
import { getNewLanguagePath } from "../../service/language";
import defPopBoxType from "./defPopBoxType";

export class Dropdown extends Component {
	constructor(props) {
		super(props);

		this.state = { isOpen: false };
		this.wrapper = createRef(null);
	}

	UNSAFE_componentWillMount() {
		if (typeof document !== `undefined`) {
			document.removeEventListener("mousedown", this.handleClickOutside);
		}
	}

	componentDidMount() {
		if (typeof document !== `undefined`) {
			document.addEventListener("mousedown", this.handleClickOutside);
		}
	}

	handleClickOutside = e => {
		if (this.wrapper.current && !this.wrapper.current.contains(e.target)) {
			this.setState({ isOpen: false });
		}
	};

	clickDropdown = e => {
		e.preventDefault();
		this.setState(prev => ({ isOpen: !prev.isOpen }));
	};

	selectItem = (value, newPath, e) => {
		e.stopPropagation();
		this.setState({ isOpen: false });
		if (value !== this.props.selectedValue) {
			if (this.props.isLogin) {
				try {
					api_account_SetLanguage(value).then(res => {
						if (
							this.props.isMountedRef.current &&
							res.data.errorCode !== 0
						) {
							this.props.setPopBox({
								isOpen: true,
								popBoxType: defPopBoxType.ErrorMsg,
								message: `[${res.data.errorCode}] ${res.data.message}`,
								btnBar: (
									<div className="btnGroup">
										<button
											className="btn-check"
											onClick={this.props.clickClose}>
											{this.props.t("lbl_Close")}
										</button>
									</div>
								)
							});
						}
					});
				} catch (error) {
					if (this.props.isMountedRef.current) {
						this.props.setPopBox({
							isOpen: true,
							popBoxType: defPopBoxType.ErrorMsg,
							message: this.props.t("msg_ApiServerError"),
							btnBar: (
								<div className="btnGroup">
									<button
										className="btn-check"
										onClick={this.props.clickClose}>
										{this.props.t("lbl_Close")}
									</button>
								</div>
							)
						});
					}
				}
			}

			navigate(newPath);
			setCookie(SiteInfo.langCookie, value);
		}
	};

	render() {
		let icon =
			Object.keys(this.props.defLangGroup).length === 1
				? Object.keys(this.props.defLangGroup)[0]
				: null;

		const rows = Object.keys(this.props.defLangGroup).map(_group => (
			<span key={`${this.props.mainKey}_${_group}`}>
				<div className="countryName">
					{this.props.defLangGroupCountry[_group]}
				</div>
				<div className={`flag ${_group}`}>
					{this.props.defLangGroup[_group].map(item => {
						if (item.value === this.props.selectedValue) {
							icon = _group;
						}
						return (
							<button
								key={`${this.props.mainKey}_${item.value}`}
								onClick={this.selectItem.bind(
									this,
									item.value,
									getNewLanguagePath(item.value)
								)}>
								{item.displayText}
							</button>
						);
					})}
				</div>
			</span>
		));

		const selected = (
			<button className={`flag ${icon}`}>
				{/* {defLangDisplayText[this.props.selectedValue]} */}
			</button>
		);

		return (
			<div
				className={`${this.props.className}${
					this.state.isOpen ? " active" : ""
				}`}
				ref={this.wrapper}
				onClick={this.clickDropdown}>
				{selected}
				<div className={this.props.subClassName}>{rows}</div>
			</div>
		);
	}
}

Dropdown.propTypes = {
	className: PropTypes.string.isRequired,
	subClassName: PropTypes.string.isRequired,
	mainKey: PropTypes.string.isRequired,
	selectedValue: PropTypes.string.isRequired,
	defLangGroupCountry: PropTypes.objectOf(PropTypes.any).isRequired,
	defLangGroup: PropTypes.objectOf(PropTypes.array).isRequired
};

export default Dropdown;

import React from "react";
import Wrapper from "../components/common/wrapper";
import Layout from "../components/common/layout";
import LiveCasino from "../components/livecasino/livecasino";

export default () => (
	<Wrapper>
		<Layout>
			<main className="livecasinoinside newDesign">
				<LiveCasino />
			</main>
		</Layout>
	</Wrapper>
);

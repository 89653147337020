import React from "react";
import { useTranslation } from "react-i18next";

export default ({ btnBar, realName }) => {
	const { t } = useTranslation();

	return (
		<div className="Cancel active">
			<div className="icon icon-icon32px_notification" />
			<div className="title">
				{t("lbl_Hi")} {realName}
			</div>
			<div>{t("lbl_ProfileModifyWarning")}</div>
			{btnBar}
		</div>
	);
};

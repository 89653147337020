import { req } from "./base";

export const api_payment_GetBankInfoListStatus = () =>
	req.post("/Payment/GetBankInfoListStatus");

export const api_payment_DepositBankTransfer = ({ bankInfoId, amount }) =>
	req.post("/Payment/DepositBankTransfer", {
		bankInfoId,
		amount
	});

export const api_payment_GetMerchantBankInfo = ({ bankInfoId }) =>
	req.post("/Payment/GetMerchantBankInfo", {
		bankInfoId
	});

export const api_payment_GetDepositInfoRecent = () =>
	req.post("/Payment/GetDepositInfoRecent");

export const api_payment_GetDepositInfoHistory = ({
	startDate,
	endDate,
	pageNumber
}) =>
	req.post("/Payment/GetDepositInfoHistory", {
		startDate,
		endDate,
		pageNumber
	});

export const api_payment_SetDepositCancel = orderId =>
	req.post("/Payment/SetDepositCancel", { orderId });

export const api_payment_GetCryptoPayInfoList = () =>
	req.post("/Payment/GetCryptoPayInfoList");

export const api_payment_DepositReqCrypto = ({ amount, channelId }) =>
	req.post(`/Payment/DepositReqCrypto`, { amount, channelId });

export const api_payment_GetBankInfoList = () =>
	req.post(`/Payment/GetBankInfoList`);

export const api_payment_GetCryptoGroup = () =>
	req.post(`/Payment/GetCryptoGroup`);

export const api_payment_GetMobilePayGroupList = () =>
	req.post("/Payment/GetMobilePayGroupList");

export const api_payment_GetMobilePayInfoList = groupId =>
	req.post("/Payment/GetMobilePayInfoList", { groupId });

export const api_payment_DepositReqMobile = ({
	channelId,
	amount,
	returnUrl
}) => req.post("/Payment/DepositReqMobile", { channelId, amount, returnUrl });

export const api_payment_DepositReqBank = ({ bankInfoId, amount, returnUrl }) =>
	req.post("/Payment/DepositReqBank", { bankInfoId, amount, returnUrl });

export const api_payment_GetAutoChannelBankGroupList = () =>
	req.post("/Payment/GetAutoChannelBankGroupList");

export const api_payment_GetWithdrawalInfoRecent = () =>
	req.post("/Payment/GetWithdrawalInfoRecent");

export const api_payment_ReqExchangeRateByCryptoId = ({ cryptoId }) =>
	req.post("/Payment/ReqExchangeRateByCryptoId", { cryptoId });

export const api_payment_WithdrawalReq = ({
	bankInfoId,
	accountNo,
	amount,
	note
}) =>
	req.post("/Payment/WithdrawalReq", {
		bankInfoId,
		accountNo,
		amount,
		note
	});

export const api_payment_WithdrawalCryptoReq = ({
	groupId,
	walletAddress,
	amount,
	note
}) =>
	req.post("/Payment/WithdrawalCryptoReq", {
		groupId,
		walletAddress,
		amount,
		note
	});

export const api_payment_SetWithdrawalCancel = orderId =>
	req.post("/Payment/SetWithdrawalCancel", { orderId });

export const api_payment_GetWithdrawalInfoHistory = ({
	startDate,
	endDate,
	pageNumber
}) =>
	req.post("/Payment/GetWithdrawalInfoHistory", {
		startDate,
		endDate,
		pageNumber
	});

import React, { useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import path from "../../router";
import { defNav } from "../casino/casino.def";
import useInterval from "../hook/useInterval";
import { getCurrencyCode, number_format } from "../common/common";
import Jackpot from "../Jackpot/Jackpot";

const ANIMATION_LOOP_MILLISECOND = 2000;
const DELAY_MILLISECOND = 800;

const fakeJackpot = 12345678;
export default function HomeJackpot({ t, userInfo }) {
	const [number, setNumber] = useState(fakeJackpot);
	const [currentText, setCurrentText] = useState(
		number_format(fakeJackpot, false, 0)
	);

	let currencyId = null;

	if (userInfo.isLogin) {
		currencyId = userInfo.currencyId;
	} else if (userInfo.isLogin === false) {
		currencyId = getCurrencyCode(userInfo.userLang);
	}

	useInterval(() => {
		const newNumber = number + 123456;
		const [_currentText] = String(newNumber).split(" ");

		setCurrentText(number_format(_currentText, false, 0));
		setNumber(newNumber);
	}, ANIMATION_LOOP_MILLISECOND + DELAY_MILLISECOND);

	return (
		<Jackpot
			value={currentText}
			label={t(`Currency_${currencyId}`)}
			userLang={userInfo.userLang}
			animationLoopMillisecond={ANIMATION_LOOP_MILLISECOND}
			onClick={() =>
				navigate(
					`/${userInfo.userLang}/${path.casino.root}?tab=${defNav.ALLGAMES.key}`
				)
			}
		/>
	);
}

HomeJackpot.propTypes = {
	t: PropTypes.func.isRequired,
	userInfo: PropTypes.objectOf(PropTypes.any).isRequired
};

import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import WAValidator from "multicoin-address-validator";
import moment from "moment";
import { api_accountStrict_SetCustCryptoWallet } from "../../service/api/api.accountStrict";
import { popBoxContext, cryptoInfoContext } from "./context";
import usePopBox from "../hook/usePopBox";
import defPopBoxType from "./defPopBoxType";
import Inputbox from "./inputbox";
import Dropdown from "./dropdown";

const defCryptoValidation = {
	TRC20: "Tron",
	ERC20: "usdt",
	ETH: "eth"
};

export const Counter = ({ startUnixTime, timeStop }) => {
	const now = moment().unix();
	const [count, setCount] = useState(startUnixTime + 300 - now);
	const timerRef = useRef(null);

	const secondsToTime = secondsInTime => {
		const pad = (num, size) => `000${num}`.slice(size * -1);
		const time = parseFloat(secondsInTime).toFixed(3);
		const minutes = Math.floor(time / 60) % 60;
		const seconds = Math.floor(time - minutes * 60);
		return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setCount(prev => prev - 1);
		}, 1000);
		timerRef.current = interval;

		return () => {
			clearInterval(timerRef.current);
		};
	}, []);

	useEffect(() => {
		if (count === 0) {
			clearInterval(timerRef.current);
			timeStop();
		}
	}, [count, timeStop]);

	return ` ( ${secondsToTime(count)} ) `;
};

export const defColType = {
	nickName: "nickName",
	address: "address"
};

export default ({ GetCryptoWallet }) => {
	const { t } = useTranslation();
	const { setPopBox } = useContext(popBoxContext);
	const { errorPopBox } = usePopBox();
	const isMountedRef = useRef(null);
	const [selectedCryptoIdx, setSelectedCryptoIdx] = useState(0);
	const [selectedNetWorkIdx, setSelNetWorkIdx] = useState(0);
	const { cryptoInfo } = useContext(cryptoInfoContext);

	const [walletDatas, setWalletDatas] = useState({
		nickName: {
			value: ""
		},
		address: { value: "", tip: "", checked: null }
	});

	const onSelNetWork = i => {
		setSelNetWorkIdx(i);
	};

	const onSelCurrency = i => {
		setSelectedCryptoIdx(i);
	};

	useEffect(() => {
		setSelNetWorkIdx(0);
	}, [selectedCryptoIdx]);

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	const clickCancel = () => {
		setPopBox({ isOpen: false, refresh: true });
	};

	const editField = (_colType, e) => {
		let { value } = e.target;
		if (_colType === defColType.address) {
			value = value.replaceAll(" ", "");
		}
		setWalletDatas(prev => ({
			...prev,
			[_colType]: { ...prev[_colType], value, checked: null, tip: "" }
		}));
	};

	useEffect(() => {
		if (walletDatas.address.value) {
			checkAddress(walletDatas.address.value);
		}
	}, [selectedCryptoIdx, selectedNetWorkIdx, walletDatas.address.value]);

	const checkAddress = _val => {
		let checked = true;
		let tip = "";

		const value = _val === undefined ? walletDatas.address.value : _val;

		if (value === "") {
			checked = false;
			tip = checked ? "" : t("lbl_Please_Enter_Wallet_Address");
		} else {
			const validType =
				defCryptoValidation?.[
					networkOptions?.[selectedNetWorkIdx]?.chain
				] ?? "";

			if (validType) {
				const valid = WAValidator.validate(value, validType);

				if (!valid) {
					checked = false;
					tip = t("lbl_wrongWalletAddrFormat", {
						network: networkOptions?.[selectedNetWorkIdx].chain
					});
				}
			}
		}

		if (!checked) {
			setWalletDatas(prev => ({
				...prev,
				address: {
					...prev.address,
					checked,
					tip
				}
			}));
		}

		return checked;
	};

	const currencyOptions = [];
	let networkOptions = [];

	cryptoInfo?.forEach((c, cIdx) => {
		currencyOptions.push({ ...c, displayText: c.crypto });

		if (cIdx === selectedCryptoIdx) {
			networkOptions = c.chains.map(chain => ({
				...chain,
				displayText: chain.chain
			}));
		}
	});

	const clickSubmit = e => {
		e.preventDefault();

		if (checkAddress()) {
			api_accountStrict_SetCustCryptoWallet({
				cryptoId: currencyOptions[selectedCryptoIdx].cryptoId,
				groupId: networkOptions[selectedNetWorkIdx].groupId,
				nickName: walletDatas.nickName.value,
				walletAddress: walletDatas.address.value
			}).then(
				res => {
					if (isMountedRef.current) {
						if (res.data.errorCode === 0) {
							setPopBox({
								isOpen: true,
								popBoxType: defPopBoxType.AddBankSuccess,
								refresh: true,
								btnBar: (
									<div className="btnGroup">
										<button
											className="btn-check"
											onClick={clickCancel}>
											OK
										</button>
									</div>
								)
							});
							GetCryptoWallet && GetCryptoWallet();
						} else {
							errorPopBox(
								res.data.message || "error",
								res.data.errorCode
							);
						}
					}
				},
				() => {
					if (!isMountedRef.current) return;
					errorPopBox();
				}
			);
		}
	};

	return (
		<div className="addAddresspop active">
			<button className="icon icon-close" onClick={clickCancel} />
			<span className="title">{t("lbl_Add_Crypto_Wallet")}</span>
			<div>
				<div className="inputBox-blue">
					<span className="txt-red">*</span>
					<Dropdown
						i18n={t}
						mainKey="crypto-crypto"
						options={currencyOptions}
						selectedIdx={selectedCryptoIdx}
						onSelectItem={onSelCurrency}
					/>
				</div>
				<div className="inputBox-blue">
					<span className="txt-red">*</span>
					<Dropdown
						i18n={t}
						mainKey="crypto-network"
						options={networkOptions}
						selectedIdx={selectedNetWorkIdx}
						onSelectItem={onSelNetWork}
					/>
				</div>
				<Inputbox
					title={t("lbl_CryptoWalletNickName")}
					className={`inputBox-blue${
						walletDatas.nickName.checked === false ? " error" : ""
					}`}
					value={walletDatas.nickName.value}
					onChange={editField.bind(this, defColType.nickName)}
					checked={walletDatas.nickName.checked}
					tip={walletDatas.nickName.tip}
					placeholder={t("lbl_Optional")}
				/>
				<Inputbox
					title={t("lbl_CryptoWalletAddress")}
					className={`inputBox-blue${
						walletDatas.address.checked === false ? " error" : ""
					}`}
					value={walletDatas.address.value}
					onChange={editField.bind(this, defColType.address)}
					checked={walletDatas.address.checked}
					tip={walletDatas.address.tip}
					isRequired={true}
				/>
			</div>
			<div className="btnGroup">
				<button className="btn-cancel" onClick={clickCancel}>
					{t("lbl_Cancel")}
				</button>
				<button
					onClick={clickSubmit}
					className={"btn-check"}
					type="submit">
					{t("lbl_Submit")}
				</button>
			</div>
		</div>
	);
};

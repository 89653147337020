import { useEffect, useState, useCallback, useRef, useContext } from "react";
import { api_thirdparty_GotoGame } from "../../service/api/api.thirdParty";
import usePopBox from "./usePopBox";
import { popBoxContext } from "../common/context";
import defPopBoxType from "../common/defPopBoxType";

export const useGotoGame = ({
	isLogin,
	userLang,
	tpId,
	gameId,
	isFun = false,
	autoGetData = true,
	allowBeforeLogin = false
}) => {
	const { errorPopBox } = usePopBox();
	const [data, setData] = useState({ account: "", password: "" });
	const { setPopBox } = useContext(popBoxContext);
	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true;

		if (
			!(isLogin === false && allowBeforeLogin) &&
			autoGetData &&
			isLogin
		) {
			handleGoToGame({
				isLogin,
				userLang,
				tpId,
				gameId,
				isFun
			});
		}

		return () => {
			isMountedRef.current = false;
		};
	}, [autoGetData, isLogin, userLang]);

	const handleGoToGame = useCallback(
		async ({
			isLogin: _isLogin = true,
			userLang: _userLang,
			tpId: _tpId,
			gameId: _gameId,
			isFun: _isFun
		}) => {
			if (_isLogin) {
				try {
					const res = await api_thirdparty_GotoGame({
						TpId: _tpId,
						LangId: _userLang,
						gameId: _gameId,
						fun: _isFun ? "1" : "0",
						gameType: ""
					});

					if (res.data.errorCode === 0 && res.data.message) {
						window.open(res.data.message, `game-${_tpId}`).focus();
					} else if (
						res.data.errorCode === 3000 &&
						res.data.message
					) {
						const result = JSON.parse(res.data.message);
						setData(() => result);
					} else {
						errorPopBox(
							res.data.message || "error",
							res.data.errorCode
						);
					}
				} catch (error) {
					console.error(error);
				}
			} else {
				setPopBox({
					isOpen: true,
					popBoxType: defPopBoxType.Login
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[gameId, isFun, isLogin, userLang, tpId]
	);

	return { data, handleGoToGame };
};

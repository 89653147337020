export const defLangList = ["en", "zh-CN"];

export const defTmpSupportLangList = ["vi", "th"];

export const defCountryLangList = ["en", "zh-CN"];

export const isCountryLang = _lang => {
	return defCountryLangList.includes(_lang);
};

export const defLang = {
	en: "en",
	"zh-CN": "zh-CN"
};

export const defLangToCurrency = {
	en: "MYR",
	"zh-CN": "MYR"
};

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { cloudImgUrl } from "../../config";

const BankCard = ({
	t,
	state,
	defPanel,
	addBankAccount,
	selectAccount,
	delAccount
}) => {
	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	const _custBankInfo = state.custBankInfo?.bankInfo;

	const bankArr = _custBankInfo?.map(item => {
		const _key = `withdrawal-bankCard-${item.accountNo}`;
		const _checked = item.accountNo === state.selectedAccount?.accountNo;

		return (
			<BankCardLayout
				key={_key}
				t={t}
				checkKey={item.accountNo}
				bankInfo={item}
				selectAccount={selectAccount.bind(this, item)}
				checked={_checked}
				delAccount={delAccount.bind(
					this,
					item.accountNo,
					item.bankInfoId
				)}
				hideDelBtn={state.panel === defPanel.confirm}
			/>
		);
	});

	return (
		<div className="walletModule">
			<div className="addBank">
				<div className="title">{t("lbl_BankAccount")}</div>
				{bankArr}
				{(!bankArr || bankArr.length < 3) && (
					<div
						className="addBankName noBank"
						onClick={addBankAccount}>
						<div className="icon icon-plus" />
						<div>{t("lbl_AddBankAccount")}</div>
					</div>
				)}
			</div>
		</div>
	);
};

const BankCardLayout = ({
	checkKey,
	bankInfo,
	selectAccount,
	checked,
	delAccount,
	hideDelBtn
}) => {
	const bankLogo = String(bankInfo.bankLogo).toLowerCase();
	return (
		<div className="addBankName">
			<div className="addBankInfo">
				<div className="bankName">
					<img
						src={`${cloudImgUrl}/BankLogo/${bankLogo}`}
						alt={`logo ${bankInfo.bankName}`}
					/>
					<div>
						{bankInfo.bankName}
						<div className="txt-blue">
							<span>{bankInfo.accountNo?.slice(0, 4)}</span>
							<span>{bankInfo.accountNo?.slice(4, 8)}</span>
							<span>{bankInfo.accountNo?.slice(8, 12)}</span>
							<span>{bankInfo.accountNo?.slice(12, 16)}</span>
						</div>
					</div>
				</div>
				<div className="checkbox">
					<input
						type="checkbox"
						id={checkKey}
						onChange={selectAccount}
						checked={checked}
					/>
					<label
						htmlFor={checkKey}
						className="icon icon-checkbox-checked"
					/>
					{hideDelBtn ? null : (
						<button
							className="icon icon-bin"
							onClick={delAccount}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

BankCard.propTypes = {
	t: PropTypes.func.isRequired,
	state: PropTypes.objectOf(PropTypes.any).isRequired,
	defPanel: PropTypes.objectOf(PropTypes.any).isRequired,
	addBankAccount: PropTypes.func.isRequired,
	selectAccount: PropTypes.func.isRequired,
	delAccount: PropTypes.func.isRequired
};

BankCardLayout.defaultProps = {
	checkKey: null
};

BankCardLayout.propTypes = {
	checkKey: PropTypes.string,
	bankInfo: PropTypes.objectOf(PropTypes.any).isRequired,
	selectAccount: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
	hideDelBtn: PropTypes.bool.isRequired,
	delAccount: PropTypes.func.isRequired
};

export default BankCard;

import React, { useRef, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import defPopBoxType from "../common/defPopBoxType";
import { defBonusType } from "./promotion.def";
import { popBoxContext } from "../common/context";
import { checkPromotion } from "./promotion.common";
import { api_promotion_SetClaimPromotion } from "../../service/api/api.promotion";

export default ({ promotionInfo, userInfo, callBack }) => {
	const { t } = useTranslation();
	const { isLogin } = userInfo;
	const isMountedRef = useRef(null);

	const { setPopBox } = useContext(popBoxContext);

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	const clickClose = () => {
		setPopBox({ isOpen: false });
	};

	const openErrorMessagePopBox = (
		message = t("msg_ApiServerError"),
		errorCode = null
	) => {
		setPopBox({
			message,
			errorCode,
			isOpen: true,
			popBoxType: defPopBoxType.ErrorMsg,
			icon: "icon-bell",
			btnBar: (
				<div className="btnGroup">
					<button className="btn-check" onClick={clickClose}>
						{t("lbl_Close")}
					</button>
				</div>
			)
		});
	};

	const setClaimPromotion = async (
		userLang,
		promotionCode,
		lockedAmount = 0
	) => {
		return api_promotion_SetClaimPromotion({
			promotionCode,
			lockedAmount: Number(lockedAmount)
		}).then(
			({ data: { message, errorCode } }) => {
				if (!isMountedRef.current) return;
				const success = errorCode === 0;

				if (success) {
					setPopBox({
						isOpen: true,
						popBoxType: defPopBoxType.Success,
						icon: "icon icon-bell",
						description: (
							<>
								<div>{t("lbl_JoinSuccessDescription1")}</div>
								<div className="text-center">
									{t("lbl_JoinSuccessDescription2")}
								</div>
							</>
						),
						btnBar: (
							<div className="btnGroup">
								<button
									className="btn-check"
									onClick={clickClose}>
									OK
								</button>
							</div>
						)
					});
					return true;
				}
				openErrorMessagePopBox(message || "error", errorCode);
			},
			() => {
				if (!isMountedRef.current) return;
				openErrorMessagePopBox();
			}
		);
	};

	const onJoinPromotion = async () => {
		const { userLang, currencyId } = userInfo;
		const { promotionCode, canJoin, bonusType, maxAmount } = promotionInfo;
		const promotionCanJoin = checkPromotion({
			promotionInfo,
			userInfo,
			setPopBox,
			t
		});

		if (!promotionCanJoin) return;
		if (canJoin && bonusType === defBonusType.redeposit)
			return setPopBox({
				isOpen: true,
				popBoxType: defPopBoxType.PromotionAmountConfirm,
				userLang,
				currencyId,
				promotionInfo,
				clickClose,
				setClaimPromotion
			});

		const success = await setClaimPromotion(
			userLang,
			promotionCode,
			maxAmount
		);

		if (success) return callBack();
	};
	if (
		promotionInfo.showComing ||
		(promotionInfo.isJoin && isLogin) ||
		promotionInfo.bonusType === defBonusType.rebate
	)
		return null;

	return (
		<button className="orange" onClick={onJoinPromotion}>
			{promotionInfo.bonusType === defBonusType.cashback
				? t("lbl_ClaimNow")?.toUpperCase()
				: t("lbl_PromoJoinNow")?.toUpperCase()}
		</button>
	);
};

import React from "react";
import Wrapper from "../components/common/wrapper";
import Layout from "../components/common/layout";
import Footer from "../components/footer/footer";
import Casino from "../components/casino/casino";

export default () => (
	<Wrapper>
		<Layout>
			<main>
				<Casino />
			</main>
			<Footer isFullFooter={true} />
		</Layout>
	</Wrapper>
);

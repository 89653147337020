import React, {
	useState,
	useEffect,
	useRef,
	useContext,
	useLayoutEffect
} from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Wrapper from "../components/common/wrapper";
import Layout from "../components/common/layout";
import Footer from "../components/footer/footer";
import LeftMenu from "../components/common/leftMenu";
import { api_entrance_GetAnnouncement } from "../service/api/api.entrance";
import Loading from "../components/common/loading";
import { loadingContext } from "../components/common/context";

export default () => (
	<Wrapper afterLogin={true}>
		<Layout>
			<main>
				<section className="inside">
					<LeftMenu />
					<div className="rightModule">
						<Content />
					</div>
				</section>
			</main>
			<Footer />
		</Layout>
	</Wrapper>
);

const Content = () => {
	const { t, i18n } = useTranslation();
	const [announcementData, setAnnouncementData] = useState([]);
	const { loading, setLoading } = useContext(loadingContext);

	const isMountedRef = useRef(null);

	useLayoutEffect(() => {
		setLoading(true);
	}, []);

	useEffect(() => {
		isMountedRef.current = true;

		const apiCall = async () => {
			try {
				const res = await api_entrance_GetAnnouncement(i18n.language);
				if (res.data.errorCode === 0) {
					setAnnouncementData(res.data.data);
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		};

		apiCall();

		return () => {
			isMountedRef.current = false;
		};
	}, []);

	const arr = announcementData?.map(item => {
		const isToday =
			moment(item.startTime).format("YYYY-MM-DD") ===
			moment().format("YYYY-MM-DD");

		const displayTime = isToday
			? moment(item.startTime).format("hh:mm:ss A")
			: moment(item.startTime).format("YYYY-MM-DD");

		const textColor = isToday ? "txt-blue" : "";

		return (
			<div key={`announcement-${item.id}`}>
				<div className="listHeader">
					<div>{item.subject}</div>
					<div className={textColor}>{displayTime}</div>
				</div>
				<pre className="listBody">{item.content}</pre>
			</div>
		);
	});

	return loading ? (
		<Loading />
	) : (
		<div className="infoModule">
			<div className="inside-title icon icon-icon32px_openmail">
				<span>{t("lbl_Announcement")}</span>
			</div>
			{announcementData?.length > 0 ? (
				<div className="announcement">
					<div className="list">{arr}</div>
				</div>
			) : (
				<div className="nodata icon icon-nodata">
					<div>{t("lbl_NoData")}</div>
				</div>
			)}
		</div>
	);
};

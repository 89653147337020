const siteName = "Beyond88";

export const errorPageUrl = userLang =>
	`https://s3-bt-img.s3.ap-southeast-1.amazonaws.com/404/index_${userLang}.html`;

export const SiteInfo = {
	siteName,
	langCookie: "beyond88LangId",
	socialMedia: {
		fb: {
			link: "https://www.facebook.com",
			displayKey: "lbl_Facebook"
		},
		twitter: {
			link: "https://twitter.com",
			displayKey: "lbl_Twitter"
		},
		ig: {
			link: "https://www.instagram.com",
			displayKey: "lbl_Instagram"
		},
		youtube: {
			link: "https://www.youtube.com",
			displayKey: "lbl_Youtube"
		}
	}
};

export const cloudImgUrl = "https://s3-bt-img.s3.ap-southeast-1.amazonaws.com";

export default process.env.NODE_ENV === "production"
	? {
			siteName,
			apiURL: "",
			openURL: "",
			imgUrl: "",
			t_and_cURL: "",
			cloudImgUrl
	  }
	: {
			siteName,
			cloudImgUrl,
			appURL: "http://localhost:4200",

			apiURL: "",
			imgUrl: "https://s3-bt-img.s3.ap-southeast-1.amazonaws.com",
			t_and_cURL: "https://s3-bt-img.s3.ap-southeast-1.amazonaws.com",
			openURL: "http://13.229.43.153"
	  };

export const defActionType = {
	GET_BALANCE: "GET_BALANCE",
	GET_CUST_BANKINFO: "GET_CUST_BANKINFO",
	SELECT_ACCOUNT: "SELECT_ACCOUNT",
	EDIT_AMOUNT: "EDIT_AMOUNT",
	CHECK_AMOUNT: "CHECK_AMOUNT",
	TO_OTHER_PANEL: "TO_OTHER_PANEL",
	SET_INIT_STATE: "SET_INIT_STATE",
	GET_RECENT_HISTORY: "GET_RECENT_HISTORY",
	SET_LOADING: "SET_LOADING",
	GET_CRYPTO_WALLET: "GET_CRYPTO_WALLET"
};

export const defPanel = {
	amount: "amount",
	confirm: "confirm"
};

export const initState = {
	balanceData: {},
	custBankInfo: {},
	selectedAccount: {},
	loading: true,
	redirectPath: null,
	panel: defPanel.amount,
	receiveUSDT: null,
	cryptoWallet: null
};

export function reducer(state, action) {
	switch (action.type) {
		case defActionType.GET_BALANCE:
			return {
				...state,
				balanceData: action.balanceData
			};

		case defActionType.GET_CUST_BANKINFO:
			return {
				...state,
				custBankInfo: action.custBankInfo
			};

		case defActionType.GET_CRYPTO_WALLET:
			return {
				...state,
				cryptoWallet: action.cryptoWallet
			};

		case defActionType.SELECT_ACCOUNT:
			return {
				...state,
				selectedAccount: action.selectedAccount
			};

		case defActionType.EDIT_AMOUNT:
			return {
				...state,
				amount: action.amount
			};

		case defActionType.CHECK_AMOUNT:
			return {
				...state,
				checked: action.checked,
				tip: action.tip
			};

		case defActionType.TO_OTHER_PANEL:
			return {
				...state,
				panel: action.panel
			};

		case defActionType.SET_INIT_STATE:
			return {
				...initState,
				custBankInfo: state.custBankInfo,
				cryptoWallet: state.cryptoWallet
			};

		case defActionType.GET_RECENT_HISTORY:
			return {
				...state,
				recentHistory: action.recentHistory
			};

		case defActionType.SET_LOADING:
			return {
				...state,
				loading: action.loading
			};

		default:
			console.log("action type not exists");
			return state;
	}
}

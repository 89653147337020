import { req, defDeviceType } from "./base";

export const api_thirdparty_GotoGame = ({
	gameId = null,
	TpId,
	LangId,
	AppType = defDeviceType.Desktop,
	fun = null,
	gameType = ""
}) =>
	req.post("/ThirdParty/GotoGame", {
		TpId,
		LangId,
		AppType,
		inputs: {
			GameCode: gameId,
			fun,
			gameType
		}
	});

export default {
	CS: { AreaCode: "+86", Pattern: "[0-9]*", Length: 11, MaxLength: 50 },
	AF: { AreaCode: "+93", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	AL: { AreaCode: "+355", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	DZ: { AreaCode: "+213", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	AO: { AreaCode: "+244", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	AI: { AreaCode: "+1-264", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	AR: { AreaCode: "+54", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	AM: { AreaCode: "+374", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	AW: { AreaCode: "+297", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	AU: { AreaCode: "+61", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	AT: { AreaCode: "+43", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	AZ: { AreaCode: "+994", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BS: { AreaCode: "+1-242", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BH: { AreaCode: "+973", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BD: { AreaCode: "+880", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BB: { AreaCode: "+1-246", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BY: { AreaCode: "+375", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BE: { AreaCode: "+32", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BZ: { AreaCode: "+501", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BJ: { AreaCode: "+229", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BM: { AreaCode: "+1-441", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BT: { AreaCode: "+975", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BO: { AreaCode: "+591", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BA: { AreaCode: "+387", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BW: { AreaCode: "+267", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BR: { AreaCode: "+55", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BN: { AreaCode: "+673", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BG: { AreaCode: "+359", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BF: { AreaCode: "+226", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	BI: { AreaCode: "+257", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	KH: { AreaCode: "+855", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	CM: { AreaCode: "+237", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	CA: { AreaCode: "+1", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	CV: { AreaCode: "+238", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	KY: { AreaCode: "+1-345", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	CF: { AreaCode: "+236", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	TD: { AreaCode: "+235", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	CL: { AreaCode: "+56", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	CO: { AreaCode: "+57", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	CG: { AreaCode: "+242", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	CK: { AreaCode: "+682", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	CR: { AreaCode: "+506", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	CI: { AreaCode: "+225", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	HR: { AreaCode: "+385", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	CY: { AreaCode: "+357", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	CZ: { AreaCode: "+420", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	CD: { AreaCode: "+243", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	DK: { AreaCode: "+45", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	DM: { AreaCode: "+1", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	DO: { AreaCode: "+1", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	EC: { AreaCode: "+593", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	EG: { AreaCode: "+20", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SV: { AreaCode: "+503", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	ER: { AreaCode: "+291", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	EE: { AreaCode: "+372", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	ET: { AreaCode: "+251", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	FO: { AreaCode: "+298", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	FJ: { AreaCode: "+679", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	FI: { AreaCode: "+358", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	FR: { AreaCode: "+33", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	GF: { AreaCode: "+594", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	PF: { AreaCode: "+689", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	GA: { AreaCode: "+241", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	GE: { AreaCode: "+995", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	DE: { AreaCode: "+49", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	GH: { AreaCode: "+233", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	GI: { AreaCode: "+350", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	GR: { AreaCode: "+30", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	GD: { AreaCode: "+1-473", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	GT: { AreaCode: "+502", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	GN: { AreaCode: "+224", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	GY: { AreaCode: "+592", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	HT: { AreaCode: "+509", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	HN: { AreaCode: "+504", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	HU: { AreaCode: "+36", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	IS: { AreaCode: "+354", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	IN: { AreaCode: "+91", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	ID: { AreaCode: "+62", Pattern: "[0-9]*", Length: 10, MaxLength: 50 },
	IR: { AreaCode: "+98", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	IQ: { AreaCode: "+964", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	IE: { AreaCode: "+353", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	IL: { AreaCode: "+972", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	IT: { AreaCode: "+39", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	JM: { AreaCode: "+1-876", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	JP: { AreaCode: "+81", Pattern: "[0-9]*", Length: 10, MaxLength: 50 },
	JO: { AreaCode: "+962", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	KE: { AreaCode: "+254", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	KR: { AreaCode: "+82", Pattern: "[0-9]*", Length: 9, MaxLength: 50 },
	KW: { AreaCode: "+965", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	KZ: { AreaCode: "+7", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	LA: { AreaCode: "+856", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	LV: { AreaCode: "+371", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	LB: { AreaCode: "+961", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	LS: { AreaCode: "+266", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	LI: { AreaCode: "+423", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	LT: { AreaCode: "+370", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	LU: { AreaCode: "+352", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	MO: { AreaCode: "+853", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	MK: { AreaCode: "+389", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	MG: { AreaCode: "+261", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	MW: { AreaCode: "+265", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	MY: { AreaCode: "+60", Pattern: "[0-9]*", Length: 9, MaxLength: 50 },
	MV: { AreaCode: "+960", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	ML: { AreaCode: "+223", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	MT: { AreaCode: "+356", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	MR: { AreaCode: "+222", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	MU: { AreaCode: "+230", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	MX: { AreaCode: "+52", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	MD: { AreaCode: "+373", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	MC: { AreaCode: "+377", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	MN: { AreaCode: "+976", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	MA: { AreaCode: "+212", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	MM: { AreaCode: "+95", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	NA: { AreaCode: "+264", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	NR: { AreaCode: "+674", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	NP: { AreaCode: "+977", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	NL: { AreaCode: "+31", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	NC: { AreaCode: "+687", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	NZ: { AreaCode: "+64", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	NE: { AreaCode: "+227", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	NG: { AreaCode: "+234", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	NO: { AreaCode: "+47", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	OM: { AreaCode: "+968", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	PK: { AreaCode: "+92", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	PW: { AreaCode: "+680", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	PA: { AreaCode: "+507", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	PG: { AreaCode: "+675", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	PY: { AreaCode: "+595", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	PE: { AreaCode: "+51", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	PN: { AreaCode: "+870", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	PL: { AreaCode: "+48", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	PT: { AreaCode: "+351", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	QA: { AreaCode: "+974", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	RO: { AreaCode: "+40", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	RU: { AreaCode: "+7", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	RW: { AreaCode: "+250", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	RS: { AreaCode: "+381", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	KN: { AreaCode: "+1-869", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	LC: { AreaCode: "+1-758", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	VC: { AreaCode: "+1-784", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SM: { AreaCode: "+378", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	ST: { AreaCode: "+239", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SA: { AreaCode: "+966", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SN: { AreaCode: "+221", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SC: { AreaCode: "+248", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SL: { AreaCode: "+232", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SK: { AreaCode: "+421", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SI: { AreaCode: "+386", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SB: { AreaCode: "+677", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SO: { AreaCode: "+252", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	ZA: { AreaCode: "+27", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	ES: { AreaCode: "+34", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	LK: { AreaCode: "+94", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SD: { AreaCode: "+249", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SR: { AreaCode: "+597", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SZ: { AreaCode: "+268", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SE: { AreaCode: "+46", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	CH: { AreaCode: "+41", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	SY: { AreaCode: "+963", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	TJ: { AreaCode: "+992", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	TZ: { AreaCode: "+255", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	TH: { AreaCode: "+66", Pattern: "[0-9]*", Length: 8, MaxLength: 50 },
	TG: { AreaCode: "+228", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	TT: { AreaCode: "+1-868", Pattern: "[0-9]*", Length: 8, MaxLength: 50 },
	TN: { AreaCode: "+216", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	TR: { AreaCode: "+90", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	UG: { AreaCode: "+256", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	UA: { AreaCode: "+380", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	AE: { AreaCode: "+971", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	UK: { AreaCode: "+44", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	UY: { AreaCode: "+598", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	UZ: { AreaCode: "+998", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	VU: { AreaCode: "+678", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	VE: { AreaCode: "+58", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	VN: {
		AreaCode: "+84",
		Pattern: "^[1-9]{1}[0-9]*$",
		Length: 9,
		MaxLength: 11
	},
	WF: { AreaCode: "+681", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	WS: { AreaCode: "+685", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	YE: { AreaCode: "+967", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	YU: { AreaCode: "+38", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	ZM: { AreaCode: "+260", Pattern: "[0-9]*", Length: 1, MaxLength: 50 },
	ZW: { AreaCode: "+263", Pattern: "[0-9]*", Length: 1, MaxLength: 50 }
};

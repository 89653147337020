import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DateTime from "react-datetime";
import Inputbox from "../common/inputbox";
import Dropdown from "../common/dropdown";

const DisplayRow = ({
	t,
	displayKey,
	content,
	areaCode,
	userInfo,
	userValue,
	status,
	editField,
	valueCheck,
	phoneCheck,
	isUSDT,
	areaCodeOptions,
	onSelectAreaCode
}) => {
	const legalDate = moment().subtract(18, "year");
	const vaildDate = current => current.isBefore(legalDate);

	if (displayKey === "lbl_realName" && !userInfo?.realName) {
		return (
			<div>
				<div>{t(displayKey)}</div>
				<div>
					<Inputbox
						className="inputBox-blue"
						onChange={editField.bind(this, "realName")}
						onBlur={e => valueCheck(e.target.value, "realName")}
						value={userValue.realName}
						checked={status.realName.checked}
						tooltip={t("lbl_RemindForName")}
					/>
					{status.realName.tip && (
						<div className="tip active txt-red">
							{status.realName.tip}
						</div>
					)}
				</div>
			</div>
		);
	}

	if (
		displayKey === "lbl_Birthday" &&
		(!userInfo?.birth_d || !userInfo?.birth_m || !userInfo?.birth_y)
	) {
		return (
			<div>
				<div>{t(displayKey)}</div>
				<div>
					<div className="inputBox-blue">
						<DateTime
							onChange={editField.bind(this, "birth")}
							onBlur={e => valueCheck(e, "birth")}
							value={userValue.birth}
							timeFormat={false}
							dateFormat="YYYY-MM-DD"
							isValidDate={vaildDate}
							initialViewDate={moment(legalDate).format(
								"YYYY-MM-DD"
							)}
						/>
						<div className="iconModule">
							<i className="icon icon-calendar" />
						</div>
					</div>

					{status.birth.tip ? (
						<div className="tip active txt-red">
							{status.birth.tip}
						</div>
					) : null}
				</div>
			</div>
		);
	}

	if (displayKey === "lbl_Phone") {
		let phoneContent = null;

		if (!userInfo?.phoneNo) {
			phoneContent = (
				<div className="phone">
					<div
						className="inputBox-blue"
						onBlur={() => phoneCheck("areaCode")}
						tabIndex="0"
						role="button">
						<Dropdown
							defaultOption={areaCode}
							mainKey="area_code"
							icon="icon-phone txt-blue"
							options={areaCodeOptions}
							selectedIdx={userValue.areaCodeIdx}
							i18n={t}
							onSelectItem={onSelectAreaCode}
							disabled={!isUSDT}
						/>
					</div>
					<Inputbox
						className="inputBox-blue"
						onChange={editField.bind(this, "phoneNo")}
						onBlur={e => phoneCheck("phoneNo", e.target.value)}
						value={userValue.phoneNo}
						checked={status.phoneNo.checked}
						inputType="phone"
						isTipHide={true}
					/>
					{status.phoneNo.tip ? (
						<div className="tip active txt-red">
							{status.phoneNo.tip}
						</div>
					) : null}
				</div>
			);
		} else {
			phoneContent = <div>{`(${areaCode})${content}`}</div>;
		}

		return (
			<div>
				<div>{t(displayKey)}</div>
				{phoneContent}
			</div>
		);
	}

	if (displayKey === "lbl_Email" && !userInfo?.email) {
		return (
			<div>
				<div>{t(displayKey)}</div>
				<div>
					<Inputbox
						className="inputBox-blue"
						onChange={editField.bind(this, "email")}
						onBlur={e => valueCheck(e.target.value, "email")}
						value={userValue.email}
						checked={status.email.checked}
						tooltip={t("lbl_EmailToolTip")}
					/>
					{status.email.tip && (
						<div className="tip active txt-red">
							{status.email.tip}
						</div>
					)}
				</div>
			</div>
		);
	}

	return (
		<div>
			<div>{t(displayKey)}</div>
			<div>{content}</div>
		</div>
	);
};

export default DisplayRow;

DisplayRow.defaultProps = {
	content: null
};

DisplayRow.propTypes = {
	t: PropTypes.func.isRequired,
	displayKey: PropTypes.string.isRequired,
	content: PropTypes.string,
	areaCode: PropTypes.string.isRequired,
	userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
	userValue: PropTypes.objectOf(PropTypes.any).isRequired,
	status: PropTypes.objectOf(PropTypes.any).isRequired,
	editField: PropTypes.func.isRequired,
	valueCheck: PropTypes.func.isRequired,
	phoneCheck: PropTypes.func.isRequired,
	isUSDT: PropTypes.bool.isRequired,
	areaCodeOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
	onSelectAreaCode: PropTypes.func.isRequired
};

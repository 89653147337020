export default {
	Cancel: "Cancel",
	Success: "Success",
	ErrorMsg: "ErrorMsg",
	Login: "Login",
	Join: "Join",
	AddBankAccount: "AddBankAccount",
	AddCryptoWallet: "AddCryptoWallet",
	AddBankSuccess: "AddBankSuccess",
	ConfirmDelete: "ConfirmDelete",
	ProfileConfirmCheck: "ProfileConfirmCheck",
	ProfileCompelete: "ProfileCompelete",
	ChangePsw: "ChangePsw",
	LogoutHint: "LogoutHint",
	DepositHint: "DepositHint",
	WithdrawalSuccess: "WithdrawalSuccess",
	UpdateProfile: "UpdateProfile",
	TurnoverAccumulated: "TurnoverAccumulated",
	RegisterSuccess: "RegisterSuccess",
	PromotionDepositConfirm: "PromotionDepositConfirm",
	PromotionAmountConfirm: "PromotionAmountConfirm",
	PaymentQR: "PaymentQR",
	Modal: "Modal"
};

import { useState } from "react";
import usePopBox from "../../hook/usePopBox";
import { api_payment_GetDepositInfoRecent } from "../../../service/api/api.payment";

const useDeposit = ({ isMountedRef }) => {
	const [depositInfoRecentDatas, setDepositInfoRecentDatas] = useState([]);

	const { errorPopBox } = usePopBox();

	const getDepositInfoRecent = async () => {
		await api_payment_GetDepositInfoRecent().then(
			res => {
				if (!isMountedRef.current) return;
				const { data, message, errorCode } = res.data;
				const success = errorCode === 0;
				if (success) {
					setDepositInfoRecentDatas(data);
					return data;
				}
				errorPopBox(message || `error`, errorCode);
			},
			() => {
				if (!isMountedRef.current) return;
				errorPopBox();
			}
		);
	};

	return {
		getDepositInfoRecent,
		depositInfoRecentDatas,
		setDepositInfoRecentDatas
	};
};

export { useDeposit };

export const defActionType = {
	GET_CUST_BANK_INFO: "GET_CUST_BANK_INFO",
	SET_BANKLIST: "SET_BANKLIST",
	SELECT_BANK: "SELECT_BANK",
	EDIT_FIELD: "EDIT_FIELD"
};

export const initState = {};

export function reducer(state, action) {
	switch (action.type) {
		case defActionType.GET_CUST_BANK_INFO: {
			let _realName = "";
			let _custBankList = [];
			if (action.datas) {
				_realName = action.datas.realName;
				_custBankList = action.datas.bankInfo ?? [];
			}

			return {
				...state,
				realName: _realName,
				custBankList: _custBankList
			};
		}

		case defActionType.SET_BANKLIST:
			return {
				...state,
				bankList: action.bankList
			};

		case defActionType.SELECT_BANK:
			return {
				...state,
				accountNumber: "",
				realName: "",
				bankIdx: action.bankIdx
			};

		case defActionType.EDIT_FIELD:
			return {
				...state,
				[action.col]: action.value
			};

		default:
			console.warn("this action type is not exists");
			return state;
	}
}

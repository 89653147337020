import React, {
	useEffect,
	useRef,
	useContext,
	useReducer,
	useState
} from "react";
import { useTranslation } from "react-i18next";
import { api_account_GetCustBankInfo } from "../../service/api/api.account";
import { api_accountStrict_DelCustBankInfo } from "../../service/api/api.accountStrict";
import { api_payment_GetBankInfoList } from "../../service/api/api.payment";
import { reducer, initState, defActionType } from "./bankInfo.reducer";
import { userInfoContext, popBoxContext } from "../common/context";
import AccountNamePanel from "./bankInfo.accountName";
import ShowCard from "./bankInfo.set.bankCard";
import defPopBoxType from "../common/defPopBoxType";
import Loading from "../common/loading";

export default () => {
	const { t } = useTranslation();
	const { userInfo } = useContext(userInfoContext);
	const { setPopBox } = useContext(popBoxContext);
	const [state, dispatch] = useReducer(reducer, initState);
	const [showTip, setShowTip] = useState(false);
	const isMountedRef = useRef(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	useEffect(() => {
		GetCustBankInfo();
	}, []);

	const clickClose = () => {
		setPopBox({ isOpen: false });
	};

	const clickTip = () => {
		setShowTip(!showTip);
	};

	const GetCustBankInfo = () => {
		api_account_GetCustBankInfo().then(
			res => {
				if (isMountedRef.current) {
					if (res.data.errorCode === 0) {
						const resData = res.data.data;

						dispatch({
							type: defActionType.GET_CUST_BANK_INFO,
							datas: resData
						});
					} else {
						setPopBox({
							isOpen: true,
							popBoxType: defPopBoxType.ErrorMsg,
							message: `[${res.data.errorCode}] ${res.data.message}`,
							btnBar: (
								<div className="btnGroup">
									<button
										className="btn-check"
										onClick={clickClose}>
										{t("lbl_Close")}
									</button>
								</div>
							)
						});
					}
					setLoading(false);
				}
			},
			() => {
				if (isMountedRef.current) {
					setPopBox({
						isOpen: true,
						popBoxType: defPopBoxType.ErrorMsg,
						message: t("msg_ApiServerError"),
						btnBar: (
							<div className="btnGroup">
								<button
									className="btn-check"
									onClick={clickClose}>
									{t("lbl_Close")}
								</button>
							</div>
						)
					});
					setLoading(false);
				}
			}
		);
	};

	const delBankAccount = (bankInfoId, accountNo) => {
		const ClickCancel = () => {
			setPopBox({
				isOpen: false
			});
		};

		const deleteCard = (_bankInfoId, AccountNo) => {
			setPopBox({
				isOpen: false
			});
			setLoading(true);

			api_accountStrict_DelCustBankInfo({
				bankInfoId: _bankInfoId,
				accountNo: AccountNo
			}).then(
				res => {
					if (isMountedRef.current) {
						if (res.data.errorCode === 0) {
							GetCustBankInfo();
							setPopBox({
								isOpen: false
							});
						} else {
							setPopBox({
								isOpen: true,
								popBoxType: defPopBoxType.ErrorMsg,
								message: `[${res.data.errorCode}] ${res.data.message}`,
								btnBar: (
									<div className="btnGroup">
										<button
											className="btn-check"
											onClick={ClickCancel}>
											{t("lbl_Close")}
										</button>
									</div>
								)
							});
						}
						setLoading(false);
					}
				},
				() => {
					if (isMountedRef.current) {
						setPopBox({
							isOpen: true,
							popBoxType: defPopBoxType.ErrorMsg,
							message: t("msg_ApiServerError"),
							btnBar: (
								<div className="btnGroup">
									<button
										className="btn-check"
										onClick={ClickCancel}>
										{t("lbl_Close")}
									</button>
								</div>
							)
						});
						setLoading(false);
					}
				}
			);
		};

		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.ConfirmDelete,
			message: t("lbl_AreYouSureDelete"),
			message2: t("lbl_CantBeRestore"),
			btnBar: (
				<div className="btnGroup">
					<button
						className="btn-check"
						onClick={deleteCard.bind(this, bankInfoId, accountNo)}>
						{t("lbl_Yes")}
					</button>
					<button className="btn-cancel" onClick={ClickCancel}>
						{t("lbl_No")}
					</button>
				</div>
			)
		});
	};

	const ClickClose = () => {
		setPopBox({ isOpen: false });
	};

	useEffect(() => {
		api_payment_GetBankInfoList().then(
			res => {
				if (isMountedRef.current) {
					setLoading(true);
					if (res.data.errorCode === 0) {
						dispatch({
							type: defActionType.SET_BANKLIST,
							bankList: res.data.data
						});
					} else {
						setPopBox({
							isOpen: true,
							popBoxType: defPopBoxType.ErrorMsg,
							message: `[${res.data.errorCode}] ${res.data.message}`,
							btnBar: (
								<div className="btnGroup">
									<button
										className="btn-check"
										onClick={ClickClose}>
										{t("lbl_Close")}
									</button>
								</div>
							)
						});
					}
					setLoading(false);
				}
			},
			() => {
				if (isMountedRef.current) {
					setPopBox({
						isOpen: true,
						popBoxType: defPopBoxType.ErrorMsg,
						message: t("msg_ApiServerError"),
						btnBar: (
							<div className="btnGroup">
								<button
									className="btn-check"
									onClick={ClickClose}>
									{t("lbl_Close")}
								</button>
							</div>
						)
					});
					setLoading(false);
				}
			}
		);
	}, [state.custBankList]);

	const onShowAddBankAccount = () => {
		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.AddBankAccount,
			bankInfoSysList: state.bankList,
			custBankInfo: state.custBankList,
			GetCustBankInfo
		});
	};

	const numberOfAccount = state.custBankList ? state.custBankList.length : 0;

	return loading ? (
		<Loading />
	) : (
		<>
			<div className="infoModule">
				<div className="inside-title icon icon-icon32px_bank-account">
					<span>{t("lbl_BankInfo")}</span>
					<div>{t("lbl_UpdateBankInfo")}</div>
				</div>

				<div className="accountName">
					{userInfo.realName ? (
						<AccountNamePanel
							t={t}
							realName={userInfo.realName}
							clickTip={clickTip}
							showTip={showTip}
						/>
					) : null}
				</div>

				<div className="walletModule">
					<div className="addBank">
						{numberOfAccount < 3 ? (
							<div
								className="addBankName noBank"
								onClick={onShowAddBankAccount}>
								<div className="icon icon-plus" />
								<div>{t("lbl_AddBankAccount")}</div>
							</div>
						) : (
							<div className="addBankName noBank">
								<div className="icon icon-warning" />
								<div>{t("lbl_BankCardsFull")}</div>
							</div>
						)}

						<div className="remainder">
							{t("lbl_NumberOfBankAccounts")}:{" "}
							{numberOfAccount || 0}/3
						</div>
					</div>
				</div>
			</div>
			<ShowCard
				t={t}
				custBankList={state.custBankList}
				delBankAccount={delBankAccount}
				loading={loading}
			/>
		</>
	);
};

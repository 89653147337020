import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "gatsby";
import { userInfoContext, popBoxContext } from "../common/context";
import { TransactionHistory } from "./deposit.historyResult";
import { api_payment_SetDepositCancel } from "../../service/api/api.payment";
import { isConvert } from "../common/common";
import defPopBoxType from "../common/defPopBoxType";
import ReloadSpin from "../common/reloadSpin";
import path from "../../router";
import { defHistoryIdList } from "../history/def";
import usePopBox from "../hook/usePopBox";

const DepositRecentHistory = ({
	getDepositInfoRecent,
	depositInfoRecentDatas,
	setDepositInfoRecentDatas
}) => {
	const { t } = useTranslation();
	const { userInfo } = useContext(userInfoContext);
	const { setPopBox } = useContext(popBoxContext);
	const { errorPopBox } = usePopBox();
	const _isConvert = isConvert(userInfo.currencyId);
	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	useEffect(() => {
		getDepositInfoRecent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const ClosePopBox = () => {
		setPopBox({ isOpen: false });
	};

	const ClickCancel = orderId => {
		const ClickConfirm = () => {
			setPopBox({ isOpen: false });
			api_payment_SetDepositCancel(orderId).then(
				res => {
					if (isMountedRef.current) {
						if (res.data.errorCode === 0) {
							setDepositInfoRecentDatas(prevState => {
								const newD = [...prevState];
								const idx = newD.findIndex(
									x => x.orderId === orderId
								);
								newD[idx].orderStatus = 5;
								return newD;
							});
						} else {
							errorPopBox(
								res.data.message || "error",
								res.data.errorCode
							);
						}
					}
				},
				() => {
					if (isMountedRef.current) {
						errorPopBox();
					}
				}
			);
		};

		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.Cancel,
			btnBar: (
				<div className="btnGroup">
					<button className="gray" onClick={ClickConfirm}>
						{t("lbl_Yes")}
					</button>
					<button className="orange" onClick={ClosePopBox}>
						{t("lbl_No")}
					</button>
				</div>
			)
		});
	};

	const arr = depositInfoRecentDatas?.map((item, i) => (
		<TransactionHistory
			key={`deposit-recent-${item.orderId}`}
			{...item}
			_isConvert={_isConvert}
			idx={i + 1}
			ClickCancel={ClickCancel.bind(this, item.orderId)}
		/>
	));

	return (
		<>
			<div className="list">
				<div className="listHeader">
					<div>{t("lbl_NO")}</div>
					<div>
						{t("lbl_Date")} ({moment().format("Z")})
					</div>
					<div>{t("lbl_ID")}</div>
					<div>{t("lbl_Method")}</div>
					<div>{t("lbl_Amount")}</div>
					<div>{t("lbl_Status")}</div>
					<div>
						{t("lbl_Action")}
						<ReloadSpin func={getDepositInfoRecent} />
					</div>
				</div>
				{depositInfoRecentDatas?.length > 0 ? (
					<div className="listBody">{arr}</div>
				) : (
					<div className="nodata icon icon-nodata">
						<div>{t("lbl_NoData")}</div>
					</div>
				)}
			</div>
			<Link
				to={`/${userInfo.userLang}/${path.history.root}?type=${defHistoryIdList.depositId}`}
				className="icon icon-books"
			/>
		</>
	);
};

DepositRecentHistory.propTypes = {
	// asyncUploadBankPopBox: PropTypes.func.isRequired,
	getDepositInfoRecent: PropTypes.func.isRequired,
	depositInfoRecentDatas: PropTypes.arrayOf(PropTypes.any).isRequired,
	setDepositInfoRecentDatas: PropTypes.func.isRequired
	// uploadLocalBankReceipt: PropTypes.func.isRequired
	// setLocalBankReceipt: PropTypes.func.isRequired
};
export default DepositRecentHistory;

import config, { errorPageUrl } from "../config";
import path from "../router";

export const _ACTION_TYPE = {
	blank: 1,
	redirect: 2
};

export const OPEN_IN_BLANK = (url, windowKey) => {
	if (typeof window !== `undefined`) {
		window.open(
			url,
			windowKey,
			"toolbar=no, menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no, top=0, left=0"
		);
	}
};

export const GET_OPEN_RULE = (userLang, tpId, gameId, matchid, betType) => {
	const _tpId = isNaN(tpId) ? tpId : Number(tpId);
	const _gameId = isNaN(gameId) ? gameId : Number(gameId);
	const _matchid = isNaN(matchid) ? matchid : Number(matchid);
	const result = {
		url: "",
		needLogin: true,
		actionType: _ACTION_TYPE.blank,
		windowKey: `Tp_${_tpId}${gameId ? `_${gameId}` : ""}`
	};

	return {
		...result,
		entPageUrl: GET_ENTER_PAGE_URL(
			userLang,
			_tpId,
			_gameId,
			_matchid,
			betType
		)
	};
};

export const GET_ENTER_PAGE_URL = (
	userLang,
	tpId,
	gameId,
	matchid,
	betType
) => {
	const appUrl =
		typeof window !== `undefined` ? window.location.origin : null;
	const homeUrl = `${appUrl}`;
	const signupUrl = `${appUrl}/${path.join.root}`;
	const LoginUrl = `${appUrl}/${path.login.root}`;
	const lobbyUrl = ``;
	const cashierUrl = `${appUrl}/${path.deposit.root}`;
	const _matchid = matchid || "";
	const _betType = betType ? `&bettype=${betType}` : "";
	const _errorPageUrl = errorPageUrl(userLang);

	return `${config.openURL}/${userLang}/entPage?homeUrl=${homeUrl}&signupUrl=${signupUrl}&LoginUrl=${LoginUrl}&tpId=${tpId}&gameId=${gameId}&CashierUrl=${cashierUrl}&LobbyUrl=${lobbyUrl}&matchid=${_matchid}${_betType}&errorUrl=${_errorPageUrl}`;
};

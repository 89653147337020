import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Inputbox from "../../common/inputbox";
import ShortcutAmountList from "./shortcutAmountList";
import { getUrlParameter } from "../../common/common";

import ClipboardJS from "../../../lib/clipboard.min";
import { cloudImgUrl } from "../../../config";
import { useSyncRouteQuery } from "../hooks";

const LocalBankForm = ({
	t,
	depositType,
	formatAmount,
	localBankGroupList,
	formInfo,
	setFormInfo,
	onChangeAmount,
	onInputboxKeyDown,
	getLocalBankInfo,
	localBankInfo,
	isGroupListFetchDone
}) => {
	const parameterBankInfoId = Number(getUrlParameter("bankInfoId"));
	const [bankInfoIdForRecord, setBankInfoIdForRecord] =
		useState(parameterBankInfoId);

	const onChangeBankInfo = item => {
		setBankInfoIdForRecord(item.bankInfoId);
		getLocalBankInfo({ bankInfoId: item.bankInfoId });
		setFormInfo(prev => ({ ...prev, ...item }));
	};

	useSyncRouteQuery({
		type: depositType,
		bankInfoId: bankInfoIdForRecord
	});

	useEffect(() => {
		const _clipboard = new ClipboardJS(".copyBtn");
		_clipboard.on("success", () => 0);
		_clipboard.on("error", () => 0);

		return () => {
			_clipboard.destroy();
		};
	});

	useEffect(() => {
		if (isGroupListFetchDone) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			if (
				localBankGroupList.some(
					item => item.bankInfoId === bankInfoIdForRecord
				)
			) {
				const gIdx = localBankGroupList.findIndex(
					item => item.bankInfoId === bankInfoIdForRecord
				);

				onChangeBankInfo(localBankGroupList[gIdx]);
			} else if (localBankGroupList.length > 0) {
				onChangeBankInfo(localBankGroupList[0]);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isGroupListFetchDone]);

	if (localBankGroupList.length <= 0) return null;

	let { minAmount, maxAmount } = formInfo;

	if (minAmount === 0 && maxAmount === 0) {
		minAmount = 1;
		maxAmount = t("lbl_Unlimited");
	}

	return (
		<>
			<div className="subModuleTitle">
				<i className="txt-blue">*</i>
				{t("lbl_SelectBank")}
			</div>

			<div className="subModule selectBank">
				<div>
					{localBankGroupList.map(item => {
						const bankLogo = String(item.bankLogo).toLowerCase();

						let className = [];
						if (item.bankInfoId === bankInfoIdForRecord) {
							className.push("active");
						}

						className = className.join(" ");

						let {
							minAmount: itemMinAmount,
							maxAmount: itemMaxAmount
						} = item;

						if (itemMinAmount === 0 && itemMaxAmount === 0) {
							itemMinAmount = 1;
							itemMaxAmount = t("lbl_Unlimited");
						}

						return (
							<button
								key={`deposit-localPay-group-${item.bankInfoId}}`}
								className={className}
								onClick={() => onChangeBankInfo(item)}>
								<div>
									<img
										src={`${cloudImgUrl}/BankLogo/${bankLogo}`}
										alt={item.bankName}
									/>
									<i className="icon icon-icon32px_check" />
								</div>

								<div className="bank">
									<div>{item.bankName}</div>
									<div>
										<div>
											{`${t("lbl_Min")}: ${formatAmount(
												itemMinAmount
											)}`}
										</div>
										<div>
											{`${t("lbl_Max")}: ${formatAmount(
												itemMaxAmount
											)}`}
										</div>
									</div>
								</div>
							</button>
						);
					})}
				</div>
			</div>

			<div className="subModuleTitle">
				<i className="txt-blue">*</i>
				{t("lbl_BankAccount")}
			</div>

			<div className="subModule">
				<div className="bankAccount">
					{t("lbl_TransactionHint")}
					<div className="smallList">
						<ul>
							<li>{t("lbl_BankName")}</li>
							<li>{localBankInfo.bankName}</li>
							<li>
								<a
									target="_blank"
									rel="noreferrer"
									href={localBankInfo.bankSite}
									className="icon icon-new-tab"
								/>
							</li>
						</ul>
						<ul>
							<li>{t("lbl_BankAccountName")}</li>
							<li>{localBankInfo.accountName}</li>
							<button
								className="icon icon-files-empty copyBtn"
								data-clipboard-text={localBankInfo.accountName}
							/>
						</ul>
						<ul>
							<li>{t("lbl_BankNumber")}</li>
							<li>{localBankInfo.accountNo}</li>
							<button
								className="icon icon-files-empty copyBtn"
								data-clipboard-text={localBankInfo.accountNo}
							/>
						</ul>
					</div>
				</div>
			</div>

			<div className="subModuleTitle">
				<i className="txt-blue">*</i>
				{t("lbl_DepositAmount")}
			</div>

			<div className="subModule">
				<Inputbox
					className="inputBox-white"
					// title={t("lbl_Amount")}
					value={formInfo.amount}
					onChange={e => onChangeAmount(e.target.value)}
					checked={formInfo?.checked}
					tip={formInfo?.tip}
					_isConvert={false}
					isAmount={true}
					placeholder={`${t("lbl_MinMaxAmount")} ${formatAmount(
						minAmount
					)} ~ ${formatAmount(maxAmount)}`}
					onKeyDown={onInputboxKeyDown}
				/>

				<ShortcutAmountList
					amount={formInfo.amount}
					minAmount={formInfo.minAmount}
					maxAmount={formInfo.maxAmount}
					onClick={amount => onChangeAmount(amount)}
				/>
			</div>
		</>
	);
};

LocalBankForm.propTypes = {
	formInfo: PropTypes.objectOf(PropTypes.any).isRequired,
	depositType: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired,
	formatAmount: PropTypes.func.isRequired,
	localBankGroupList: PropTypes.arrayOf(PropTypes.any).isRequired,
	setFormInfo: PropTypes.func.isRequired,
	onChangeAmount: PropTypes.func.isRequired,
	onInputboxKeyDown: PropTypes.func.isRequired,
	getLocalBankInfo: PropTypes.func.isRequired,
	localBankInfo: PropTypes.objectOf(PropTypes.any).isRequired,
	isGroupListFetchDone: PropTypes.bool.isRequired
};
export default LocalBankForm;

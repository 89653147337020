import React, { memo } from "react";
import indexCenterBanner from "../../styles/img/indexCenterBanner.jpg";

const IndexCenterBanner = () => (
	<img
		src={indexCenterBanner}
		className="indexCenterBanner"
		alt="IndexCenterBanner"
	/>
);

export default memo(IndexCenterBanner);

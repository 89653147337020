import React from "react";
import PropTypes from "prop-types";
import DisplayRow from "./profile.form.row";

const ProfileForm = ({
	t,
	infoList,
	areaCodeVal,
	userInfo,
	userValue,
	status,
	editField,
	valueCheck,
	phoneCheck,
	clickInfo,
	clickSubmit,
	isUSDT,
	areaCodeOptions,
	onSelectAreaCode
}) => {
	const arr = infoList?.map(item => {
		let displayValue = item.content;

		if (item.format) {
			displayValue = item.format(item.content, item.bindingData);
		}

		return (
			<DisplayRow
				key={`profile-${item.displayKey}`}
				t={t}
				displayKey={item.displayKey}
				content={displayValue}
				areaCode={areaCodeVal}
				userInfo={userInfo}
				userValue={userValue}
				status={status}
				editField={editField}
				valueCheck={valueCheck}
				phoneCheck={phoneCheck}
				clickInfo={clickInfo}
				clickSubmit={clickSubmit}
				isUSDT={isUSDT}
				areaCodeOptions={areaCodeOptions}
				onSelectAreaCode={onSelectAreaCode}
			/>
		);
	});
	return <div className="profile">{arr}</div>;
};

export default ProfileForm;

ProfileForm.propTypes = {
	t: PropTypes.func.isRequired,
	infoList: PropTypes.arrayOf(PropTypes.any).isRequired,
	areaCodeVal: PropTypes.string.isRequired,
	userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
	userValue: PropTypes.objectOf(PropTypes.any).isRequired,
	status: PropTypes.objectOf(PropTypes.any).isRequired,
	editField: PropTypes.func.isRequired,
	valueCheck: PropTypes.func.isRequired,
	phoneCheck: PropTypes.func.isRequired,
	clickInfo: PropTypes.func.isRequired,
	clickSubmit: PropTypes.func.isRequired,
	isUSDT: PropTypes.bool.isRequired,
	areaCodeOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
	onSelectAreaCode: PropTypes.func.isRequired
};

import React, { useState, useEffect, useRef } from "react";
import "react-datetime/css/react-datetime.css";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import * as ct from "./context";
import { defaultLang } from "../../i18n/config";
import { setCookie, readCookie, getUrlParameter } from "./common";
import SEO from "../seo/seo";
import {
	api_account_GetCustProfile,
	api_account_OnlineCheck,
	api_account_GetMsgCount
} from "../../service/api/api.account";
import defPopBoxType from "./defPopBoxType";
import useInterval from "../hook/useInterval";
import {
	GET_LANG_ID,
	isVaildLang,
	getNewLanguagePath,
	isTmpSupportLang
} from "../../service/language";
import { SiteInfo } from "../../config";
import useGetWalletInfo from "./wrapper.walletInfo";
import ThirdPartyJS from "./thirdPartyJS";
import usePopBox from "../hook/usePopBox";

import {
	api_entrance_CheckSignIn,
	api_entrance_MerchantInfo
} from "../../service/api/api.entrance";

import { api_payment_GetCryptoGroup } from "../../service/api/api.payment";

const defaultPathLang = () => {
	const urlLang =
		typeof document === `undefined`
			? ""
			: window.location.pathname.split("/")?.[1];
	const cookieLang = readCookie(SiteInfo.langCookie);
	const _defaultLang = GET_LANG_ID();

	let result = _defaultLang;
	if (isVaildLang(urlLang)) {
		result = isTmpSupportLang(urlLang) ? _defaultLang : urlLang;
	} else if (isVaildLang(cookieLang)) {
		result = cookieLang;
	}
	return result;
};

export default ({ children, afterLogin = false, onlyBeforeLogin = false }) => {
	const { t, i18n } = useTranslation();
	const [popBox, setPopBox] = useState({
		isOpen: false,
		popBoxType: null
	});
	const [merchantInfo, setMerchantInfo] = useState({});
	const [cryptoInfo, setCryptoInfo] = useState();
	const [loading, setLoading] = useState(false);
	const [msgCount, setMsgCount] = useState(0);
	const [userInfo, setUserInfo] = useState({
		isLogin: null,
		userName: null,
		userLang: defaultPathLang()
	});

	const [currentPath] = useState(
		typeof window !== `undefined` ? window.location.pathname : null
	);
	const [correctPath, setCorrectPath] = useState(false);
	const [redirectPath, setRedirectPath] = useState(null);

	const { errorPopBox } = usePopBox(setPopBox);
	const walletInfo = useGetWalletInfo({
		isLogin: userInfo.isLogin,
		userLang: userInfo.userLang,
		errorPopBox
	});

	const [isRoot, setIsRoot] = useState(false);

	const isMountedRef = useRef(null);

	const [popUp, setPopUp] = useState({
		popupMessage: "",
		id: "",
		pageNumber: ""
	});

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	useEffect(() => {
		const refId = getUrlParameter("refid");
		if (refId) {
			setCookie("refId", refId);
		}
	}, []);

	useEffect(() => {
		api_entrance_CheckSignIn()
			.then(
				res => res.data.data,
				() => setCorrectPath(true)
			)
			.then(isLogin => {
				if (userInfo.kickout) {
					if (userInfo.willLogout) {
						setUserInfo(prevS => ({
							...prevS,
							kickout: false,
							willLogout: false
						}));
					} else {
						const ClickClose = () => {
							setPopBox({ isOpen: false });
							setUserInfo(prevS => ({
								...prevS,
								kickout: false
							}));
						};
						setPopBox({
							isOpen: true,
							popBoxType: defPopBoxType.LogoutHint,
							content: t("msg_ForceLogedout"),
							btnBar: (
								<div className="btnGroup">
									<button
										className="btn-check"
										onClick={ClickClose}>
										{t("lbl_Close")}
									</button>
								</div>
							)
						});
					}
				} else if (
					isMountedRef.current &&
					((afterLogin && !isLogin) || (isLogin && onlyBeforeLogin))
				) {
					if (
						localStorage.getItem(
							`${userInfo.username}-loginViaJoin`
						) === "1"
					) {
						localStorage.removeItem(
							`${userInfo.username}-loginViaJoin`
						);
					} else {
						setRedirectPath(`/${userInfo.userLang}`);
					}
				}
				if (isLogin !== userInfo.isLogin && isMountedRef.current) {
					setUserInfo(prevState => ({
						...prevState,
						isLogin
					}));
				}
			});
	}, [
		userInfo.isLogin,
		userInfo.kickout,
		userInfo.willLogout,
		setUserInfo,
		setRedirectPath,
		setPopBox
	]);

	useEffect(() => {
		if (userInfo.isLogin) {
			if (!userInfo.registeredAt) {
				api_account_GetCustProfile().then(
					res_custInfo => {
						if (
							res_custInfo.data.errorCode === 0 &&
							isMountedRef.current
						) {
							const { data } = res_custInfo.data;
							setCookie(SiteInfo.langCookie, data.userLang);
							setUserInfo(prevState => ({
								...prevState,
								...data,
								userLang: data.userLang
							}));
						} else if (res_custInfo.data.errorCode !== 0) {
							setPopBox({
								isOpen: true,
								popBoxType: defPopBoxType.ErrorMsg,
								message: `[${res_custInfo.data.errorCode}] ${res_custInfo.data.message}`,
								btnBar: (
									<div className="btnGroup">
										<button
											className="btn-check"
											onClick={() => {
												setPopBox({ isOpen: false });
											}}>
											{t("lbl_Close")}
										</button>
									</div>
								)
							});
						}
					},
					() => {
						if (isMountedRef.current) {
							setPopBox({
								isOpen: true,
								popBoxType: defPopBoxType.ErrorMsg,
								message: t("msg_ApiServerError"),
								btnBar: (
									<div className="btnGroup">
										<button
											className="btn-check"
											onClick={() => {
												setPopBox({ isOpen: false });
											}}>
											{t("lbl_Close")}
										</button>
									</div>
								)
							});
						}
					}
				);
			}

			api_account_GetMsgCount().then(res => {
				if (res.data.errorCode === 0 && isMountedRef.current) {
					setMsgCount(res.data.data);
				} else if (res.data.errorCode !== 0) {
					setPopBox({
						isOpen: true,
						popBoxType: defPopBoxType.ErrorMsg,
						message: `[${res.data.errorCode}] ${res.data.message}`,
						btnBar: (
							<div className="btnGroup">
								<button
									className="btn-check"
									onClick={() => {
										setPopBox({ isOpen: false });
									}}>
									{t("lbl_Close")}
								</button>
							</div>
						)
					});
				}
			});
		} else if (isMountedRef.current) {
			setMsgCount(0);
		}
	}, [userInfo.isLogin, userInfo.registeredAt]);

	useEffect(() => {
		if (userInfo.isLogin !== null) {
			if (typeof window !== `undefined`) {
				const pathLang = window.location.pathname.split("/")?.[1];
				const currentLang = isVaildLang(userInfo.userLang)
					? userInfo.userLang
					: GET_LANG_ID();

				if (!pathLang && currentLang !== defaultLang) {
					setRedirectPath(`/${currentLang}`);
				} else if (pathLang !== "" && pathLang !== currentLang) {
					setUserInfo(prevState => ({
						...prevState,
						userLang: currentLang
					}));

					const newPathArr = getNewLanguagePath(currentLang);
					navigate(newPathArr);
				} else {
					setCorrectPath(true);
				}
			}
		}
	}, [currentPath, i18n.language, userInfo.userLang, userInfo.isLogin]);

	useEffect(() => {
		const getMerchantInfo = async () => {
			try {
				const res = await api_entrance_MerchantInfo({
					userLang: userInfo?.userLang
				});
				const { errorCode, data } = res.data;

				if (errorCode === 0) {
					setMerchantInfo(data);
				}
			} catch (error) {}
		};

		getMerchantInfo();
	}, []);

	useEffect(() => {
		const getCryptoInfo = async () => {
			try {
				const res = await api_payment_GetCryptoGroup();
				if (res.data.errorCode === 0 && res.data?.data) {
					if (isMountedRef.current) {
						setCryptoInfo(res.data.data);
					}
				} else if (res.data.errorCode !== 0) {
					errorPopBox(
						res.data.message || "error",
						res.data.errorCode
					);
				}
			} catch (error) {
				errorPopBox();
			}
		};

		if (userInfo.isLogin) {
			getCryptoInfo();
		}
	}, [userInfo.isLogin]);

	useInterval(
		async () => {
			try {
				const res = await api_account_OnlineCheck();
				if (res.data.errorCode !== 0) {
					setUserInfo(prev => ({
						...prev,
						isLogin: false,
						userLang: prev.userLang,
						kickout: true
					}));
				}
			} catch (error) {}
		},
		userInfo.isLogin ? 10000 : null
	);

	if (redirectPath === null) {
		const content = correctPath ? (
			<ct.merchantInfoContext.Provider
				value={{ merchantInfo, setMerchantInfo }}>
				<ct.cryptoInfoContext.Provider
					value={{ cryptoInfo, setCryptoInfo }}>
					<ct.popUpMsgContext.Provider value={{ setPopUp, popUp }}>
						<ct.userInfoContext.Provider
							value={{ userInfo, setUserInfo }}>
							<ct.walletInfoContext.Provider
								value={{
									...walletInfo.state,
									dispatch: walletInfo.dispatch
								}}>
								<ct.msgCountContext.Provider
									value={{ msgCount, setMsgCount }}>
									<ct.popBoxContext.Provider
										value={{ popBox, setPopBox }}>
										<ct.loadingContext.Provider
											value={{ loading, setLoading }}>
											<ct.isRootContext.Provider
												value={{ isRoot, setIsRoot }}>
												<ThirdPartyJS />
												<SEO lang={userInfo.userLang} />
												{children}
											</ct.isRootContext.Provider>
										</ct.loadingContext.Provider>
									</ct.popBoxContext.Provider>
								</ct.msgCountContext.Provider>
							</ct.walletInfoContext.Provider>
						</ct.userInfoContext.Provider>
					</ct.popUpMsgContext.Provider>
				</ct.cryptoInfoContext.Provider>
			</ct.merchantInfoContext.Provider>
		) : (
			<ThirdPartyJS />
		);

		return content;
	}

	navigate(redirectPath);

	return [];
};

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-announcement-js": () => import("./../../../src/pages/announcement.js" /* webpackChunkName: "component---src-pages-announcement-js" */),
  "component---src-pages-bank-info-js": () => import("./../../../src/pages/bankInfo.js" /* webpackChunkName: "component---src-pages-bank-info-js" */),
  "component---src-pages-casino-js": () => import("./../../../src/pages/casino.js" /* webpackChunkName: "component---src-pages-casino-js" */),
  "component---src-pages-change-password-js": () => import("./../../../src/pages/changePassword.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-crypto-wallet-js": () => import("./../../../src/pages/cryptoWallet.js" /* webpackChunkName: "component---src-pages-crypto-wallet-js" */),
  "component---src-pages-deposit-js": () => import("./../../../src/pages/deposit.js" /* webpackChunkName: "component---src-pages-deposit-js" */),
  "component---src-pages-fishing-js": () => import("./../../../src/pages/fishing.js" /* webpackChunkName: "component---src-pages-fishing-js" */),
  "component---src-pages-forget-password-js": () => import("./../../../src/pages/forgetPassword.js" /* webpackChunkName: "component---src-pages-forget-password-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-live-casino-js": () => import("./../../../src/pages/liveCasino.js" /* webpackChunkName: "component---src-pages-live-casino-js" */),
  "component---src-pages-message-js": () => import("./../../../src/pages/message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-p-2-p-js": () => import("./../../../src/pages/p2p.js" /* webpackChunkName: "component---src-pages-p-2-p-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-promotion-js": () => import("./../../../src/pages/promotion.js" /* webpackChunkName: "component---src-pages-promotion-js" */),
  "component---src-pages-promotion-promotion-info-js": () => import("./../../../src/pages/promotion/promotionInfo.js" /* webpackChunkName: "component---src-pages-promotion-promotion-info-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/resetPassword.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-withdrawal-js": () => import("./../../../src/pages/withdrawal.js" /* webpackChunkName: "component---src-pages-withdrawal-js" */)
}


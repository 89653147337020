import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, navigate } from "gatsby";
import { userInfoContext, popBoxContext, promoPopBox } from "../common/context";
import { getUrlParameter, isConvert, number_format } from "../common/common";
import { api_promotion_GetPromotionDetail } from "../../service/api/api.promotion";
import { api_account_GetCustBonusWallet } from "../../service/api/api.account";
import defPopBoxType from "../common/defPopBoxType";
import { defBonusType } from "./promotion.def";
import { RULE_TYPE } from "./promotion.common";
import ProcessBar from "./promotion.processBar";
import PromotionJoinButton from "./promotion.joinButton";
import PromoRule from "./promotion.promoRule";
import CashBackInfo from "./cashBackInfo";
import path from "../../router";

export const useHandlePromotionInfo = ({ showAllPromosBtn = false }) => {
	const { t } = useTranslation();
	const { setPopBox } = useContext(popBoxContext);
	const { setPromoBox } = useContext(promoPopBox);

	const urlPromotionCode = getUrlParameter("promotionCode");

	useEffect(() => {
		if (
			window.location.pathname.includes(
				`${path.promotion.root}/${path.promotion.sub.promotionInfo.root}`
			)
		) {
			getPromotionDetail(urlPromotionCode);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [urlPromotionCode]);

	const closePromoPopBox = () => {
		setPromoBox(prev => ({ ...prev, isOpen: false }));
		window.history.replaceState({}, "", `${window.location.pathname}`);
	};

	const openErrorMessagePopBox = (
		message = t("msg_ApiServerError"),
		errorCode = null
	) => {
		const clickClose = () => {
			setPopBox({ isOpen: false });
		};

		setPopBox({
			message,
			errorCode,
			isOpen: true,
			popBoxType: defPopBoxType.ErrorMsg,
			icon: "icon-bell",
			btnBar: (
				<div className="btnGroup">
					<button className="btn-check" onClick={clickClose}>
						{t("lbl_Close")}
					</button>
				</div>
			)
		});
	};

	const getPromotionDetail = async promotionCode => {
		if (promotionCode) {
			await api_promotion_GetPromotionDetail({
				promotionCode
			}).then(
				({ data: { data, message, errorCode } }) => {
					const success = errorCode === 0;

					if (success && data) {
						setPromoBox({
							isOpen: true,
							content: (
								<PromotionInfo
									promotionInfo={data}
									getPromotionDetail={getPromotionDetail}
									refreshProcessBar={refreshProcessBar}
									closePromoPopBox={closePromoPopBox}
									showAllPromosBtn={showAllPromosBtn}
								/>
							),
							processBarInfo: null
						});
					} else {
						if (!success) {
							openErrorMessagePopBox(
								message || "error",
								errorCode
							);
						}
						navigate(
							`${window.location.origin}${window.location.pathname}`
						);
					}
				},
				() => {
					openErrorMessagePopBox();
					navigate(
						`${window.location.origin}${window.location.pathname}`
					);
				}
			);
		}
	};

	const refreshProcessBar = () => {
		api_account_GetCustBonusWallet().then(
			({ data: { data, message, errorCode } }) => {
				const success = errorCode === 0;
				if (success)
					setPromoBox(prev => {
						return {
							...prev,
							processBarInfo: data && {
								rollover: data.rollover,
								turnover: data.turnover
							}
						};
					});
				else {
					openErrorMessagePopBox(message || "error", errorCode);
				}
			},
			() => {
				openErrorMessagePopBox();
			}
		);
	};

	return {
		getPromotionDetail,
		closePromoPopBox
	};
};

const PromotionInfo = ({
	promotionInfo,
	getPromotionDetail,
	refreshProcessBar,
	showAllPromosBtn = false,
	closePromoPopBox
}) => {
	const filterType = Number(getUrlParameter("FilterType"));

	const { t } = useTranslation();

	const { userInfo } = useContext(userInfoContext);

	const _currency = t(`Currency_${userInfo.currencyId}`);

	if (promotionInfo === null) return null;

	return (
		<div className="promotionsInfo">
			<div className="icon icon-close" onClick={closePromoPopBox} />
			<img src={promotionInfo.banner} alt="promotionDetailData_banner" />
			<div className="proData">
				<div className="proDataTitle">
					<div className="txt-darkBlue">
						{promotionInfo.title}
						<div className="txt-gray">
							{promotionInfo.description}
						</div>
					</div>
					<div className="btnGroup">
						<PromotionJoinButton
							promotionInfo={promotionInfo}
							userInfo={userInfo}
							filter={filterType}
							callBack={() =>
								getPromotionDetail(promotionInfo.promotionCode)
							}
						/>
						{showAllPromosBtn && (
							<Link
								className="btn-check"
								to={`/${userInfo.userLang}/${path.promotion.root}`}>
								{t("promotion.lbl_promo_all_promos")}
							</Link>
						)}
					</div>
				</div>
				<CashBackInfo
					promotionCode={promotionInfo.promotionCode}
					cashBackDetails={promotionInfo.cashBackDetails}
					currencyId={userInfo.currencyId}
					t={t}
				/>

				<DepositSlip
					promotionInfo={promotionInfo}
					t={t}
					currencyId={userInfo.currencyId}
				/>

				<DepositMenu
					cash={number_format(
						promotionInfo.depositAmount,
						isConvert(userInfo.currencyId)
					)}
					_currency={_currency}
					promotionInfo={promotionInfo}
					t={t}
				/>

				<ProcessBar
					t={t}
					{...promotionInfo}
					refreshProcessBar={refreshProcessBar}
					currencyId={userInfo.currencyId}
				/>
				<PromoRule
					promotionInfo={promotionInfo}
					t={t}
					currencyId={userInfo.currencyId}
					ruleType={RULE_TYPE.list}
					userLang={userInfo.userLang}
				/>
				<div
					className="proDataBody"
					dangerouslySetInnerHTML={{
						__html: promotionInfo.details
					}}
				/>
			</div>
		</div>
	);
};

export default () => {
	const { promoBox, setPromoBox } = useContext(promoPopBox);
	const { popBox } = useContext(popBoxContext);

	if (promoBox.promotionInfo === null) return null;

	const { isOpen: openPromoPopBox } = promoBox;

	const closePromoPopBox = () => {
		setPromoBox(prev => ({ ...prev, isOpen: false }));
		window.history.replaceState({}, "", `${window.location.pathname}`);
	};

	return (
		<div className={`promoInfoPop${openPromoPopBox ? " active" : ""}`}>
			<div
				onClick={closePromoPopBox}
				className={`promoInfoPopMask${
					popBox.isOpen || !openPromoPopBox ? "" : " active"
				}`}
			/>
			{promoBox.content}
		</div>
	);
};

const DepositSlip = ({ promotionInfo, t, currencyId }) => {
	const { bonusType, refList, depositAmount, promotionCode } = promotionInfo;

	if (!refList || bonusType !== defBonusType.redeposit) return null;

	return (
		<>
			<div className="colorInfo">
				<div>
					<span />
					{t("lbl_ID")}
				</div>
				<div>
					<span />
					{t("lbl_Amount")}
				</div>
			</div>
			<div className="depositSlip">
				<div className="depositSlipList">
					{refList?.map(item => (
						<div
							key={`promotion-deposit-record-${promotionCode}-${item.depositId}`}>
							<div>{item.depositId}</div>
							<div>
								{number_format(
									item.amount,
									isConvert(currencyId)
								)}
							</div>
						</div>
					))}
				</div>
				<div className="txt-orange">
					{t("lbl_total")}:{" "}
					{number_format(depositAmount, isConvert(currencyId))}
				</div>
			</div>
		</>
	);
};

const DepositMenu = ({
	cash,
	_currency,
	promotionInfo: { bonusType, canJoin, isJoin, depositId },
	t
}) => {
	if (
		depositId &&
		canJoin &&
		!isJoin &&
		(bonusType === defBonusType.firstDeposit ||
			bonusType === defBonusType.redeposit)
	)
		return (
			<div className="minAmount">
				<div>
					<div>{t("lbl_ID")}</div>
					<div>{depositId}</div>
				</div>
				<div>
					<div>{t("lbl_DepositAmount")}</div>
					<div>{`${_currency} ${cash}`}</div>
				</div>
			</div>
		);

	return null;
};

import React from "react";
import SwiperCore, { Navigation } from "swiper";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

SwiperCore.use([Navigation]);

const TopQuickLink = ({ tpOptions, clickLobbyProvider, activeProviderId }) => {
	const { t } = useTranslation();
	const Rows = tpOptions.map(_item => (
		<button
			key={`slots-lobby-provider-${_item.id}}`}
			onClick={clickLobbyProvider.bind(this, _item.id)}
			className={activeProviderId === _item.id ? "active" : ""}>
			{_item.displayKey ? t(_item.displayKey) : _item.displayText}
		</button>
	));

	return (
		<div className="casinoSelect">
			<div className="btnGroup">{Rows}</div>
		</div>
	);
};

TopQuickLink.defaultProps = {
	activeProviderId: null
};

TopQuickLink.propTypes = {
	clickLobbyProvider: PropTypes.func.isRequired,
	activeProviderId: PropTypes.number
};

export default TopQuickLink;

export default {
	home: {
		root: "/"
	},
	login: {
		root: "login"
	},
	join: {
		root: "join"
	},
	sports: {
		root: "sports"
	},
	casino: {
		root: "casino"
	},
	p2p: {
		root: "p2p"
	},
	fishing: {
		root: "fishing"
	},
	liveCasino: {
		root: "liveCasino"
	},
	deposit: {
		root: "deposit",
		sub: {
			onlineBank: {
				root: "onlineBank"
			},
			mobilePay: {
				root: "mobilePay"
			},
			localBank: {
				root: "localBank"
			},
			crypto: {
				root: "cryptoPay"
			}
		}
	},
	history: {
		root: "history"
	},
	depositHistory: {
		root: "depositHistory"
	},
	message: {
		root: "message"
	},
	announcement: {
		root: "announcement"
	},
	withdrawal: {
		root: "withdrawal"
	},
	withdrawalHistory: {
		root: "withdrawalHistory"
	},
	transferFunds: {
		root: "transferFunds"
	},
	transferHistory: {
		root: "transferHistory"
	},
	financial: {
		root: "financial"
	},
	profile: {
		root: "profile"
	},
	bankInfo: {
		root: "bankInfo"
	},
	cryptoWallet: {
		root: "cryptoWallet"
	},
	changePassword: {
		root: "changePassword"
	},
	forgetPassword: {
		root: "forgetPassword"
	},
	newsInfo: {
		root: "newsInfo"
	},
	promotion: {
		root: "promotion",
		sub: {
			promotionInfo: {
				root: "promotionInfo"
			}
		}
	},
	resetPassword: {
		root: "resetPassword"
	}
};

import React, { useContext, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { navigate } from "gatsby";
import DropdownHeader from "../common/dropdown.langList";
import { userInfoContext, popBoxContext } from "../common/context";

const getLangs = (currencyId, langKey) => {
	const afterLogin = Boolean(currencyId);

	const defLangGroups = {
		ch: [
			{ displayText: "English", value: "en" },
			{ displayText: "简体中文", value: "zh-CN" }
		]
	};

	return afterLogin && langKey
		? { [langKey]: defLangGroups[langKey] }
		: defLangGroups;
};

const currencyToLangKey = {
	2: "ch"
};

const defLangGroupCountry = {
	ch: "Malaysia"
};

export default () => {
	const { userInfo } = useContext(userInfoContext);
	const { setPopBox } = useContext(popBoxContext);
	const [redirectPath] = useState(null);
	const isMountedRef = useRef(null);
	const { t } = useTranslation();

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	const langKey = currencyToLangKey[userInfo.currencyId];
	const _defLangGroup = getLangs(userInfo.currencyId, langKey);

	const clickClose = () => {
		setPopBox({ isOpen: false });
	};

	if (redirectPath === null) {
		return (
			<DropdownHeader
				mainKey="header-langList"
				className="country"
				subClassName="countryList"
				defLangGroup={_defLangGroup}
				defLangGroupCountry={defLangGroupCountry}
				selectedValue={userInfo.userLang}
				isLogin={userInfo.isLogin}
				isMountedRef={isMountedRef}
				setPopBox={setPopBox}
				clickClose={clickClose}
				t={t}
			/>
		);
	}
	navigate(redirectPath);
	return [];
};

export const defTpGameType = {
	All: 0
};

export const defCategory = {
	Sports: 1,
	Slots: 2,
	LiveCasino: 3,
	Fishing: 4,
	CockFighting: 5,
	Lottery: 6,
	VirtualSports: 7,
	Poker: 8
};

export default {
	/* === sports === */
	polySports: {
		tpId: 67
	},
	sports: {
		tpId: 1,
		gameId: 1,
		tpGameType: 1
	},

	/* === live casino === */

	liveCasino_WM: {
		tpId: 20,
		gameId: 20,
		tpGameType: 32
	},
	liveCasino_Allbet: {
		gameId: 12,
		tpId: 12,
		tpGameType: 6
	},
	liveCasino_AG: {
		gameId: 3,
		tpId: 3,
		tpGameType: 8
	},
	liveCasino_EVO: {
		gameId: 0,
		tpId: 36,
		tpGameType: 63
	}
};

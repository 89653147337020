import React, { useState, useRef, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Nav from "./p2p.nav";
import SearchBox from "./p2p.searchBox";
import GameList from "./p2p.gamelist";
import { defBannerType } from "../../service/api/site";
import {
	api_entrance_GetGameList,
	defGameListType
} from "../../service/api/api.entrance";
import {
	api_account_SetFavoriteGame,
	api_account_GetFavoriteGame
} from "../../service/api/api.account";
import {
	popBoxContext,
	userInfoContext,
	loadingContext
} from "../common/context";
import defPopBoxType from "../common/defPopBoxType";
import {
	defNav,
	defRowsPerPage,
	defSearchColumn,
	defCategory,
	defCategoryList
} from "./p2p.def";
import { getUrlParameter } from "../common/common";
import SwipperBanner from "../common/swipperBanner";
import TopQuickLink from "./p2p.topQuickLink";

const matchKeyword = (name, _keyword) => {
	const keyword = _keyword.toLowerCase();
	return (
		Object.keys(name)?.filter(
			key => name[key].toLowerCase().indexOf(keyword) !== -1
		).length > 0
	);
};

export default () => {
	const { t } = useTranslation();
	const urlTpId = getUrlParameter("tpId");
	const urlTab = getUrlParameter("tab");

	const isSomeTab = Object.values(defNav).some(item => item.key === urlTab);

	const [selNav, setSelNav] = useState(
		isSomeTab ? urlTab : defNav.ALLGAMES.key
	);

	const [favorite, setFavorite] = useState([]);
	const [imgUrl, setImgUrl] = useState("");
	const [tpList, setTpList] = useState({});
	const [gameDatas, setGameDatas] = useState([]);
	const [tpOptions, setTpOptions] = useState([]);

	const [displayGames, setDisplayGames] = useState([]);
	const [pageInfo, setPageInfo] = useState({ currentPage: 1, totalPages: 1 });

	const [searchInfo, setSearchInfo] = useState({
		[defSearchColumn.searchText]: "",
		[defSearchColumn.provider]: { idx: 0, value: null }
	});

	const [category, setCategory] = useState({
		category: { idx: 0, value: null }
	});

	const isMountedRef = useRef(null);
	const { setPopBox } = useContext(popBoxContext);
	const { userInfo } = useContext(userInfoContext);

	const { loading, setLoading } = useContext(loadingContext);

	const loadMore = () => {
		if (gameDatas && pageInfo.totalPages > pageInfo.currentPage) {
			setPageInfo(prev => ({
				...prev,
				currentPage: prev.currentPage + 1
			}));
		}
	};

	const clickClose = () => {
		setPopBox({ isOpen: false });
	};

	const onSelectNav = navKey => {
		if (navKey !== selNav) {
			window.history.replaceState(
				{},
				"",
				`${window.location.pathname}?tab=${navKey}`
			);

			setSelNav(navKey);
			SetGames({
				_searchInfo: searchInfo,
				_category: category,
				_selNav: navKey,
				_gameDatas: gameDatas,
				_tpList: tpList
			});
		}
	};

	const FilterGames = (
		_searchInfo,
		_category,
		_nav,
		initGameDatas,
		initTpList,
		_favorite
	) => {
		const _gameDatas = initGameDatas ?? gameDatas;
		const _tpList = initTpList ?? tpList;
		const tpId = _searchInfo.provider.value;
		const { searchText } = _searchInfo;
		const _categoryId = _category.category.idx;
		const doNotFilterCategory = _categoryId === 0;

		const matchFilter = (_tpId, g, categoryId) => {
			const matchNav =
				(_nav === defNav.FEATURED.key && g.featured === true) ||
				(_nav === defNav.NEW.key && g.new) ||
				(_nav === defNav.FAVOURITE.key &&
					_favorite.includes(`${_tpId}_${g.gameId}`)) ||
				_nav === defNav.ALLGAMES.key;
			return (
				matchNav &&
				(searchText === "" || matchKeyword(g.name, searchText)) &&
				(doNotFilterCategory ||
					g.gameType === defCategoryList[categoryId])
			);
		};

		return _gameDatas?.filter(g => {
			const tp = _tpList[g.tpId];
			return (
				(tpId === null || tp.value === tpId) &&
				matchFilter(tp.value, g, _categoryId, doNotFilterCategory)
			);
		});
	};

	const SetGames = ({
		_searchInfo,
		_category,
		_selNav,
		_gameDatas,
		_tpList,
		_favorite = favorite
	}) => {
		const _displayGames = FilterGames(
			_searchInfo,
			_category,
			_selNav,
			_gameDatas,
			_tpList,
			_favorite
		);

		const _totalPages =
			_displayGames.length === 0
				? 1
				: Math.ceil(_displayGames.length / defRowsPerPage);

		const _currentPage =
			pageInfo.currentPage > _totalPages
				? _totalPages
				: pageInfo.currentPage;
		setDisplayGames(_displayGames);
		setPageInfo(prevS => ({
			...prevS,
			currentPage: _currentPage,
			totalPages: _totalPages
		}));
	};

	const onSetFavorite = (tpId, gameId) => {
		const key = `${tpId}_${gameId}`;
		const isRemove = favorite?.includes(key);
		const newFav = isRemove
			? favorite?.filter(f => f !== key)
			: [...favorite, key];
		setFavorite(newFav);
		SetGames({
			_searchInfo: searchInfo,
			_category: category,
			_selNav: selNav,
			_gameDatas: gameDatas,
			_tpList: tpList,
			_favorite: newFav
		});
		if (userInfo.isLogin) {
			api_account_SetFavoriteGame(tpId, gameId, isRemove).then(
				res => {
					if (isMountedRef.current) {
						const { errorCode, message } = res.data;
						if (errorCode !== 0) {
							setPopBox({
								isOpen: true,
								popBoxType: defPopBoxType.ErrorMsg,
								message: `[${errorCode}] ${message}`,
								btnBar: (
									<div className="btnGroup">
										<button
											className="btn-check"
											onClick={clickClose}>
											{t("lbl_Close")}
										</button>
									</div>
								)
							});
						}
					}
				},
				() => {
					if (isMountedRef.current) {
						setPopBox({
							isOpen: true,
							popBoxType: defPopBoxType.ErrorMsg,
							message: t("msg_ApiServerError"),
							btnBar: (
								<div className="btnGroup">
									<button
										className="btn-check"
										onClick={clickClose}>
										{t("lbl_Close")}
									</button>
								</div>
							)
						});
					}
				}
			);
		}
	};

	const EditSearchField = (column, value) => {
		let _searchInfo = searchInfo;
		let _result = {
			[defSearchColumn.searchText]: "",
			[defSearchColumn.provider]: { idx: 0, value: null }
		};
		if (column === defSearchColumn.searchText) {
			_result = {
				[defSearchColumn.searchText]: value,
				[defSearchColumn.provider]:
					_searchInfo[defSearchColumn.provider]
			};
			setSearchInfo(prevS => {
				_searchInfo = { ...prevS, [column]: value };
				return _searchInfo;
			});
		} else {
			_result = {
				[defSearchColumn.searchText]:
					_searchInfo[defSearchColumn.searchText],
				[defSearchColumn.provider]: {
					idx: value,
					value: tpOptions[value].id
				}
			};
			setSearchInfo(prevS => {
				_searchInfo = {
					...prevS,
					[column]: { idx: value, value: tpOptions[value].id }
				};
				return _searchInfo;
			});
		}
		SetGames({
			_searchInfo: _result,
			_category: category,
			_selNav: selNav,
			_gameDatas: gameDatas,
			_tpList: tpList
		});
	};

	const clickLobbyProvider = _providerId => {
		const _optionsIdx = tpOptions.findIndex(
			_options => _options.id === _providerId
		);

		if (searchInfo.provider.id !== _optionsIdx) {
			const _result = {
				...searchInfo,
				[defSearchColumn.provider]: {
					idx: _optionsIdx,
					value: _providerId
				}
			};

			setSearchInfo(_result);
			SetGames({
				_searchInfo: _result,
				_category: category,
				_selNav: selNav,
				_gameDatas: gameDatas,
				_tpList: tpList
			});
		}
	};

	const onChangeCategory = (column, value) => {
		let _category = category;
		setCategory(prevS => {
			_category = {
				...prevS,
				[column]: {
					idx: value,
					value: defCategory[value].id
				}
			};
			return _category;
		});

		SetGames({
			_searchInfo: searchInfo,
			_category,
			_selNav: selNav,
			_gameDatas: gameDatas,
			_tpList: tpList
		});
	};

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	useEffect(() => {
		setLoading(true);
		api_entrance_GetGameList({
			userLang: userInfo.userLang,
			gameListType: defGameListType.p2p
		}).then(
			res => {
				const success = res.data.errorCode === 0;

				if (success) {
					const { imgUrl: _imgUrl, providerList } = res.data.data;
					const _tpList = {};
					const _tpOptions = [
						{ displayKey: "lbl_AllProvider", id: null }
					];
					const games = [];

					setImgUrl(_imgUrl);

					providerList?.forEach(gameInfo => {
						_tpList[gameInfo.value] = { ...gameInfo };
						_tpOptions.push({
							displayText: gameInfo.name,
							id: gameInfo.value
						});

						if (gameInfo?.gameList) {
							gameInfo.gameList.forEach(list => {
								games.push({
									tpId: gameInfo.value,
									tpName: gameInfo.name,
									iconPath: gameInfo.iconPath,
									...list
								});
							});
						}
					});

					if (games.length) {
						for (let index = 0; index < games.length; index++) {
							const idx = Math.floor(
								Math.random() * games.length
							);
							const tempEle1 = games[index];
							const tempEle2 = games[idx];

							games[index] = tempEle2;
							games[idx] = tempEle1;
						}
					}

					let _searchInfo = searchInfo;
					let _category = category;

					if (isMountedRef.current) {
						setTpList(_tpList);
						setGameDatas(games);
						setTpOptions(_tpOptions);

						_searchInfo = searchInfo;
						_category = category;

						if (urlTpId) {
							const idx = _tpOptions.findIndex(
								x => x.id === Number(urlTpId)
							);

							if (idx !== -1) {
								setSearchInfo(prevS => {
									_searchInfo = {
										...prevS,
										provider: {
											idx,
											value: _tpOptions[idx].id
										}
									};
									return _searchInfo;
								});
							}
						}
					}

					SetGames({
						_searchInfo,
						_category,
						_selNav: selNav,
						_gameDatas: games,
						_tpList
					});

					if (userInfo.isLogin) {
						api_account_GetFavoriteGame().then(resFav => {
							if (
								resFav.data.errorCode === 0 &&
								resFav.data.data
							) {
								const fav = resFav.data.data.map(
									f => `${f.tpId}_${f.gameCode}`
								);

								if (isMountedRef.current) {
									setFavorite(fav);

									SetGames({
										_searchInfo,
										_category,
										_selNav: selNav,
										_gameDatas: games,
										_tpList,
										_favorite: fav
									});
								}
							} else if (resFav.data.errorCode !== 0) {
								setPopBox({
									isOpen: true,
									popBoxType: defPopBoxType.ErrorMsg,
									message: `[${resFav.data.errorCode}] ${resFav.data.message}`,
									btnBar: (
										<div className="btnGroup">
											<button
												className="btn-check"
												onClick={clickClose}>
												{t("lbl_Close")}
											</button>
										</div>
									)
								});
							}
						});
					} else if (isMountedRef.current) {
						SetGames({
							_searchInfo,
							_category,
							_selNav: selNav,
							_gameDatas: games,
							_tpList
						});
					}

					setLoading(false);
				} else {
					setPopBox({
						isOpen: true,
						popBoxType: defPopBoxType.ErrorMsg,
						message: `[${res.data.errorCode}] ${res.data.message}`,
						btnBar: (
							<div className="btnGroup">
								<button
									className="btn-check"
									onClick={clickClose}>
									{t("lbl_Close")}
								</button>
							</div>
						)
					});
				}
			},
			() => {
				if (isMountedRef.current) {
					setPopBox({
						isOpen: true,
						popBoxType: defPopBoxType.ErrorMsg,
						message: t("msg_ApiServerError"),
						btnBar: (
							<div className="btnGroup">
								<button
									className="btn-check"
									onClick={clickClose}>
									{t("lbl_Close")}
								</button>
							</div>
						)
					});
					setLoading(false);
				}
			}
		);
	}, [userInfo.isLogin, urlTpId]);

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);

		if (!isSomeTab) {
			searchParams.set("tab", defNav.ALLGAMES.key);

			window.history.replaceState(
				{},
				"",
				`${window.location.pathname}?${searchParams.toString()}`
			);
		}
	}, [isSomeTab]);

	return loading ? null : (
		<>
			<SwipperBanner
				ImgFolder={[defBannerType.Slots]}
				openInBlank={true}
				className="swiper-casino"
			/>
			<section className="inside">
				<div className="rightModule">
					<div className="infoModule">
						<TopQuickLink
							tpOptions={tpOptions}
							clickLobbyProvider={clickLobbyProvider}
							activeProviderId={searchInfo.provider?.value}
							userLang={userInfo.userLang}
						/>
						<div className="casinoSelect">
							<Nav
								selNav={selNav}
								onSelectNav={onSelectNav}
								t={t}
							/>
							<SearchBox
								t={t}
								tpOptions={tpOptions}
								onChangeCategory={onChangeCategory}
								searchInfo={searchInfo}
								categoryOptions={defCategory}
								category={category}
								EditSearchField={EditSearchField}
							/>
						</div>
						<GameList
							t={t}
							imgUrl={imgUrl}
							displayGames={displayGames}
							tpList={tpList}
							userLang={userInfo.userLang}
							isLogin={userInfo.isLogin}
							currentPage={pageInfo.currentPage}
							favorite={favorite}
							onSetFavorite={onSetFavorite}
							onLoadMore={loadMore}
						/>
					</div>
				</div>
			</section>
		</>
	);
};

import React from "react";
import { Trans } from "react-i18next";
import PropTypes from "prop-types";
import defPopBoxType from "../common/defPopBoxType";

const DepositInfoModule = ({
	t,
	userLang,
	depositOptions,
	depositType,
	setDepositType,
	checked,
	onSubmit,
	onReset,
	children,
	setPopBox
}) => (
	<div className="infoModule">
		<div className="inside-title icon icon-icon32px_deposit">
			<span>{t("lbl_Deposit")}</span>
		</div>
		<div className="subModuleListColumn">
			<div className="subModuleTitle">
				<i className="txt-blue">*</i>
				{t("lbl_DepositOptions")}
			</div>

			<div className="subModule">
				<div>
					{depositOptions?.map(item => (
						<button
							onClick={() => {
								if (item.linePayLink) {
									setPopBox({
										isOpen: true,
										popBoxType: defPopBoxType.LinePay,
										clickClose: () =>
											setPopBox({ isOpen: false }),
										userLang,
										linePayLink: item.linePayLink
									});
								} else {
									setDepositType(item.type);
									onReset();
								}
							}}
							className={
								item.type === depositType ? "active" : ""
							}
							key={`${item.type}-key`}>
							<div className={item.iconClass}>
								<i className="icon icon-icon32px_check" />
							</div>

							<div className="bank">
								<div>{t(item.depositDisplayKey)}</div>
								<div>
									{t("lbl_CreditTime")} ：{t(item.creditTime)}
								</div>
							</div>
						</button>
					))}
				</div>
			</div>

			{children}

			<div className="btnGroup">
				<button
					onClick={onSubmit}
					className={checked ? "btn-check" : "darkGray"}>
					{t("lbl_Submit")}
				</button>
			</div>
		</div>

		<div className="important">
			<div className="title">
				<span className="icon icon-warning" />
				{t("lbl_ImportantNotice")}
			</div>
			<ul>
				<li>{t("lbl_DepositImportantNoticeContent1")}</li>
				<li>
					<Trans
						i18nKey="lbl_DepositImportantNoticeContent2"
						components={{
							span: <span />
						}}
					/>
				</li>
				<li>
					<Trans
						i18nKey="lbl_DepositImportantNoticeContent3"
						components={{
							span: <span />
						}}
					/>
				</li>
				<li>
					<Trans
						i18nKey="lbl_DepositImportantNoticeContent4"
						components={{
							span: <span />
						}}
					/>
				</li>
				<li>{t("lbl_DepositImportantNoticeContent5")}</li>
				<li>
					<Trans
						i18nKey="lbl_DepositImportantNoticeContent6"
						components={{
							a: <a href="https://www.google.com" />
						}}
					/>
				</li>
			</ul>
		</div>
	</div>
);

DepositInfoModule.defaultProps = {
	checked: true,
	depositType: null
};

DepositInfoModule.propTypes = {
	t: PropTypes.func.isRequired,
	userLang: PropTypes.string.isRequired,
	depositOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
	depositType: PropTypes.string,
	setDepositType: PropTypes.func.isRequired,
	checked: PropTypes.bool,
	onSubmit: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired
};

export default DepositInfoModule;

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { userInfoContext } from "../common/context";
import { defMenuLinks, defMenuType } from "./header.menu.def";
import LinkItems from "../common/linkItems";
import { getCurrencyCode } from "../common/common";
import { defGameListType } from "../../service/api/api.entrance";

export default ({
	showSubMenu,
	currentMenuKey,
	fishingList,
	p2pList,
	slotList
}) => {
	const { t } = useTranslation();
	const { userInfo } = useContext(userInfoContext);
	const _currencyId =
		userInfo.currencyId ?? getCurrencyCode(userInfo.userLang);

	const _defMenuLinks = {};

	Object.keys(defMenuLinks)
		.filter(m => {
			if (defMenuLinks[m].gameListType === defGameListType.fishing) {
				return fishingList && fishingList.length > 0;
			}
			if (defMenuLinks[m].gameListType === defGameListType.p2p) {
				return p2pList && p2pList.length > 0;
			}

			if (defMenuLinks[m].gameListType === defGameListType.casino) {
				return slotList && slotList.length > 0;
			}

			return true;
		})
		.forEach(m => {
			_defMenuLinks[m] = defMenuLinks[m];
		});

	return (
		<LinkItems
			defMenuLinks={_defMenuLinks}
			defMenuType={defMenuType}
			t={t}
			showSubMenu={showSubMenu}
			currentMenuKey={currentMenuKey}
			userLang={userInfo.userLang}
			isLogin={userInfo.isLogin}
			checkMatchPage={true}
			toUpperCase={true}
			currencyId={_currencyId}
		/>
	);
};

import React from "react";
import PropTypes from "prop-types";
import { cloudImgUrl } from "../../config";
import Loading from "../common/loading";

const BankInfoCard = ({ custBankList, delBankAccount, loading }) => {
	const arr = custBankList?.map(item => {
		const bankLogo = String(item.bankLogo).toLowerCase();
		return (
			<div className="addBankName" key={`bankCard-${item.accountNo}`}>
				<div className="addBankInfo">
					<div className="bankName">
						<img
							src={`${cloudImgUrl}/BankLogo/${bankLogo}`}
							alt={item.bankName}
						/>
						<div>
							{item.bankName}
							<div className="txt-blue">{item.accountNo}</div>
						</div>
					</div>
					<div className="checkbox">
						<button
							aria-label="deleteBankAccount"
							className="icon icon-bin"
							onClick={delBankAccount.bind(
								this,
								item.bankInfoId,
								item.accountNo
							)}
						/>
					</div>
				</div>
			</div>
		);
	});
	return loading ? (
		<Loading />
	) : custBankList?.length > 0 ? (
		<div className="infoModule">
			<div className="walletModule">
				<div className="addBank haveBank">{arr}</div>
			</div>
		</div>
	) : null;
};

BankInfoCard.propTypes = {
	delBankAccount: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

export default BankInfoCard;

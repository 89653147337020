import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { navigate } from "gatsby";
import {
	api_entrance_Signup,
	api_entrance_ExistsUserName,
	api_entrance_GetCurrencies
} from "../../service/api/api.entrance";
import { api_account_GetCustProfile } from "../../service/api/api.account";
import TandC from "../../service/t_and_c";
import { GET_LANG_ID } from "../../service/language";
import { deleteCookie, passwordCheck, readCookie } from "../common/common";
import defPopBoxType from "../common/defPopBoxType";
import usePopBox from "../hook/usePopBox";
import {
	userInfoContext,
	popBoxContext,
	merchantInfoContext
} from "../common/context";
import { initValue, initTip } from "../common/join.data";
import JoinForm from "./join.form";
import path from "../../router";
import JoinPromoInfo from "./join.promoInfo";

export default () => {
	const { t } = useTranslation();
	const { userInfo, setUserInfo } = useContext(userInfoContext);
	const { merchantInfo } = useContext(merchantInfoContext);
	const { setPopBox } = useContext(popBoxContext);
	const [allCurrencies, setAllCurrencies] = useState([]);
	const [userValue, setUserValue] = useState(initValue);
	const [tip, setTip] = useState(initTip);
	const [showPsw, setShowPsw] = useState(false);
	const isMountedRef = useRef(null);
	const defaultLangId = userInfo.userLang ?? GET_LANG_ID();
	const { errorPopBox } = usePopBox();
	const [defaultOption, setDefaultOption] = useState(
		t("lbl_PrefferedCurrency")
	);

	const [refId, setRefId] = useState(readCookie("refId"));

	const tandCTerms = TandC(defaultLangId).terms;

	useEffect(() => {
		isMountedRef.current = true;

		const getAllCurrencies = async () => {
			const res = await api_entrance_GetCurrencies(userInfo.userLang);
			const { errorCode, data } = res.data;

			if (errorCode === 0) {
				if (isMountedRef.current) {
					setAllCurrencies(data);
				}
			}
		};

		getAllCurrencies();

		return () => {
			isMountedRef.current = false;
		};
	}, []);

	const showPswHandler = () => {
		setShowPsw(!showPsw);
	};

	const clickInfo = col => {
		let msg = "";
		if (col === "username") {
			msg = t("lbl_UsernameTooltip");
		} else if (col === "password") {
			msg = t("lbl_PasswordTooltip");
		} else if (col === "currency") {
			msg = t("lbl_CantChangePreferredCurrency");
		}
		if (tip[col].msg === msg) {
			msg = "";
		}

		setTip(prev => ({
			...prev,
			[col]: {
				checked: tip[col].checked,
				msg
			}
		}));
	};

	const EditField = (col, e) => {
		let val = e.target.value;
		if (col === "username") {
			val = val.replace(/[\W]/g, "");
		}
		if (col === "phone") {
			val = val.replace(/[^0-9]+/g, "");
		}

		setUserValue(prev => ({
			...prev,
			[col]: val
		}));
	};

	const _currencyId = merchantInfo?.currencyList?.[userValue.currencyIdx];

	const setCurrencyTip = (col, _defaultOption) => {
		const result = currencyCheck(_defaultOption);
		const { checked, msg } = result.checked;
		setTip(prev => ({
			...prev,
			[col]: {
				checked,
				msg: t(msg)
			}
		}));
	};

	const onSelectItem = i => {
		setDefaultOption(null);
		setUserValue(prev => ({ ...prev, currencyIdx: i }));
		setCurrencyTip("currency", null);
	};

	const checkValue = async (col, e) => {
		const val = e.target.value;
		let checked = true;
		let msg = "";

		if (col === "username") {
			const result = await usernameCheck(val);
			checked = result.checked;
			msg = result.msg;
		} else if (col === "password") {
			const result = passwordCheck(val);
			checked = result.checked;
			msg = result.message;
		} else if (col === "currency") {
			const result = currencyCheck(defaultOption);
			checked = result.checked;
			msg = result.msg;
		}

		setTip(prev => ({
			...prev,
			[col]: {
				checked,
				msg: t(msg)
			}
		}));
	};

	const usernameCheck = async val => {
		let checked = false;
		let msg = "";

		if (val === "") {
			checked = false;
			msg = "msg_UsernameReq";
		} else {
			checked = true;
			msg = "lbl_CantChangeUsername";
		}

		if (checked === true) {
			const res = await api_entrance_ExistsUserName({
				userName: val,
				userLang: userInfo.userLang
			});
			if (isMountedRef.current) {
				if (res.data.errorCode === 0) {
					if (res.data.data === true) {
						checked = false;
						msg = t("msg_UsernameNotAvailable");
					}
				} else {
					errorPopBox(
						res.data.message || "error",
						res.data.errorCode
					);
				}
			}
		}

		return { checked, msg };
	};

	const currencyCheck = _defaultOption => {
		let checked = true;
		let msg = "";
		if (_defaultOption !== null) {
			checked = false;
			msg = "lbl_RemindPrefferedCurrency";
		}
		return { checked, msg };
	};

	const clickCancel = () => {
		navigate(`/${userInfo.userLang}`);
	};

	const checkAll = async () => {
		try {
			const res = await Promise.all([usernameCheck(userValue.username)]);

			const _usernameCheck = res[0];
			const _passwordCheck = passwordCheck(userValue.password);
			const _currencyCheck = refId
				? { checked: true }
				: currencyCheck(defaultOption);

			const checked =
				_usernameCheck.checked &&
				_passwordCheck.checked &&
				_currencyCheck.checked;

			if (!checked) {
				setTip(prev => ({
					...prev,
					username: {
						checked: _usernameCheck.checked,
						msg: t(_usernameCheck.msg)
					},
					password: {
						checked: _passwordCheck.checked,
						msg: t(_passwordCheck.message)
					},
					currency: {
						checked: _currencyCheck.checked,
						msg: t(_currencyCheck.msg)
					}
				}));
			}
			return checked;
		} catch (error) {
			return false;
		}
	};

	const clickSubmit = async e => {
		e.preventDefault();
		const checked = await checkAll();

		if (checked) {
			api_entrance_Signup(
				{
					username: userValue.username,
					userpwd: userValue.password,
					userLang: defaultLangId,
					referralId: refId,
					currencyId: refId ? 0 : _currencyId
				},
				defaultLangId
			).then(
				res => {
					if (isMountedRef.current) {
						const { errorCode, message } = res.data;
						if (errorCode === 0) {
							api_account_GetCustProfile().then(
								async res_custInfo => {
									if (isMountedRef.current) {
										let _user = {
											isLogin: true,
											userLang: defaultLangId,
											userName: userValue.username
										};
										if (res_custInfo.data.errorCode === 0) {
											_user = {
												isJoin: true,
												..._user,
												...res_custInfo.data.data,
												userLang:
													res_custInfo.data.data
														.userLang
											};
											localStorage.setItem(
												`${_user.userName}-loginViaJoin`,
												"1"
											);
											await navigate(
												`/${_user.userLang}/${path.deposit.root}`
											);
										} else {
											errorPopBox(
												res_custInfo.data.message ||
													"error",
												res_custInfo.data.errorCode
											);
										}
										setUserInfo(_user);
									}
								},
								() => {
									if (isMountedRef.current) {
										errorPopBox();
										setPopBox({ isOpen: false });
									}
								}
							);
						} else if (errorCode === -2 || errorCode === -5) {
							setTip(prev => ({
								...prev,
								username: {
									checked: false,
									msg: message
								}
							}));
						} else if (errorCode === -3) {
							setTip(prev => ({
								...prev,
								password: {
									checked: false,
									msg: message
								}
							}));
						} else {
							// refId不合法
							if (errorCode === -19) {
								deleteCookie("refId");
								window.history.pushState(
									"",
									"",
									`${window.location.origin}${window.location.pathname}`
								);
								setRefId(null);
							}
							errorPopBox(message || "error", errorCode);
						}
					}
				},
				() => {
					if (isMountedRef.current) {
						errorPopBox();
					}
				}
			);
		}
	};

	const openLogin = () => {
		setPopBox({
			isOpen: true,
			popBoxType: defPopBoxType.Login
		});
	};

	return (
		<div className="joinpop">
			<div className="joinloginModule">
				<JoinPromoInfo userLang={defaultLangId} t={t} />
				<JoinForm
					t={t}
					openLogin={openLogin}
					userValue={userValue}
					tip={tip}
					clickInfo={clickInfo}
					EditField={EditField}
					checkValue={checkValue}
					showPsw={showPsw}
					showPswHandler={showPswHandler}
					onSelectItem={onSelectItem}
					tandCTerms={tandCTerms}
					clickCancel={clickCancel}
					clickSubmit={clickSubmit}
					defaultOption={defaultOption}
					siteCurrency={
						merchantInfo?.currencyList?.map(c => ({
							id: c,
							displayText:
								allCurrencies.find(ac => ac.currencyId === c)
									?.isoCode ?? c,
							displayKey: `Currency_${c}`
						})) ?? []
					}
					refId={refId}
				/>
			</div>
		</div>
	);
};

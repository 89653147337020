import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DateTime from "react-datetime";
import Dropdown from "../common/dropdown";
import { defHistoryIdList } from "./def";

const quickPickList = [
	{ id: 0, displayKey: "lbl_Today" },
	{ id: 1, displayKey: "lbl_YesterDay" },
	{ id: 2, displayKey: "lbl_ThisWeek" },
	{ id: 3, displayKey: "lbl_LastWeek" },
	{ id: 4, displayKey: "lbl_ThisMonth" },
	{ id: 5, displayKey: "lbl_LastMonth" }
];

export default function HistoryFilter({
	filterData,
	submitHandler,
	setFilterData,
	bonusList,
	selectedTagId
}) {
	const { t } = useTranslation();

	const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");

	const start_valid = current => current.isBefore(filterData.endDate);

	const end_valid = current =>
		current.isBefore(tomorrow) && current.isAfter(filterData.startDate);

	const onSelectItem = i => {
		let startDate = "";
		let endDate = "";

		if (quickPickList[i].displayKey === "lbl_Today") {
			startDate = moment().format("YYYY-MM-DD");
			endDate = moment().format("YYYY-MM-DD");
		} else if (quickPickList[i].displayKey === "lbl_YesterDay") {
			startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
			endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
		} else if (quickPickList[i].displayKey === "lbl_ThisWeek") {
			startDate = moment().startOf("week").format("YYYY-MM-DD");
			endDate = moment().format("YYYY-MM-DD");
		} else if (quickPickList[i].displayKey === "lbl_LastWeek") {
			startDate = moment()
				.subtract(1, "weeks")
				.startOf("week")
				.format("YYYY-MM-DD");
			endDate = moment()
				.subtract(1, "weeks")
				.endOf("week")
				.format("YYYY-MM-DD");
		} else if (quickPickList[i].displayKey === "lbl_ThisMonth") {
			startDate = moment().startOf("month").format("YYYY-MM-DD");
			endDate = moment().format("YYYY-MM-DD");
		} else if (quickPickList[i].displayKey === "lbl_LastMonth") {
			startDate = moment()
				.subtract(1, "months")
				.startOf("month")
				.format("YYYY-MM-DD");
			endDate = moment()
				.subtract(1, "months")
				.endOf("month")
				.format("YYYY-MM-DD");
		}

		setFilterData(prev => ({
			...prev,
			selectedIdx: i,
			startDate,
			endDate
		}));
	};

	const onChangeDate = (col, val) => {
		const _val = moment.isMoment(val) ? val : moment(val);
		if (moment.isMoment(_val) && _val.isValid()) {
			col === "start"
				? setFilterData(prev => ({
						...prev,
						startDate: _val
				  }))
				: setFilterData(prev => ({
						...prev,
						endDate: _val
				  }));
		}
	};

	const onSelectBonus = i => {
		setFilterData(prev => ({
			...prev,
			selectedBonusIdx: i
		}));
	};

	const bonusListOpt = useMemo(
		() =>
			bonusList.map((b, bIdx) => ({
				id: bIdx,
				displayText: b.promotionCode
			})),
		[bonusList]
	);

	return (
		<div className="quickSelect">
			{selectedTagId === defHistoryIdList.bonusStatement && (
				<div className="inputBox-blue">
					<i className="txt-blue">{t("lbl_promotionCode")}</i>
					<Dropdown
						i18n={t}
						mainKey="history_bonusList"
						options={bonusListOpt}
						selectedIdx={filterData.selectedBonusIdx}
						onSelectItem={onSelectBonus}
					/>
				</div>
			)}

			<div className="inputBox-blue">
				<i className="txt-blue">{t("lbl_QuickPick")}</i>
				<Dropdown
					i18n={t}
					mainKey="history_"
					options={quickPickList}
					selectedIdx={filterData.selectedIdx}
					onSelectItem={onSelectItem}
				/>
			</div>

			<div className="inputBox-blue">
				<i className="txt-blue">{t("lbl_From")}</i>
				<DateTime
					value={moment(filterData.startDate).format("YYYY-MM-DD")}
					onChange={onChangeDate.bind(this, "start")}
					timeFormat={false}
					isValidDate={start_valid}
					dateFormat={"YYYY-MM-DD"}
				/>
				<div className="iconModule">
					<i className="icon icon-calendar" />
				</div>
			</div>

			<div className="inputBox-blue">
				<i className="txt-blue">{t("lbl_To")}</i>
				<DateTime
					value={moment(filterData.endDate).format("YYYY-MM-DD")}
					onChange={onChangeDate.bind(this, "end")}
					timeFormat={false}
					isValidDate={end_valid}
					dateFormat={"YYYY-MM-DD"}
				/>
				<div className="iconModule">
					<i className="icon icon-calendar" />
				</div>
			</div>

			<div className="btnGroup">
				<button
					className="btn-check"
					onClick={submitHandler.bind(
						this,
						filterData.startDate,
						filterData.endDate,
						false,
						filterData.selectedIdx,
						filterData.selectedBonusIdx
					)}>
					{t("lbl_Submit")}
				</button>
			</div>
		</div>
	);
}

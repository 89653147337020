import React, {
	Component,
	createRef,
	useContext,
	useEffect,
	useRef
} from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import moment from "moment";
import path from "../../router";
import { walletInfoContext, userInfoContext } from "../common/context";
import { isConvert, number_format } from "../common/common";
import ReloadSpin from "../common/reloadSpin";
import HeaderProcessBar from "./header.processBar";
import {
	GetAllWallets,
	handleIsHideBalance
} from "../common/wrapper.walletInfo";
import usePopBox from "../hook/usePopBox";

export default ({ i18n }) => {
	const { isHideBalance, mainWallet, tpWallets, bonusWallets, dispatch } =
		useContext(walletInfoContext);
	const { userInfo } = useContext(userInfoContext);
	const { errorPopBox } = usePopBox();
	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	});

	const HandleIsHideBalance = e => {
		e.stopPropagation();
		const value = e.target.value === "true";
		handleIsHideBalance({ dispatch, value, errorPopBox });
	};

	const RefreshAllWallets = () => {
		GetAllWallets({
			userLang: userInfo.userLang,
			isLogin: userInfo.isLogin,
			dispatch,
			errorPopBox
		});
	};

	return userInfo.isLogin && userInfo.currencyId !== undefined ? (
		<Dropdown
			i18n={i18n}
			userLang={userInfo.userLang}
			currencyId={userInfo.currencyId}
			mainWallet={mainWallet}
			tpWallets={tpWallets}
			isHideBalance={isHideBalance}
			bonusWallets={bonusWallets}
			_isConvert={isConvert(userInfo.currencyId)}
			handleIsHideBalance={HandleIsHideBalance}
			RefreshAllWallets={RefreshAllWallets}
			lastLoginAt={userInfo.lastLoginAt}
		/>
	) : null;
};

class Dropdown extends Component {
	constructor(props) {
		super(props);
		if (typeof document !== `undefined`) {
			document.addEventListener("mousedown", this.handleClickOutside);
		}
		this.state = { isOpen: false };
		this.wrapper = createRef(null);
	}

	handleClickOutside = e => {
		if (this.wrapper.current && !this.wrapper.current.contains(e.target)) {
			this.setState({ isOpen: false });
		}
	};

	clickDropdown = e => {
		e.preventDefault();
		this.setState(prev => ({ isOpen: !prev.isOpen }));
	};

	render() {
		const {
			isHideBalance,
			tpWallets,
			mainWallet,
			bonusWallets,
			handleIsHideBalance: _handleIsHideBalance
		} = this.props;

		const tpWalletsBonusSum = tpWallets.reduce(
			(prev, curr) => prev + curr.balance,
			0
		);

		const balance = !isNaN(mainWallet.balance)
			? number_format(
					mainWallet.balance +
						tpWalletsBonusSum +
						(bonusWallets?.bonus ? bonusWallets.bonus : 0),
					this.props._isConvert
			  )
			: 0;

		const tpWalletRows = tpWallets
			.sort((a, b) => (a.tpId > b.tpId ? 1 : -1))
			.map(t => (
				<div
					className="walletBalance"
					key={`header-tpwallet-${t.tpId}`}>
					<div className="walletName">
						<span>{t.displayText}</span>
						<span>
							{number_format(t.balance, this.props._isConvert)}
						</span>
					</div>
				</div>
			));

		return (
			<div
				className={`wallet icon icon-balance${
					this.state.isOpen ? " active" : ""
				}`}
				ref={this.wrapper}
				onClick={this.clickDropdown}>
				<span>
					{`${
						this.props.currencyId === 51
							? "VNĐ"
							: this.props.i18n(
									`Currency_${this.props.currencyId}`
							  )
					} ${isHideBalance ? "" : balance}`}
				</span>
				<button
					aria-label="HandleDisplayBalance"
					value={!isHideBalance}
					onClick={_handleIsHideBalance}
					className={`icon icon-${isHideBalance ? "plus" : "minus"}`}
				/>

				<div className="submenu">
					<div className="balance">
						<span>
							{`${this.props.i18n(
								`Currency_${this.props.currencyId}`
							)}`}
						</span>
						<span>
							{balance}
							<ReloadSpin
								btnActive={false}
								func={this.props.RefreshAllWallets}
							/>
						</span>
					</div>

					<BonusWalletRows
						bonusWallets={bonusWallets}
						i18n={this.props.i18n}
						mainWallet={mainWallet}
						_isConvert={this.props._isConvert}
					/>

					{tpWalletRows}

					<div className="btnGroup">
						<Link
							to={`/${this.props.userLang}/${path.deposit.root}`}
							className="orange">
							{this.props.i18n("lbl_Deposit")}
						</Link>
					</div>
					<div className="loginLog">
						<span>{this.props.i18n("lbl_LastLogin")}</span>
						<span>
							{this.props.lastLoginAt
								? moment(this.props.lastLoginAt).format("l LTS")
								: null}
						</span>
					</div>
					<div className="transactionLog">
						<span>{this.props.i18n("lbl_LastTransaction")}</span>
						<span>
							{mainWallet.lastTransaction
								? moment(mainWallet.lastTransaction).format(
										"l LTS"
								  )
								: null}
						</span>
					</div>
				</div>
			</div>
		);
	}
}

const BonusWalletRows = ({ bonusWallets, i18n, mainWallet, _isConvert }) => {
	return (
		<div className="walletBalance">
			<div className="walletName">
				<span>{i18n("lbl_MainWallet")}</span>
				<span>{number_format(mainWallet.balance, _isConvert)}</span>
			</div>
			{bonusWallets && (
				<div>
					<div>
						<span>{bonusWallets.promotionCode}</span>
						<span>{number_format(bonusWallets.bonus)}</span>
					</div>
					<HeaderProcessBar
						turnover={bonusWallets.turnover}
						rollover={bonusWallets.rollover}
					/>
				</div>
			)}
		</div>
	);
};

Dropdown.propTypes = {
	i18n: PropTypes.func.isRequired
};
